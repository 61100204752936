import { useLocation } from "react-router-dom";
// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";

import styled from "@emotion/styled";

import { css } from "@emotion/react";

import MainLayout from "../components/layout/MainLayout";
import Circle from "../components/Circle";
import { useScrollToTop } from "../lib/react/hooks";
import { BookingHorizontalSection } from "../components/sections";

interface IIntegrityProps {}

export const Integrity = (props: IIntegrityProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  return (
    <MainLayout>
      <div css={css``} />
      <Circle1></Circle1>
      <Column>
        <h2>Integritets- och Cookiepolicy</h2>

        <p>
          Denna Integritets- och cookiespolicy gäller för alla uppgifter som
          samlas in via www.atmoshere.nu (”Webbplatsen”), inklusive för dig som
          registrerar ett konto på Webbplatsen, eller från våra kunder. Datum
          för ikraftträdande: 23.4.2020
        </p>

        <br></br>

        <h6>Personuppgiftsansvarig</h6>
        <ul>
          <li>Atmoshere Sweden AB (nedan kallad “Bolaget”)</li>
          <li>Postadress: Siktgatan 1</li>
          <li>Postnummer: 162 50 Vällingby, Sverige</li>
          <li>Telefonnummer ‭+46 (0) 73-677 10 05‬</li>
          <li>E-postadress: kontakt@atmoshere.nu</li>
          <li>Organisationsnummer: 559250-1935</li>
        </ul>

        <br></br>

        <h6>Dataskyddsombud</h6>
        <ul>
          <li>Namn: Morgan Andersson</li>
          <li>E-postadress: moggeandersson@gmail.com</li>
        </ul>

        <br></br>

        <h6>Namn på register</h6>
        <p>Atmoshere Sweden ABs kundregister</p>

        <br></br>

        <h5>Om integritetspolicyn</h5>
        <p>
          Bolaget erbjuder städning av bostäder (”Tjänsten”). Tjänsten som
          Bolaget erbjuder är baserat på förtroende. Det är därför viktigt för
          Bolaget att Bolagets kunder och användare av Webbplatsen (gemensamt
          benämnda ”Konsumenten”) känner sig trygga med att använda Tjänsten,
          och Bolaget lägger därför stor vikt vid att skydda Konsumentens
          integritet. Denna integritets- och cookiespolicy förklarar hur Bolaget
          samlar in och använder information om användare av Webbplatsen och
          Bolagets kunder och hur Bolaget skyddar deras integritet. Om
          Konsumenten har frågor som rör Bolagets integritets- och cookiespolicy
          eller hantering av personuppgifter uppmanar Bolaget Konsumenten att
          kontakta Bolaget på telefonnummer +46 (0) 73-677 10 05 eller per
          e-post info@Atmoshere.se alternativt kontaktar Bolagets
          dataskyddsombud vars kontaktuppgifter framgår ovan. Bolaget är i
          enlighet med personuppgiftslagen (1998:204) personuppgiftsansvarig och
          har adressen Siktgatan 1, 162 50 Vällingby, Sverige. Genom att använda
          Tjänsten, Webbplatsen och/eller prenumerera på Bolagets nyhetsbrev
          accepterar och godkänner Konsumenten att Bolaget behandlar dina
          personuppgifter enligt av som framgår av denna integritets- och
          cookiepolicy.
        </p>

        <br></br>

        <h5>Kort översikt över behandlingen av personuppgifter</h5>
        <p>
          Bolaget respekterar Konsumentens rätt till integritet och all
          behandling av Konsumentens personuppgifter sker i enlighet med den
          svenska personuppgiftslagen. För Konsumentens bekvämlighet har Bolaget
          skapat följande sammanfattning, men Bolaget rekommenderar att
          Konsumenten sätter sig in i denna integritets- och cookiespolicy i sin
          helhet för att Konsumenten ska förstå hur Bolaget samlar in och
          behandlar dina personuppgifter. Om Konsumenten har ett avtal med
          Bolaget gällande Tjänsten, behandlar Bolaget Konsumentens
          personuppgifter för att fullgöra Bolagets åtaganden gentemot
          Konsumenten, och för att förbättra och vidareutveckla Tjänsten.
          Bolaget behandlar också Konsumentens personuppgifter för att kunna ge
          Konsumenten erbjudanden, rekommendationer och innehåll anpassade för
          dina användningsmönster, analysera och att förstå marknadstrender och
          för att begränsa missbruk av Tjänsten och/eller Webbplatsen. De
          personuppgifter som samlas in för dessa ändamål inkluderar både
          information som Konsumenten själv har lämnat (namn, e-postadress etc.)
          och information som samlats in genom användning av Webbplatsen.
        </p>

        <br></br>

        <h5>Grundläggande om behandlingen av personuppgifter</h5>
        <p>
          Personuppgifter är all slags information som direkt eller indirekt kan
          hänföras till en fysisk person som är i livet. Som exempel kan nämnas
          namn, adress, telefonnummer och e-postadress. Behandling av
          personuppgifter är varje åtgärd eller serie av åtgärder som vidtas i
          fråga om personuppgifter, såsom exempelvis insamling, registrering,
          organisering, lagring och utlämnande. Bolagets behandling av
          personuppgifter omfattas av personuppgiftslagen.
        </p>

        <br></br>

        <h5>Vilken typ av information samlar Bolaget in?</h5>

        <br></br>

        <h6>Personlig information:</h6>
        <ul>
          <li>För- och efternamn</li>
          <li>Personnummer</li>
          <li>Adress och kartinformation</li>
          <li>E-postadress</li>
          <li>Telefonnummer</li>
          <li>Kundnummer</li>
          <li>IP-adresser</li>
          <li>
            Identifiering av kundinformation (t.ex. lösenord, identifiering av
            webbläsare etc.)
          </li>
          <li>Betalningsinformation</li>
        </ul>

        <br></br>

        <h6>Beställningsinformation:</h6>
        <ul>
          <li>Information om den beställda Tjänsten</li>
          <li>Beställningsdatum och varaktighet</li>
          <li>Orderfrekvens och relaterad information</li>
          <li>Kunds adress där Tjänsten ska utföras </li>
          <li>
            Kundinformation såsom i tillämpliga fall information om husdjur
          </li>
          <li>Prisinformation (t.ex. totalt pris, rabattkoder etc.)</li>
          <li>Betalningsinformation</li>
          <li>Kundrecensioner</li>
        </ul>

        <br></br>

        <h6>Teknisk information om din enhet och internetanslutning</h6>
        <p>
          Genom serverloggar och andra verktyg registrerar Bolaget information
          om den enhet och anslutning till Webbplatsen som Konsumenten använder,
          inklusive operativsystem, webbläsarversion, IP-adresser, cookies och
          unika identifieringsfiler. Denna information används ofta i anonym och
          aggregerad statistik, men Bolaget kan också koppla informationen till
          ditt användarkonto. Informationen möjliggör bland annat att Bolaget
          kan anpassa visningen av Webbplatsen till den enhet Konsumenten
          använder, samt analysera olika sätt användarna använder Webbplatsen
          på.
        </p>

        <br></br>

        <h6>Cookies och annat innehåll som lagras lokalt</h6>
        <p>
          När Konsumenten besöker Webbplatsen använder Bolaget olika teknologier
          för att känna igen Konsumenten som användare. Till exempel använder
          Bolaget cookies för att Konsumenten ska slippa logga in varje gång
          Konsumenten besöker Webbplatsen.
        </p>

        <br></br>

        <h5>För vilka ändamål behandlar Bolaget personuppgifterna?</h5>
        <p>
          Bolagets insamling och behandling av dina personuppgifter sker i syfte
          att administrera och fullgöra Bolagets åtaganden gentemot Konsumenten,
          för att förbättra och vidareutveckla tillhörande tjänster, inklusive
          anpassa och förbättra Webbplatsen, och för att informera Konsumenten
          om nya användningsområden samt följa tillämplig lagstiftning och
          myndighetsbeslut. Bolaget behandlar även Konsumentens personuppgifter
          för att kunna ge Konsumenten erbjudanden och rekommendationer
          anpassade till ditt användningsmönster, för analysering för att förstå
          marknadstrender och för att försöka förutse framtida beteenden.
        </p>

        <br></br>

        <h6>Personlig information och användningsområden:</h6>
        <ul>
          <li>Kundidentifiering och åtkomstkontroll </li>
          <li>Leverans av den beställda Tjänsten till kund</li>
          <li>Upprätthålla och analysera kundkommunikation</li>
          <li>
            Utveckling, rapportering (inklusive orderhistorik) och anpassning av
            Tjänsten
          </li>
          <li>Förbättra användarupplevelsen</li>
          <li>Förstå marknadstrenderna</li>
          <li>Förhindra missbruk</li>
          <li>Marknadsföring (i enlighet med kundens uttryckliga samtycke)</li>
        </ul>

        <br></br>

        <p>
          Mer information om ändamålet för Bolagets behandling av dina
          personuppgifter framgår nedan.
        </p>

        <br></br>

        <h6>Administration av Konsumentens köp av Tjänsten</h6>
        <p>
          Bolaget behandlar för- och efternamn, personnummer, adress och
          kartinformation, e-postadress, telefonnummer, kundnummer,
          betalningsinformation, information om den beställda Tjänsten,
          beställningsdatum och varaktighet, orderfrekvens och relaterad
          information, kundinformation såsom i tillämpliga fall information om
          husdjur, prisinformation (t.ex. totalt pris, rabattkoder etc.),
          betalningsinformation, kundrecensioner för att kunna administrera
          Konsuments köp och för att fullfölja Bolagets åtaganden gentemot
          Konsument. Den lagliga grunden för Bolagets behandling är att den är
          nödvändig för att Bolaget ska kunna fullgöra avtalet avseende
          Konsuments köp. Om uppgifterna inte lämnas till Bolaget har Bolaget
          inte möjlighet att genomföra Konsuments köp på Webbplatsen eller
          uppfylla Bolagets åtaganden i förhållande till Konsument.
        </p>
        <p>
          Bolaget behandlar uppgifter om Konsuments namn, köp och
          kontaktuppgifter för att kunna hantera Konsuments eventuella anspråk
          på ångerrätt, reklamation och garanti. Bolaget behandlar uppgifter om
          Konsuments namn och personnummer samt uppgifter om Konsuments
          ekonomiska situation för att kunna fastställa Konsuments identitet och
          göra kreditprövningar.
        </p>

        <br></br>

        <h6>Administration av Konsumentens användarkonto på Webbplatsen</h6>
        <p>
          För Konsument som registrerat ett användarkonto på Webbplatsen
          behandlar Bolaget, utöver de personuppgifter som nämns ovan, även
          Konsumentens födelsedatum, orderhistorik, IP-adress och identifiering
          av kundinformation (t.ex. lösenord, identifiering av webbläsare etc.).
          Personuppgifter behandlas för att Bolaget ska kunna administrera
          Konsumentens användarkonto och för att underlätta för Konsumenten som
          användare. Konsumentens orderhistorik behandlas också för att
          Konsumenten ska kunna ha kontroll på sina historiska inköp. Bolaget
          använder också Konsumentens IP-adress för att Konsumenten ska kunna
          spara sin kundkorg till ett senare tillfälle. Den lagliga grunden för
          behandlingen är Konsumentens samtycke som Konsumenten lämnat i samband
          med att Konsumenten registrerat sitt användarkonto hos Bolaget. Det är
          inte nödvändigt att Konsumenten lämnar dessa uppgifter för att
          genomföra ett köp hos Bolaget, men om Konsumenten vill registrera ett
          användarkonto krävs att Konsumenten samtycker till denna behandling.
        </p>

        <br></br>

        <h6>Marknadsföringsändamål m.m.</h6>
        <p>
          Bolaget vill gärna att Konsumenten fortsätter vara kund hos Bolaget
          och för att Konsumenten ska få ta del av Bolagets erbjudanden kommer
          Bolaget även att under en period om ett år från och med Konsumentens
          senaste köp använda Konsumentens e-mailadress, adress och ditt
          telefonnummer för att skicka Bolagets nyhetsbrev via e-mail, post och
          sms. Den rättsliga grunden för Bolagets behandling av dessa
          personuppgifter i syfte att skicka ut nyhetsbrev är Bolagets
          berättigade intresse av att kommunicera med Konsumenten som kund i
          marknadsföringssyfte.
        </p>
        <p>
          Om Konsumenten har lämnat ditt samtycke till Bolagets behandling av
          personuppgifter för marknadsföringsändamål, t.ex. i samband med att
          Konsumenten registrerat ditt konto så kan Konsumentens
          personuppgifter, i form av namn, hemadress, e-mailadress och
          telefonnummer även komma att användas för direktmarknadsföring,
          nyhetsbrev, kundundersökningar samt för statistikändamål och kan
          användas för utskick per post, e-post och sms till Konsumenten fram
          till dess att Konsumenten avregistrerar sitt konto eller återkallar
          sitt samtycke. Den lagliga grunden för behandlingen är Konsumentens
          samtycke.
        </p>
        <p>
          Profilering Om Konsument samtyckt till det kommer Bolaget att
          analysera Konsuments kön, din ålder, köphistorik, köpbeteende och
          surfbeteende på Bolagets sajt i syfte att få mer kunskap om
          Konsumenten och Bolagets kunder i allmänhet samt för att kunna
          förbättra Bolagets erbjudanden (profilering). För samma ändamål kan
          Bolaget även komma att behandla eventuella personliga önskemål eller
          preferenser som Konsumenten lämnat till Bolaget, t.ex. information om
          att Konsument har barn eller är intresserad av en speciell kategori
          varor. Detta gör Bolaget genom att analysera vilka köp Konsumenten
          tidigare gjort och vilka varor Konsumenten tittat på eller lagt i
          kundkorgen på Bolagets sajt. Baserat på denna information skickar
          Bolaget riktade personliga och relevanta erbjudanden till Konsumenten.
          Den lagliga grunden för behandlingen är ditt samtycke. Konsumenten är
          aldrig skyldig att samtycka till att Bolaget profilerar dina
          uppgifter, men uppgifterna samlar Bolaget in automatiskt.
        </p>

        <br></br>

        <h5>Hur länge sparar Bolaget Konsuments personuppgifter?</h5>
        <p>
          Personuppgifter som Bolaget behandlar i syfte att administrera
          Konsuments köp eller för att fullgöra Bolagets skyldigheter som
          Bolaget har gentemot Konsument enligt avtal sparas inte under en
          längre tid än vad som är nödvändigt för att Bolaget ska kunna
          administrera Konsuments köp eller fullgöra Bolaget avtal med
          Konsument. För bokföringsändamål sparar Bolaget i enlighet med
          gällande bokföringslagstiftning information om Bolagets avtal med
          Konsument i sju år.
        </p>
        <p>
          I enlighet med gällande lagstiftning har Bolaget rätt att lagra
          Konsuments kontaktuppgifter för direktmarknadsföring under en tid
          efter Konsuments köp eller att Bolagets avtal med Konsument löpt ut.
          Beroende på vad avtalet rör är denna tid olika lång. Bolaget kan
          därför komma att lagra Konsuments kontaktuppgifter för att skicka
          direktmarknadsföring till Konsument under den tid Bolaget har rätt att
          göra så enligt gällande dataskyddslagstiftning, marknadsföringslag och
          praxis. Om/När Konsument särskilt lämnat samtycke till att få
          direktmarknadsföring kommer Bolaget att spara personuppgifter
          bestående av Konsuments namn, e-mail och adress tills Konsument
          avregistrerar sig eller ber Bolaget sluta kontakta Konsument. För
          Konsument som registrerat ett användarkonto hos Bolaget behandlar
          Bolaget samtliga Konsuments personuppgifter till dess att Konsument
          avregistrerar sitt konto hos Bolaget eller annars begär att Bolaget
          ska radera vissa uppgifter. Bolaget raderar dock alltid Konsuments
          konto om Konsument har varit inaktiv i ett år. Köphistorik gallras
          under alla omständigheter senast efter ett år efter gjort köp.
          Konsuments personuppgifter kan sparas längre än vad som angetts ovan i
          den mån Bolaget är skyldiga att göra det enligt lag, förordning eller
          myndighetsbeslut.
        </p>

        <br></br>

        <h6>Lagring av betalningsinformation</h6>
        <p>
          Om Konsumenten har ett prenumerationsavtal på Tjänsten som Konsumenten
          betalar med kreditkort lagras betalningsinformation automatiskt så att
          Bolaget kan debitera kortet. Kreditkortsinformation lagras aldrig i
          sin helhet i Bolagets register. Informationen är lagrad på en
          webbserver, där endast personuppgiftsbiträde har tillgång till
          informationen. Bolaget har tillgång till en unik kortidentifierare så
          att Bolaget kan debitera betalkort för kunders räkning, och
          informationen kan inte användas på något annat sätt än för utförandet
          av Bolagets tjänster.
        </p>

        <br></br>

        <h6>Källor till information</h6>
        <p>
          Information om Bolagets kunder samlas huvudsakligen in genom kunders
          registrering och beställning på Webbplatsen, samt i samband med samtal
          och e-post med kundservice. Andra informationskällor samlas in genom
          att Konsumenten använder Tjänsten och Webbplatsen, till exempel
          återkoppling om städning.
        </p>

        <br></br>

        <h5>Användning av cookies</h5>
        <p>
          Webbplatsen använder cookies . Huvudsyftet med cookies är att
          identifiera användare mellan sidvisningar. Cookies används också för
          att förbättra kvaliteten och utveckla funktionaliteten på Webbplatsen
          samt för insamling av analytisk data. Bolaget använder även cookies
          för att kunna utveckla Tjänsten och Webbplatsen. Vissa processer
          kräver att cookies lagras i Konsumentens webbläsare för att Bolaget
          ska kunna utvärdera effekterna av förändringar på Webbplatsen med
          Bolagets kunder. Konsumenten som användare kan neka eller återkalla
          samtycke till användning av cookies (till exempel tredjepartscookies)
          genom att ändra sina webbläsarinställningar och ta bort icke önskad
          cookie. För att Webbplatsen ska kunna fungera ordentligt måste dock
          minst en cookies godkännas.
        </p>

        <br></br>

        <h6>Vad är cookies?</h6>
        <p>
          En cookie är en liten textfil som sparas på din dator, mobiltelefon
          eller surfplatta vilken hjälper Bolaget att göra Konsumentens besök på
          Webbplatsen mer meningsfullt och positivt för Konsumenten. Cookies kan
          till exempel innehålla användarinställningar och information om hur
          Konsumenten har surfat på och använt Webbplatsen och vilken webbläsare
          Konsumenten använder.
        </p>

        <br></br>

        <h6>Vad sägs om cookies från annonsörer och andra tredje parter?</h6>
        <p>
          När Konsumenten använder Webbplatsen kan annonsörer och andra tredje
          parter placera cookies (tredjepartcookies) för att mäta och analysera
          effekten av bannerannonsering. Bolaget har utarbetat strikta
          riktlinjer för hur sådana aktörer får samla in och använda data om
          Bolagets användare. Riktlinjerna innebär att tredje part inte får
          samla in data från Webbplatsen för att bygga profiler, intressegrupper
          eller på annat sätt spåra beteenden för att kunna rikta in annonser.
          Data får endast samlas in för att analysera och mäta effekten av
          annonseringen, inklusive ange antalet annonsvisningar, klick och
          konvertering. Cookies och annan spårningsteknik får inte heller
          innebära behandling av personuppgifter utan särskilt samtycke från
          användaren.
        </p>

        <br></br>

        <h6>Hur kan Konsumenten se vilka cookies som lagras i webbläsaren?</h6>
        <p>
          I webbläsarinställningarna hittar Konsumenten vanligtvis en översikt
          över alla cookies som lagras så att Konsumenten kan få en översikt och
          ta bort eventuella oönskade cookies. Webbläsaren lagrar vanligtvis
          alla cookies i en specifik mapp på hårddisken, så att Konsumenten
          också kan granska innehållet i detalj. Det finns också tjänster som är
          speciellt utformade för att användare ska kunna följa cookies och
          andra spårningsmekanismer. Se exempelvis www.ghostery.com eller
          www.disconnect.me (Bolaget har ingen koppling till dessa tjänster och
          tar inget ansvar om Konsumenten klickar på länkarna). Vilka kakor som
          används på Webbplatsen På Webbplatsen används följande cookies. ...
          Vid frågor om cookies vänligen kontakta Bolagets dataskyddsombud.
          Dataskyddsombudets kontaktuppgifter framgår ovan.
        </p>

        <br></br>

        <h6>Dessa uppgifter från cookies samlas in:</h6>

        <ul>
          <li>IP-adress</li>
          <li>
            Webbläsare (Chrome, Internet Explorer, Firefox etc.)
            <ul>
              <li>Operativsystem (Windows etc.)</li>
              <li>
                Hur du kom till vår hemsida, t.ex. via länkar eller via Google
              </li>
              <li>Besökta sidor</li>
            </ul>
          </li>
        </ul>

        <br></br>

        <h6>
          För att följa upp användningen av vår hemsida lagrar Atmosphere
          webbstatistiken för att kunna:
        </h6>

        <ul>
          <li>Säkra den tekniska funktionaliteten och användningen av nätet</li>
          <li>
            Utveckla webbplatsen för att förbättra upplevelsen av hemsidan
          </li>
          <li>Förbättra våra elektroniska tjänster</li>
          <li>
            Stödja marknadsföringen av våra produkter och tjänster på hemsidan
          </li>
        </ul>

        <br></br>

        <h5>Vilka kan få tillgång till Konsumentens personuppgifter?</h5>
        <p>
          Konsumentens uppgifter behandlas som utgångspunkt endast av Bolaget.
          Bolaget kan även komma att dela Konsumentens information med Bolagets
          utvalda samarbetspartners och leverantörer i enlighet med nedan.
        </p>
        <ul>
          <li>
            För betalning eller kreditupplysning kan Bolaget komma att dela
            uppgifter om Konsumentens kortnummer eller personnummer samt
            uppgifter om Konsumentens ekonomiska situation till
            betalningsförmedlare och kreditföretag.
          </li>
          <li>
            För marknadsföringsändamål kan Bolaget komma att dela Konsumentens
            kontaktuppgifter till reklambyråer och tryckerier för t.ex. tryck
            och distribution av utskick.
          </li>
          <li>
            Bolagets interna och externa IT-leverantörer och eventuella andra
            leverantörer Bolaget anlitar kan komma att få tillgång till samtliga
            personuppgifter Bolaget hanterar om Konsumenten, dock endast i den
            utsträckning det är nödvändigt i syfte att fullgöra Bolagets
            åtaganden gentemot Konsumenten.
          </li>
          <li>
            För utförandet av Tjänsten kan Bolaget anlita att underleverantörer
            som då kan komma att få tillgång till de personuppgifter Bolaget
            hanterar om Konsumenten, dock endast i den utsträckning det är
            nödvändigt i syfte att fullgöra Bolagets åtaganden gentemot
            Konsumenten.
          </li>
        </ul>

        <br></br>

        <h6>
          Överföringar i allmänhet och överföring av data och information inom
          EU/EES eller utanför EU/EES-området
        </h6>
        <p>
          Bolaget behandlar som huvudregel endast Konsumentens personuppgifter
          inom EU/EES. I de fall personuppgifter behandlas utanför EU/EES
          föreligger antingen ett beslut från kommissionen om att tredjelandet
          ifråga säkerställer en adekvat skyddsnivå eller lämpliga
          skyddsåtgärder, i form av standardavtalsklausuler, bindande
          företagsinterna regler eller Privacy Shield, som säkerställer att
          Konsumentens rättigheter skyddas. Om Konsumenten vill få en kopia av
          de skyddsåtgärder som Bolaget vidtagit eller information om var dessa
          gjorts tillgängliga kan Konsumenten få detta genom att kontakta
          Bolagets dataskyddsombud.
        </p>

        <br></br>

        <h6>Registerskydd</h6>
        <p>
          Registret skyddas av adekvata tekniska och administrativa åtgärder.
          Bolaget har etablerat rutiner och åtgärder för att säkerställa att
          obehöriga inte får tillgång till Konsumentens personuppgifter och att
          all behandling av uppgifterna sker i enlighet med gällande
          lagstiftning. Åtgärderna innefattar bland annat regelbundna
          riskbedömningar, tekniska system och fysiska förfaranden för att
          säkerställa informationssäkerheten och rutiner för att verifiera
          insyns- och rättelseförfrågningar. Personuppgifter hanteras endast av
          anställda hos Bolaget. Tillgång till personuppgifterna är begränsat
          och registret är skyddat av brandväggar och åtkomstkontroll.
        </p>

        <br></br>

        <h6>Insynsrätt</h6>
        <p>
          Konsumenten har rätt att när som helst återkalla hela eller delar av
          ett lämnat samtycke om behandling av personuppgifter. Återkallelsen av
          sådant samtycke får dock ingen effekt på Bolagets behandling av
          Konsumentens personuppgifter för tiden innan återkallelsen ägde rum.
        </p>
        <p>
          I enlighet med gällande dataskyddslagstiftning har Konsumenten rätt
          att få tillgång till information om vilka personuppgifter som Bolaget
          behandlar om Konsumenten samt rätt att begära rättelse av dina
          personuppgifter. Om Konsumenten vill ha sådan information kan
          Konsumenten begära detta genom att kontakta Bolagets dataskyddsombud.
          Kontaktuppgifter till Bolagets dataskyddsombud framgår ovan. Begäran
          måste innehålla nödvändig kontaktinformation så att Bolaget enkelt kan
          identifiera Konsumenten och därefter ge nödvändig tillgång och
          information. Nödvändig kontaktinformation inkluderar namn,
          e-postadress och adress.
        </p>
        <p>
          Konsumenten har under vissa förutsättningar även rätt att begära
          radering eller begränsning av sina personuppgifter eller invända mot
          Bolaget behandling. Konsumenten har också rätt att inge klagomål till
          svenska Datainspektionen eller annan behörig tillsynsmyndighet som
          utövar tillsyn över företags hantering av personuppgifter.
        </p>
        <p>
          Konsument har även rätt att under vissa förutsättningar få ut de
          personuppgifter som rör Konsument som Konsument lämnat till Bolaget i
          ett strukturerat, allmänt använt och maskinläsbart format och har rätt
          att överföra dessa till en annan personuppgiftsansvarig
          (dataportabilitet).
        </p>

        <br></br>

        <h6>Rätten att ändra information</h6>
        <p>
          Konsumenten har rätt att ändra sin egen information genom att logga in
          på Webbplatsen och gå till sin profil. Om detta inte är möjligt av
          olika anledningar, vänligen kontakta dataskyddsombudet vars
          kontaktuppgifter framgår ovan. Kontaktuppgifter till Bolagets
          dataskyddsombud framgår ovan. Begäran måste innehålla nödvändig
          kontaktinformation så att Bolaget enkelt kan identifiera Konsumenten
          och därefter ge nödvändig tillgång och information. Nödvändig
          kontaktinformation inkluderar namn, e-postadress och adress.
        </p>

        <br></br>

        <h6>Samtycke och förbud</h6>
        <p>
          Konsumenten har rätt att när som helst invända mot behandlingen av
          sina personuppgifter för direktmarknadsföring. Konsumenten kan invända
          mot behandlingen av sina personuppgifter för direktmarknadsföring
          genom att logga in på Webbplatsen och ändra sina
          profilinställningarna. Om Konsumenten inte vill att Bolaget ska
          använda denna information använder Bolaget endast informationen för
          att kommunicera med Konsumenten för att fullgöra Bolagets åtaganden
          gentemot Konsumenten.
        </p>
      </Column>
      <BookingHorizontalSection />
    </MainLayout>
  );
};

const Column = styled.div`
  padding: 0 20% 5% 20%;
  margin-top: 200px;

  > h2 {
    margin-bottom: 45px;
  }

  > h3 {
    margin-bottom: 40px;
  }

  > h5 {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  > h6 {
    margin-bottom: 10px;
  }

  > ul > li {
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    padding: 0 18px 10%;
    margin-top: 18%;

    > h2 {
      margin-bottom: 55px;
    }
  }
`;

const Circle1 = styled(Circle)`
  position: absolute;
  opacity: 0.5;
  top: -25%;
  left: -10%;
  width: 50%;
  padding-bottom: 50%;
  background: ${(props) => props.theme.colors.primaryLight2};
  z-index: -1;

  @media (max-width: 600px) {
    top: -10%;
    left: -24%;
    width: 100%;
    padding-bottom: 100%;
  }
`;

export default Integrity;
