import React, { createContext, Component, PropsWithChildren } from "react";

export type TObserverContext = {
  entries: IntersectionObserverEntry[];
  observe: (t: Element) => void;
  unobserve: (t: Element) => void;
  getEntry: (t: Element) => IntersectionObserverEntry | void;
};
const Context = createContext<TObserverContext>({
  entries: [],
  observe: () => {},
  unobserve: () => {},
  getEntry: () => {},
});
const Consumer = Context.Consumer;

class Provider extends Component<PropsWithChildren> {
  observer: IntersectionObserver | null = null;
  state: { entries: IntersectionObserverEntry[] } = {
    entries: [],
  };

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  observerCallback: IntersectionObserverCallback = (entries, observer) => {
    this.setState({ entries });
  };

  getObserver() {
    if (!this.observer) {
      this.observer = new window.IntersectionObserver(this.observerCallback);
    }
    return this.observer;
  }

  render() {
    return (
      <Context.Provider
        value={{
          entries: this.state.entries,
          observe: (target) => this.getObserver().observe(target),
          unobserve: (target) => this.getObserver().unobserve(target),
          getEntry: (target) =>
            this.state.entries.find((e) => e.target === target),
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

const Observer = {
  Provider,
  Consumer,
  Context,
};

export { Provider, Consumer, Context };

export default Observer;
