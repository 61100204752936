

import { Link, useLocation } from "react-router-dom";
// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";

import styled from "@emotion/styled";

import { css } from "@emotion/react";

import { ROUTES } from "../routes";

import MainLayout from "../components/layout/MainLayout";
import Circle from "../components/Circle";
import { useScrollToTop } from "../lib/react/hooks";
import { BookingHorizontalSection } from "../components/sections";

interface ITermsProps {}

export const Terms = (props: ITermsProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  return (
    <MainLayout>
      <div css={css``} />
      <Circle1></Circle1>
      <Column>
        <h2>Allmänna villkor</h2>

        <p>
          Dessa allmänna villkor (”Allmänna villkor”) gäller mellan Atmosphere Sweden AB (nedan kallat ”Företaget”, ”Atmosphere" eller ”Tjänstleverantören") med alla dess dotterbolag, som har organisationsnummer 559250-1935 och vars registrerade adress är Siktgatan 1, 162 50  Vällingby, Sverige och Atmospheres kunder, såväl privata kunder som affärskunder (nedan gemensamt kallade ”Kunder”). Dessa Allmänna villkor träder i kraft 23.04.2020 och kan läsas på Atmospheres webbplats www.atmosphere.nu (”Webbplatsen”).
        </p>

        <br></br>

        <h5>1. Föremål för och syfte med villkoren</h5>
        <p>
          Syftet med dessa Allmänna villkor är att fastställa de allmänna villkoren enligt vilka Företaget kommer att tillhandahålla professionella städtjänster för Kunden. 
        </p>

        <br></br>

        <h5>1.1 Information om Företaget</h5>
        <p>
          Företaget erbjuder Kunden städning i hemmet och på kontor (”Tjänsten”). Avtalet om Tjänsten (”Avtalet”) består av (i) dessa Allmänna villkor; (ii) de särskilda villkoren för Tjänsten beträffande priser, omfattning och övriga villkor som avtalats mellan Kunden och Företaget (”Särskilda villkor”); och (iii) den gällande prislistan. 
        </p> 
        <p>
          Vid eventuella avvikelser mellan dessa Allmänna villkor och något av ovanstående dokument gäller villkoren i dessa Allmänna villkor. Ovan nämnda bilagor gäller i fallande numerisk ordning såvida inte annat uttryckligen anges i relevant bilaga. 
        </p>
        <p>
          Företaget förbehåller sig rätten att göra ändringar i dessa Allmänna villkor. Sådana ändringar meddelas Kunden 30 dagar före ikraftträdandet av de ändrade Allmänna villkoren. 
          Om Kunden inte accepterar ändringarna har denna rätt att säga upp avtalet när ändringarna träder i kraft med förbehåll för att Kunden inkommer med skriftlig uppsägning till Företaget minst 14 dagar innan ändringarna träder i kraft.
        </p>

        <br></br>

        <h5>1.2 Information om Tjänsten</h5>
        <p>
          Då det finns ett bindande avtal mellan Kunden och Företaget åligger det Företaget att se till att Tjänsten utförs i enlighet med Avtalet. Företaget förbinder sig till att säkerställa att Tjänsten utförs på ett professionellt sätt. 
          För att kunna utföra Tjänsten kan Företaget använda sig av samarbetspartner (”Kontrakterad städare”). 
        </p>
        <p>
          I såväl bokningsprocessen som bokningsbekräftelsen till Kunden ges detaljerad information om den erforderliga utrustningen, städredskapen och rengöringsmedlen som Kunden själv måste tillhandahålla.
        </p>
        <p>
          Vidare måste Kunden lämna nödvändig information till Företaget om var ovanstående redskap finns i dennes lokaler så att Tjänsten kan utföras effektivt samt för att säkerställa tillgängligheten till utrustningen, städredskapen och rengöringsmedel som behövs.
          Om Kunden har valt att Tjänsten ska utföras med hjälp av de städredskap och rengöringsmedel som tillhandahålls av Kunden åligger det Kunden att se till att städaren har tillgång till dem.
        </p>

        <br></br>

        <h5>2. Beställning och avbokning av städtjänst</h5>
        <p>
          Kunden är bunden av Tjänsten och Avtalet när den faktiska beställningen skickas till Företaget via Webbplatsen eller betalas för till Företagets representant. Avtalet mellan Kunden och Företaget har ingåtts när Företaget har bekräftat Kundens beställning via e-post. Vid ingående av ovan nämnda Avtal förbehåller sig Företaget rätten att inhämta sedvanliga kreditupplysningar om Kunden.
        </p>
        <p>
          Kunden har rätt att avbeställa eller omboka Tjänsten upp till fyrtioåtta (48) timmar innan Tjänstens starttid utan att någon avgift (definieras nedan i dessa Allmänna villkor) debiteras. Vid avbeställning mindre än fyrtioåtta (48) timmar innan utförande av Tjänsten sker ingen återbetalning till Kunden. Avbeställning på grund av sjukdom återbetalas till 100 procent förutsatt att ett läkarintyg uppvisas och att ett meddelande om avbeställning ges före den avtalade starttiden för utförande av Tjänsten. 
        </p>
        <p>
          Frånvaro eller försummad inställelse av Kunden, eller om Företagets städare inte har tillträde till lokalerna på den bokade tiden, räknas som avbeställning mindre än 48 timmar innan utförande av Tjänsten. 
          Det är inte möjligt att boka om tiden för utförandet av en Tjänst mindre än 48 timmar innan Tjänstens starttid. Om Kunden vill boka om tiden för utförandet av en Tjänst mindre än 48 timmar innan Tjänstens starttid görs en separat bokning för utförande av en ny Tjänst. Vår avbeställningspolicy gäller då för den Tjänst som Kunden ville boka om tiden för.
        </p>
        <p>
          Avbeställningar görs i skriftlig form till Företagets fysiska adress ELLER via Företagets digitala kanaler, t.ex. e-post eller Atmospheres hemsida.
          Om det inte går att debitera Kunden för Tjänsten på förhand annulleras bokningen 48 timmar innan Tjänstens starttid. 
          Enligt svensk lagstiftning och vår praxis för svenska Kunder debiteras den annullerade Tjänsten till fullt pris innan skatteavdrag (RUT-avdrag). 
        </p>

        <br></br>

        <h5>3. Uppsägning</h5>
        <p>
          Abonnemangsavtalen sägs upp på Kundens initiativ och i enlighet med den omfattning som avtalats med Kunden. Det finns ingen uppsägningstid för Kunden förutom ovan nämnda avbokningsvillkor. Uppsägning av abonnemangsavtal görs i skriftlig form till Företagets fysiska adress ELLER via Företagets digitala kanaler, t.ex. e-post eller Atmospheres hemsida.
          Kunden har rätt att säga upp Avtalet med omedelbar verkan om Företaget underlåter att leverera Tjänsterna enligt Avtalet.
        </p>
        <p>
          Företaget har rätt att säga upp Avtalet om Kunden underlåter att uppfylla förpliktelserna i Avtalet och inte omedelbart vidtar korrigerande åtgärder enligt Företagets önskemål. Företaget har rätt att säga upp Avtalet med omedelbar verkan med skriftligt meddelande till Kunden om Kunden i väsentlig grad bryter mot villkoren i Avtalet eller om det blir uppenbart att Kunden inom en snar framtid i väsentlig grad kommer att bryta mot villkoren i Avtalet. Vidare förbehåller sig Företaget rätten att, om inte annat avtalats skriftligen, säga upp Avtalet och upphöra med sitt Tjänsteutbud efter eget gottfinnande med minst 30 dagars skriftligt varsel till Kunden. 
          Avtalet hävs också omedelbart om Företaget underlåter att debitera sin ordinarie avgift för Tjänsten innan Tjänsten tillhandahålls. 
          I dessa situationer är Företaget skyldigt att omgående informera Kunden om Avtalets upphörande.
        </p>
        <p>
          I enlighet med lokal lagstiftning har Kunden, i egenskap av att vara en fysisk person (”Kund”), rätt att säga upp Avtalet inom 14 dagar efter ingåendet av Avtalet (tidsfrist för uppsägning). Kunden är införstådd med att om denna önskar att tillhandahållandet av Tjänsten inleds före utgången av uppsägningstiden får inte ångerrätten utövas efter att Tjänsten har levererats. Om Kunden vill utöva ångerrätten måste denna skicka ett tydligt meddelande om sitt beslut om uppsägning av Avtalet (t.ex. i ett brev skickat per post eller via e-post) till Företaget. Företagets kontaktuppgifter anges ovan.
        </p>
        <p>
          Om Kunden drar sig ur Avtalet betalar Företaget tillbaka alla betalningar som mottagits från Kunden, inklusive leveranskostnader. Återbetalningen kommer att ske utan onödigt dröjsmål, och under alla omständigheter senast fjorton (14) dagar från det datum Företaget informerades om Kundens beslut att dra sig ur Avtalet. Företaget använder samma betalningsmetod för återbetalningen som Kunden själv använde för den ursprungliga affärshändelsen såvida inte Kunden uttryckligen kommit överens med Företaget om något annat.
        </p>

        <br></br>

        <h5>4. Kampanjer</h5>
        <p>
          Kampanjpriser kan vara föremål för separata och begränsade villkor. Om villkoren inte uppfylls förbehåller sig Företaget rätten att debitera Kunden för det belopp som motsvarar rabatten som beviljades i samband med kampanjen.
        </p>

        <br></br>

        <h5>5. Sekretess</h5>
        <p>
          Alla anställda i Företaget, och alla Kontrakterade städare, har undertecknat ett sekretessavtal som förbjuder dem att avslöja någon information om Kunden eller dennas personliga omständigheter som de fått kännedom om. Företaget kommer inte att yppa någon Kundinformation för tredje part med undantag för de partner vars medverkan i tillhandahållandet av Tjänsten är oundgänglig. Utan hinder av det föregående sagda har Företaget rätt att lämna ut Kundens kontaktuppgifter i de fall sådant utlämnande av uppgifter krävs enligt lag, förordning eller föreläggande utfärdat av behörig myndighet.
        </p>

        <br></br>

        <h5>6. Personlig integritet</h5>
        <p>
          Personuppgifter om Kunden behandlas i enlighet med lokal lagstiftning samt i enlighet med Företagets integritets- och cookie-policy, som publiceras på: 
        </p>

        <Link to={ROUTES.INTEGRITY}>
          Vår integristets- och cookiepolicy
        </Link>
        

        <br></br>
        <br></br>

        <h5>7. Betalning och betalningsvillkor</h5>
        <p>
          Avgiften för Tjänsten (”Avgiften”) beräknas vid beställning på grundval av information från Kunden. Om det framgår att Kundens uppgifter, som beräkningen av Avgiften baseras på, på något sätt är felaktiga (t.ex. att felaktiga uppgifter om bostadens eller lokalernas storlek har uppgetts) kommer Avgiften att justeras genom ömsesidig överenskommelse mellan Företaget och Kunden i enlighet med gällande prislista för Tjänster med motsvarande omfattning (”Justerad avgift”).
        </p>
        <p>
          Enligt lokal lagstiftning och praxis debiteras en svensk Kund Avgiften efter RUT-avdraget. Personnumret som anges på beställningen kommer att användas för avdraget. Vid RUT-avdrag för flera personer är kunden skyldig att skriftligt meddela Företaget så att en överenskommelse kan bli nådd om huruvida RUT-avdraget ska fördelas mellan personerna. Det är Kunden som är ansvarig för att RUT-avdrag görs enligt det personnummer som angetts. Kunden är skyldig att skriftligen meddela Företaget om ändringar av Kundens möjlighet till RUT-avdrag. Om Skatteverket, efter begäran om utbetalning av RUT-avdrag, helt eller delvis inte medger utbetalning till Företaget har Företaget rätt att fakturera Kunden resterande del av de relaterade kostnaderna.
        </p>

        <br></br>

        <h5>8. Priser och fakturering</h5>
        <p>
          Priserna för Tjänsterna är i enlighet med den giltiga prislistan på Företagets webbplats såvida inte annat avtalats i de Särskilda villkoren mellan Kunden och Företaget. Kunden accepterar prissättningen när denna accepterar villkoren för Tjänsten och betalar för Tjänsten första gången. Priserna inkluderar en mervärdesskatt enligt gällande mervärdesskattesats. För engångsstädning betalar Kunden för städningen vid beställningen. Kostnaden för regelbunden städning debiteras tidigast tio (10) dagar före leverans av Tjänsten, och belastas Kundens kreditkort. Policyn, som återges på Företagets webbplats vid ”FAQ”, kan emellertid variera beroende på landet ifråga.
        </p>
        <p>
          Företaget förbehåller sig rätten att ändra priserna. Eventuella ändringar i prislistan meddelas Kunden skriftligen 30 dagar före de nya priserna börjar gälla.
        </p>
        <p>
          Företaget förbinder sig att till Kunden återbetala hela det belopp som betalats för outförda Tjänster om Företaget inte kan leverera den beställda Tjänsten.
        </p>
        <p>
          Vid beställning enligt abonnemangsavtal belastar Företaget Kundens kreditkort vid beställningstidpunkten för utförande av Tjänsten en gång. Därefter debiteras Kunden innan nästa utförande av Tjänsten. Vid en överenskommelse att Tjänsten ska utföras endast en gång belastas Kundens kreditkort vid tidpunkten för köpet. Kunden debiteras skillnaden mellan Avgiften och den justerade Avgiften i efterhand.
        </p>
        <p>
          Om Kundens kreditkortsbetalning misslyckas av någon anledning vid tidpunkten för debitering försöker Företaget att belasta kreditkortet upprepade gånger tills betalningen genomförs. I annat fall träder vår avbeställningspolicy i kraft. Om betalningen inte gått igenom efter det andra försöket kontaktar Företaget Kunden. Om det inte går att lösa problemet med debitering av Kundens kreditkort genom Företagets samarbete med Kunden har Företaget rätt att avbryta utförandet av Tjänsten till dess att den utestående betalningen har fullgjorts i sin helhet.
        </p>

        <br></br>

        <h5>9. Kundens medverkan</h5>
        <p>
          Kunden ska medverka till att Tjänsten kan utföras. Kunden ska, på begäran, lämna upplysningar om särskilda omständigheter som Företaget måste ta hänsyn till vid utförandet av Tjänsten. För att Tjänsten ska kunna utföras måste Kunden, i rimlig utsträckning, själv städa upp lösa föremål innan Tjänsten utförs. Vidare får inte lokalerna som ska städas vara i sämre skick än vad som är fallet efter normal användning av dem. 
        </p>
        <p>
          Kunden ska se till att städarna har tillträde till fastigheten från första dagen för utförandet av Tjänsten tills slutdatumet i enlighet med Avtalet med Företaget. Om det finns ett larmsystem i lokalerna ska Kunden underrätta Företaget och städarna om detta innan första dagen för utförandet av Tjänsten, och instruera städarna om hur man använder larmsystemet. Om inte Kunden ger anvisningar om användningen av larmsystemet och städarna oavsiktligt utlöser larmet är det Kunden som bär ansvaret för eventuella konsekvenser och därmed förenade kostnader. 
        </p>
        <p>
          Våra städare är utbildade yrkesmän och yrkeskvinnor, och handlar i överensstämmelse med detta när de tillhandahåller Tjänsten för Kunden. Våra städare kommer att utföra Tjänsten inom den tidsperiod som avtalats mellan Kunden och Företaget. 
        </p>
        <p>
          Kunden är ansvarig för att städarna har tillgång till varmt och kallt vatten samt fungerande eluttag under utförandet av Tjänsten. Städarna ska också ha rätt att använda Kundens toaletter om nödvändigt av arbetsmiljöskäl. 
        </p>
        <p>
          Kunden ska sörja för att det inte råder några förhållanden, eller finns löst springande husdjur, i fastigheten eller lokalerna som kan äventyra utförandet av Tjänsten eller utgöra en fara för städarna. Städarna har rätt att avbryta utförandet av Tjänsten om de har rimlig anledning att tro att utförandet av Tjänsten utgör en fara för hälsan, säkerheten och miljön. Om Tjänsten inte kan utföras och detta beror på Kunden kommer Företaget att debitera Kunden för de kostnader som Företaget åsamkas i samband med detta.
        </p>
        <p>
          Vid en städares årliga semester och eventuella sjukledighet försöker Företaget att ordna med en ersättare för hen, och kontaktar omgående Kunden för att komma överens om en lämplig lösning. Om en städare plötsligt blir sjuk räknas inte detta som dröjsmål i tillhandahållandet av Tjänsten enligt avsnitt 10 nedan. Företaget kommer i detta fall att ordna med en separat Tjänst genom att gemensamt med Kunden komma överens om den.
        </p>

        <br></br>

        <h5>10.	Klagomål på utförande av, dröjsmål vid tillhandahållande av och brist i utförande av Tjänst</h5>

        <p>a) Klagomål</p>

        <p>
          Klagomål kan riktas, utan opåkallat dröjsmål, i skriftlig form till Företagets fysiska adress ELLER via Företagets digitala kanaler, t.ex. e-post eller Atmospheres hemsida.. Detsamma gäller misstanke om stöld. Kunden måste lämna nödvändiga detaljuppgifter och bevis (t.ex. bilder) på skadorna eller undermåligt utförd Tjänst. Kunden har rätt att klaga inom rimlig tid, eller senast 3 dagar efter utförandet av Tjänsten, i skriftlig form till Företagets fysiska adress ELLER via Företagets digitala kanaler, t.ex. e-post eller Atmospheres hemsida..
        </p>

        <br></br>

        <p>b) Reklamationer</p>

        <p>
          Ett fel föreligger om Tjänsten inte utförs enligt vad Kunden har rätt att förvänta sig av en professionell städservice, och enligt vad som i övrigt avtalats. Det föreligger emellertid inget fel i utförandet av Tjänsten om avvikelsen beror på omständigheter från Kundens sida, inklusive men inte begränsat till bristande medverkan av Kunden. Om Kunden vill hävda brist i utförandet av Tjänsten ska denna meddela Företaget inom rimlig tid, eller senast 5 dagar efter det att Kunden uppmärksammade, eller borde ha uppmärksammat, bristen i utförandet av Tjänsten (klagomål). I händelse av ett fel eller en brist i utförande av Tjänst kan Kunden kräva gottgörelse, prisavdrag eller uppsägning av Avtalet. Om det begåtts ett fel i utförande av Tjänst har Företaget rätt att åtgärda detta om det kan ske inom rimlig tid och med rimliga resurser. Det är Företaget som ensamt bär ansvaret för detta. Om Företaget är oförmöget att avhjälpa bristen kan Företaget komma att lämna ett proportionellt prisavdrag på nästa Tjänst. Om det visar sig att det inte föreligger något fel i utförandet av Tjänsten kan Kunden bli skyldig att betala de relaterade kostnader som Företaget åsamkats.
        </p>

        <br></br>

        <p>c) Dröjsmål vid tillhandahållande av Tjänst</p>

        <p>
          Dröjsmål uppstår om Tjänsten inte utförs inom den tidsperiod som avtalats mellan Kunden och Företaget. Det kan inte anses att dröjsmål förekommit om detta berott på omständigheter från Kundens sida, inklusive men inte begränsat till bristande medverkan av Kunden. 
        </p>
        <p>
          Om det förekommit dröjsmål kan Kunden antingen kräva att Tjänsten utförs eller begära uppsägning av Avtalet och få därtill hörande ersättning. Kunden kan säga upp Avtalet om konsekvenserna av dröjsmålet är avgörande för Kunden. En Kund som kräver utförande av Tjänsten oavsett dröjsmålet får inte säga upp Avtalet om Tjänsten utförs på ett rimligt sätt eller inom den tidsgräns som Kunden fastställt. Om det före utförandet eller slutförandet av Tjänsten står klart att Tjänsten inte kommer att utföras utan dröjsmål, vilket ger Kunden rätt att säga upp Avtalet, kan Kunden säga upp Avtalet innan slutförandet av Tjänsten.
        </p>

        <br></br>

        <h5>11.	Ansvarsbegränsning</h5>
        <p>
          Kunden kan kräva ersättning för förlust på grund av brist i utförande av Tjänst. Detta gäller emellertid inte i den utsträckning det bevisas att förlusten berodde på ett hinder utanför Företagets kontroll, och som Företaget rimligen inte kunde ha förväntats ta hänsyn till under Avtalets löptid, eller som Företaget rimligen inte kunde ha förväntats kunna undvika eller övervinna konsekvenserna av under Avtalets löptid.
        </p>
        <p>
          För skador på annat än föremål eller egendom som Tjänsten inkluderar, eller på något annat än vad som är nära anknutet till den Tjänsten, kan Kunden begära ersättning i den utsträckning Företaget inte kan påvisa att sådan förlust inte berodde på fel eller grov oaktsamhet från Företagets sida. Detsamma gäller för föremål eller egendom som det uppstår skador på och som är i Företagets förvar eller på annat sätt är under Företagets kontroll. För förlust eller annat i anknytning till Tjänsten som inte är en följd av dröjsmål vid eller fel i utförande av Tjänst är Företaget ansvarigt när förlusten beror på fel eller grov oaktsamhet från Företagets sida.
        </p>
        <p>
          Företaget kommer på egen bekostnad att anskaffa en lämplig kommersiell allmän ansvarsförsäkring från ett välrenommerat försäkringsbolag som täcker Företagets förpliktelser enligt detta avtal, och upprätthålla denna ansvarsförsäkring under Avtalets löptid. Företagets totala, kumulativa ansvar enligt detta avtal eller vid utförande av Tjänsten ‒ inomkontraktuellt, utomkontraktuellt eller på annan grund ‒ ska vara begränsat till en sådan ansvarsförsäkring.
        </p>

        <br></br>

        <h5>12.	Force majeure</h5>
        <p>
          Force majeure-händelse innebär att en part inte kan fullgöra sina förpliktelser enligt detta avtal på grund av ett hinder utanför dennas kontroll som parten inte Kunde ha tagit hänsyn till vid tidpunkten för Avtalets uppgörande, och vilkens konsekvenser parten rimligen inte Kunde ha undvikit eller övervunnit.
        </p>
        <p>
          Företaget är inte ansvarigt för fall av dröjsmål eller omständigheter där vi inte kan leverera på grund av force majeure. Exempel på force majeure-händelser är strejker, uppror, naturkatastrofer, strömavbrott eller avsaknad av mobiltäckning.
        </p>

        <br></br>

        <h5>13.	Avtals- och lagtolkning</h5>
        <p>
          Dessa Allmänna villkor och relaterade avtal tolkas och tillämpas uteslutande i enlighet med svensk lagstiftning utan hänsyn till dess lagvalsregler.
        </p>

        <br></br>

        <h5>14.	Konfliktlösning</h5>
        <p>
          Parterna ska i första hand försöka lösa tvister, kontroverser eller anspråk enligt eller i förhållande till dessa Allmänna villkor och relaterade avtal genom förhandlingar i godo. Om parterna inte kan enas kan tvisten avgöras av Allmänna Reklamationsnämnden med adress Box 174, 101 23 Stockholm, www.arn.se, i den mån nämnden är behörig att pröva frågan. Tvist, kontrovers eller anspråk med anledning av eller rörande detta avtal; eller överträdelse av, uppsägning av eller giltighet för detta avtal; ska avgöras genom uppgörelse i godo i domstolsanknuten medling vid tingsrätten i Stockholm, Sverige.
        </p>

        <br></br>
        <br></br>
        <br></br>

        <h3>Bokningsvillkor</h3>

        <h5>Garanti och Kvalitét</h5>
        <p>
          Vi vill självklart att du ska vara nöjd med våra tjänster. Skulle ni upptäcka att vi missat något vi kommit överens om, meddela detta snarast, senast inom 24 timmar så kommer vi och åtgärdar detta så snart vi kan. Vi jobbar aktivt med kvalitetskontroller och utbildning av vår personal.
        </p> 
        <ul>
          <li>
            Fakturering sker månadsvis i efterskott. Det gäller både abonnemangstjänster och enstaka uppdrag. Du som abonnerar på hemstädning kan även välja autogiro som betalningsmetod. 
          </li>
          <li>
            Atmosphere AB förbehåller sig rätten att inhämta kreditupplysning innan avtal ingås, samt möjligheten att avböja att ingå avtal mot bakgrund av vad som framgår av kreditupplysningen.
          </li>
        </ul>

      </Column>
      <BookingHorizontalSection />
    </MainLayout>
  );
};

const Column = styled.div`
  padding: 0 20% 5% 20%;
  margin-top: 200px;

  > h2 {
    margin-bottom: 45px;
  }

  > h3 {
    margin-bottom: 40px;
  }

  > h5 {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  > ul > li {
    margin-bottom: 15px;
  }

  @media (max-width: 600px) {
    padding: 0 18px 10%;
    margin-top: 18%;

    > h2 {
      margin-bottom: 55px;
    }
  }
`;

const Circle1 = styled(Circle)`
  position: absolute;
  opacity: 0.5;
  top: -25%;
  left: -10%;
  width: 50%;
  padding-bottom: 50%;
  background: ${props => props.theme.colors.primaryLight2};
  z-index: -1;

  @media (max-width: 600px) {
    top: -10%;
    left: -24%;
    width: 100%;
    padding-bottom: 100%;
  }
`;

export default Terms;
