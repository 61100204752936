import React from "react";
// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";

// import styled from "@emotion/styled";

import { useScrollToTop } from "../lib/react/hooks";
import MainLayout from "../components/layout/MainLayout";


interface IGardenProps {}

export const Garden = (props: IGardenProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  return (
    <MainLayout>
      <div css={css`margin: 500px 0`} />
      <h1>Garden</h1>
    </MainLayout>
  );
};

export default Garden;
