import React, { FC } from "react";
import styled from "@emotion/styled";

// import { lighten, darken } from "polished";
interface IButtonRadio {
  title: string;
  description: string;
  isChecked: boolean;
  label?: string;
  infoText?: string;
  className?: string;
  onClick: (event: React.MouseEvent) => void;
}
export const ButtonRadio: FC<IButtonRadio> = ({
  className,
  isChecked,
  title,
  description,
  label,
  infoText,
  onClick
}) => {
  return (
    <StyledButton onClick={onClick} isChecked={isChecked} className={className}>
      {infoText && <InfoText>i<span>{infoText}</span></InfoText>}
      {label && <span>{label}</span>}
      <h6>{title}</h6>
      <p>{description}</p>
    </StyledButton>
  );
};

interface IStyledButton {
  isChecked: boolean;
}
const StyledButton = styled.button<IStyledButton>`
  position: relative;
  padding: 25px 25px;
  margin: 5px 5px 5px 5px;
  background: white;
  border-radius: 0 15px 15px;
  border: 2px solid ${props =>
    props.isChecked ? props.theme.colors.primary: props.theme.colors.secondary};
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  h6 {
    margin-bottom: 0.3rem;
    border-radius: 0 15px 15px;
    color: ${props =>
      props.isChecked ? props.theme.colors.primary: props.theme.colors.secondary};
  }
  p {
    color: ${props =>
      props.isChecked ? props.theme.colors.primary: props.theme.colors.grey1};
    font-size: .85rem;
    margin-bottom: 0;
  }

  > span {
    position: absolute;
    color: white;
    font-size: 0.85rem;
    padding: 4px 12px;
    border-radius: 50px;
    margin: 0;
    background: ${props => props.theme.colors.primary};

    top: -8px; 
    right: 15px;
  }

  ::after {
    position: absolute;
    top: 5px;
    left: 5px;
    content: "";
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: ${props =>
      props.isChecked ? props.theme.colors.primary: "white"};
    border: 2px solid ${props =>
      props.isChecked ? props.theme.colors.primary: props.theme.colors.secondary};
  }
`;

const InfoText = styled.div`
  position: absolute;
  background: #d4d4d4;
  border-radius: 100px;
  color: white;
  width: 21px;
  height: 21px;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: arial sans-serif;

  > span {
    position: absolute;
    background: rgba(0,0,0,0.75);
    border-radius: 10px;
    padding: 10px 15px;
    color: white;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease-out;
    pointer-events: none;
    width: 200px;
    text-align: left;
    z-index: 999999999999999999;
    font-size: 0.85rem;
    font-family: "Poppins", sans-serif;
  }

  &:hover {
    background: ${props => props.theme.colors.primary};
  
    > span {
      bottom: 10px;
      opacity: 1;
    }
  }
`;

export default ButtonRadio;
