import React, { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { ExpandMore } from "@mui/icons-material";

interface IChevronIcon extends PropsWithChildren {
  showMore?: boolean;
}
export const ChevronIcon = styled<FC<IChevronIcon>>(
  ({ showMore, children, ...props }) =>
    React.createElement(ExpandMore, props, children)
)`
  transform: rotate(90deg);
  transition: transform 0.4s ease-out !important;
  width: 24px !important;
  height: 24px !important;
`;

export default ChevronIcon;
