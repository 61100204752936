import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
// import { useQuery } from "@apollo/react-hooks";
// import { size, lighten } from "polished";
import dayjs from "dayjs";
import _ from "lodash";
// import gql from "graphql-tag";
import styled from "@emotion/styled";
import { ROUTES } from "../routes";

import { useForm, UseFormRegister } from "react-hook-form";

import Button from "../components/Button";
import Summary from "../components/Summary";
import { InfoMessage } from "../components/InfoMessage";
import ButtonRadio from "../components/ButtonRadio";
import SummaryToggle from "../components/SummaryToggle";
import DatePicker from "../components/forms/DatePicker";
import SelectForm from "../components/forms/SelectForm";
import ContactForm from "../components/forms/ContactForm";
import ShowMoreIcon from "../components/icons/ShowMoreIcon";
import BookingLayout from "../components/layout/BookingLayout";
import LocationInputs from "../components/forms/LocationInputs";
import VerifyLabel from "../components/VerifyLabel";
import { Collapse } from "@mui/material";
// import { ExpandMore } from "@mui/icons-material";
import {
  FormSection,
  FormSectionContainer,
} from "../components/forms/FormSection";
import LoadingThrobber from "../components/LoadingThrobber";

import { ReactComponent as CheckmarkSvg } from "../assets/svg/icon-check.svg";
import { useScrollToTop, useDebounce } from "../lib/react/hooks";

import {
  useBusinessCleaningQuoteLazyQuery,
  useAvailableCleaningTimesLazyQuery,
  useLocationLazyQuery,
  useBookBusinessCleaningMutation,
  // useIsUserLoggedInQuery,
  IBusinessCleaningFormInput,
  // ICleaningAdditionsInput,
  ICleaningFrequency,
  ILocationQuery,
  IAreaInput,
  // Scalars,
} from "../graphql/generated.types";

// const Information =
//   "Under den rådande COVID-19 pandemin tar vi med oss desinficerande medel för rengöring av diverse handtag & ytor!";

const InformationTerms =
  "Du måste godkänna Atmospheres villkor för att slutföra bokningen!";

const BOOKING_DURATIONS = [3, 4, 5, 6, 7, 8, 9, 10, 11];
const AREA_TO_DURATION = [
  { min: 0, max: 80, recommendedDuration: 4 },
  { min: 81, max: 150, recommendedDuration: 5 },
  { min: 151, max: 200, recommendedDuration: 6 },
  { min: 201, max: 250, recommendedDuration: 7 },
  { min: 251, max: 300, recommendedDuration: 8 },
  { min: 301, max: 500, recommendedDuration: 9 },
  { min: 501, max: 700, recommendedDuration: 10 },
  { min: 701, max: 1000, recommendedDuration: 11 },
];

const INITIAL_FORM = {
  // address: null,
  // postalCode: null,
  area: (({ min, max }) => ({ min, max }))(AREA_TO_DURATION[0]),
  duration: AREA_TO_DURATION[0].recommendedDuration,
  // date: '2017-09-30T15:03:31',
  // date: "2017-09-30" as Scalars["Date"],
  // new Date(2020,4,13), //"2020-04-13", // change to today
  // timeRange: AVAILABLE_TIMES[0] as Scalars["Time"],
  additions: {
    refrigerator: false,
    ironing: false,
    dishes: false,
    garbage: false,
    windows: false,
    oven: false,
    tools: false,
  },
  useRUT: false,
  frequency: ICleaningFrequency.Weekly,
  // other
};

const FAQ_CONTENT = [
  {
    question: "Vad ingår i en företagsstädning?",
    answer: (
      <>
        {"Kontorsytor:"}
        <ul>
          <li> {"Dammsuga & moppa golv."} </li>
          <li> {"Dammtorka ytor."} </li>
          <li> {"Torka av alla skrivbord."} </li>
          <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
          <li> {"Slänga sopor & byta påsar."} </li>
        </ul>
        {"Toaletter:"}
        <ul>
          <li> {"Dammsuga & moppa golv."} </li>
          <li> {"Dammtorka ytor."} </li>
          <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
          <li> {"Rengöra handfat & toalettstol."} </li>
          <li> {"Slänga sopor & byta påsar."} </li>
        </ul>
        {"Kök:"}
        <ul>
          <li> {"Dammsuga & moppa golv."} </li>
          <li> {"Dammtorka ytor."} </li>
          <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
          <li> {"Rengöra köksbänk, micro, diskho och spis."} </li>
          <li> {"Torka av skåp och kylskåp utvändigt."} </li>
          <li> {"Plocka in i diskmaskin & diska."} </li>
          <li> {"Slänga sopor & byta påsar."} </li>
        </ul>
        {"Detta ingår inte:"}
        <ul>
          <li> {"Fönsterputs."} </li>
          <li> {"Rengöring av väggar."} </li>
          <li> {"Rengöring av lister & karmar."} </li>
          <li> {"Rengöring av ugn."} </li>
        </ul>
        {
          "Självklart kan vi göra extra överenskommelser för att utföra det som normalt inte ingår!"
        }
        <br />
        <br />
        <a href={"tel:+4673-6771005"} rel="noopener noreferrer">
          Ring: 073-677 10 05
        </a>
      </>
    ),
  },
];

type Form = IBusinessCleaningFormInput;

interface IBookingBusinessProps {}
export const BookingBusiness = (props: IBookingBusinessProps) => {
  const {
    register,
    // getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
    handleSubmit,
    watch,
    formState,
    formState: { errors },
  } = useForm<Form>({
    mode: "onBlur",
    defaultValues: INITIAL_FORM,
  });

  const routerLocation = useLocation();
  const [postBookCleaning, bookCleaning] = useBookBusinessCleaningMutation();
  const [getQuote, quote] = useBusinessCleaningQuoteLazyQuery();
  const [getLocation, location] = useLocationLazyQuery({
    // fetchPolicy: "cache-and-network",
    onCompleted: (data: ILocationQuery) => {
      if (data.location.available) return clearErrors("location.postalCode");
      setError("location.postalCode", {
        type: "unavailable",
        message: "Tyvärr kan vi inte städa hos dig",
      });
    },
  });
  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const [getAvailableCleaningTimes, availableCleaningTimes] =
    useAvailableCleaningTimesLazyQuery();

  useScrollToTop([routerLocation && routerLocation.pathname]);

  const fields = watch();

  const priceFields = _.pick(fields, [
    "duration",
    "additions",
    "useRUT",
    "frequency",
  ]);
  const debouncedPriceFields = useDebounce(JSON.stringify(priceFields), 1000);

  const timeFields = {
    ..._.pick(fields, ["location", "frequency"]),
    duration: quote.data
      ? quote.data.businessCleaningQuote.lines.reduce(
          (acc, cur) => acc + cur.duration,
          0
        )
      : fields.duration,
  };
  const debouncedAvailableCleaningTimesFields = useDebounce(
    JSON.stringify(timeFields),
    2000
  );
  const debouncedPostalCode = useDebounce(fields.location?.postalCode, 200);

  useEffect(() => {
    if (
      formState.isDirty &&
      location.data?.location.available === false &&
      !errors.location?.postalCode
    ) {
      setError("location.postalCode", {
        type: "unavailable",
        message: "Tyvärr kan vi inte städa hos dig",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.location?.postalCode]);

  useEffect(() => {
    if (formState.isDirty) {
      const { area, duration, frequency, additions } = fields;
      getQuote({ variables: { area, duration, frequency, additions } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPriceFields]);

  useEffect(() => {
    if (formState.isDirty && location.data) {
      getAvailableCleaningTimes({ variables: { ...timeFields } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAvailableCleaningTimesFields]);

  useEffect(() => {
    if (
      formState.isDirty &&
      fields.location.postalCode &&
      RegExp(/^\d{3}[-\s]?\d{2}$/).test(fields.location.postalCode)
    ) {
      getLocation({ variables: { ...fields.location } });
      if (!quote.data) {
        const { area, duration, frequency, additions } = fields;
        getQuote({ variables: { area, duration, frequency, additions } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPostalCode]);

  useEffect(() => {
    if (formState.isDirty && formState.isValid && toggleButtonRef.current) {
      toggleButtonRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isValid]);

  useEffect(() => {
    const optionalFields: Array<keyof Form> = [
      "area",
      "frequency",
      "duration",
      "additions",
    ];
    optionalFields.forEach((name) => {
      register(name, { required: false });
    });
    const requiredFields: Array<keyof Form> = ["timeRange", "date"];
    requiredFields.forEach((name) => {
      register(name, { required: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetDate = async () => {
    if (fields.date) {
      setValue("date", null);
      await trigger("date");
    }
    if (fields.timeRange) {
      setValue("timeRange", null);
      await trigger("timeRange");
    }
  };

  const onAreaClickHandler = async (area: IAreaInput) => {
    await resetDate();
    setValue("area", area);
    const { recommendedDuration } =
      AREA_TO_DURATION.find(({ max }) => area.max === max) || {};
    if (recommendedDuration) {
      setValue("duration", recommendedDuration);
    }
  };

  const onDurationClickHandler = async (duration: Form["duration"]) => {
    setValue("duration", duration);
    await resetDate();
  };

  const onTimeClickHandler = async (timeRange: Form["timeRange"]) => {
    setValue("timeRange", timeRange);
    await trigger("timeRange");
  };

  // const onRadioClickHandler = (key: keyof ICleaningAdditionsInput) => {
  //   const additions = {
  //     ...fields.additions,
  //     [key]: fields.additions && !fields.additions[key],
  //   };
  //   setValue("additions", additions);
  //   if (key !== "tools") {
  //     resetDate();
  //   }
  // };

  const onFrequencyClickHandler = (frequency: Form["frequency"]) => {
    setValue("frequency", frequency);
  };
  const onToolsClickHandler = (tools: boolean) => {
    const additions = {
      ...fields.additions,
      tools,
    };
    setValue("additions", additions);
  };

  const onDatePickerClickHandler = async (date: Date) => {
    onTimeClickHandler(null);
    setValue("date", date);
    await trigger("date");
  };
  const getAreaText = ({ min, max }: IAreaInput) => {
    return `${min}-${max} kvm`;
  };
  const getDurationText = (duration: number) => {
    const { recommendedDuration } =
      AREA_TO_DURATION.find(({ max }) => fields.area.max === max) || {};
    const isRecommended = recommendedDuration === duration;
    return `${duration} Timmar ${isRecommended ? "(Rekommenderas)" : ""}`;
  };
  const getTimeText = (timeRange: Form["timeRange"]) => {
    const clockStart = dayjs(timeRange?.startTime).format("HH:mm");
    const clockEnd = dayjs(timeRange?.endTime).format("HH:mm");
    return timeRange ? `Klockan ${clockStart}-${clockEnd}` : "Vilken tid?";
  };
  const onSubmit = async (form: Form) => {
    await postBookCleaning({ variables: { form } }).catch((err) =>
      console.error(err)
    );
  };

  const getAvailableTimes = () => {
    const sameDay = (d1: Date, d2: Date) =>
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();

    return fields.date
      ? (
          availableCleaningTimes.data?.availableCleaningTimes.filter(
            ({ startTime, endTime }) =>
              fields.date && sameDay(new Date(startTime), new Date(fields.date))
          ) || []
        ).map(({ startTime, endTime }) => ({ startTime, endTime }))
      : [];
  };

  const success = bookCleaning.data?.bookBusinessCleaning.valueOf();
  const summaryQuote = {
    loading: quote.loading,
    data: quote.data?.businessCleaningQuote,
  };
  const [expandedIdx, setExpandedIdx] = useState<number | null>(null);
  const onExpandClick = (idx: number) =>
    setExpandedIdx(expandedIdx !== idx ? idx : null);

  const isExpanded = (idx: number) => idx === expandedIdx;
  return (
    <BookingLayout
      asideToggle={{
        Comp: SummaryToggle,
        props: {
          quote: summaryQuote,
          useRUT: false,
          isBusiness: true,
          ref: toggleButtonRef,
        },
      }}
      aside={
        !success ? (
          <Summary
            onSubmit={handleSubmit(onSubmit)}
            form={fields}
            formIsValid={formState.isValid}
            quote={summaryQuote}
            isBusiness={true}
          />
        ) : (
          <Confirmation>
            <CheckmarkImage>
              <CheckmarkSvg />
            </CheckmarkImage>
            <h4>Tack för din förfrågan!</h4>
            <p>
              Vi kontaktar dig så fort vi kan för att slutföra bokningen.
              Vanligtvis inom 30 minuter på vardagar!
            </p>
            <span>Ps. snart kan du betala här direkt vid bokningen.</span>
            <Link to={ROUTES.FEEDBACK}>
              <br></br>
              <p>Hjälp oss bli bättre!</p>
              <Button>Lämna feedback</Button>
            </Link>
          </Confirmation>
        )
      }
    >
      <Container>
        {/* <InfoMessage information={Information} /> */}
        <h3>Boka företagsstädning</h3>
        <p>
          Följ stegen nedan för att boka stans mest klimatsmarta städning till
          ditt företag!
        </p>
        <Questions>
          {FAQ_CONTENT.map(({ question, answer }, idx) => (
            <Question key={idx}>
              <div
                onClick={() => onExpandClick(idx)}
                aria-expanded={isExpanded(idx)}
                aria-label="show more"
              >
                <h6>{question}</h6>
                <ShowMoreIcon showMore={!isExpanded(idx)} />
              </div>
              <Collapse in={isExpanded(idx)} timeout="auto" unmountOnExit>
                {answer}
              </Collapse>
            </Question>
          ))}
        </Questions>
        {/* <LocationForm onSubmit={onLocationFormSubmit}/> */}
        <FormSectionContainer>
          <FormSection title={"Vart ska vi städa?"} disabled={false}>
            <LocationInputs
              register={
                register as unknown as UseFormRegister<Pick<Form, "location">>
              }
              formState={formState}
            />
            <LoadingThrobber isLoading={location.loading} minDuration={1000}>
              {(location.data?.location || errors.location) && (
                <VerifyLabel
                  isValid={!errors.location}
                  success={"Vi kan städa på din address!"}
                  failure={
                    (errors.location?.postalCode?.message || "") +
                    " " +
                    (errors.location?.address?.message || "")
                  }
                />
              )}
            </LoadingThrobber>
          </FormSection>

          <FormSection
            title={"Info om städningen"}
            disabled={!!errors.location || !location.data}
          >
            <WideSelectForm
              Selector={({ isExpanded }) => (
                <SelectItem>
                  {getAreaText(fields.area)}
                  <ShowMoreIcon showMore={!isExpanded} />
                </SelectItem>
              )}
            >
              {AREA_TO_DURATION.filter((i) => i.max !== fields.area.max).map(
                ({ min, max }, idx) => (
                  <SelectItem
                    key={idx}
                    onClick={() => onAreaClickHandler({ min, max })}
                  >
                    {getAreaText({ min, max })}
                  </SelectItem>
                )
              )}
            </WideSelectForm>
            <WideSelectForm
              Selector={({ isExpanded }) => (
                <SelectItem>
                  {getDurationText(fields.duration)}
                  <ShowMoreIcon showMore={!isExpanded} />
                </SelectItem>
              )}
            >
              {BOOKING_DURATIONS.filter((i) => i !== fields.duration).map(
                (duration, idx) => (
                  <SelectItem
                    key={idx}
                    onClick={() => onDurationClickHandler(duration)}
                  >
                    {getDurationText(duration)}
                  </SelectItem>
                )
              )}
            </WideSelectForm>
          </FormSection>

          <FormSection
            title={"Hur ofta ska vi städa?"}
            disabled={!!errors.location || !location.data}
          >
            <ButtonRadioContainer>
              <SmalButtonRadio
                onClick={() => onFrequencyClickHandler(ICleaningFrequency.Once)}
                isChecked={fields.frequency === ICleaningFrequency.Once}
                title={"En gång"}
                // description={"296kr/tim ex moms"}
                description={"429/tim ex moms"}
              />
              <SmalButtonRadio
                onClick={() =>
                  onFrequencyClickHandler(ICleaningFrequency.Weekly)
                }
                isChecked={fields.frequency === ICleaningFrequency.Weekly}
                title={"Varje vecka"}
                // description={"288kr/tim ex moms"}
                description={"393/tim ex moms"}
                label={"Populärt val"}
              />
              <SmalButtonRadio
                onClick={() =>
                  onFrequencyClickHandler(ICleaningFrequency.Biweekly)
                }
                isChecked={fields.frequency === ICleaningFrequency.Biweekly}
                title={"Varannan vecka"}
                // description={"288kr/tim ex moms"}
                description={"393/tim ex moms"}
              />
              <SmalButtonRadio
                onClick={() =>
                  onFrequencyClickHandler(ICleaningFrequency.Monthly)
                }
                isChecked={fields.frequency === ICleaningFrequency.Monthly}
                title={"Var fjärde vecka"}
                // description={"288kr/tim ex moms"}
                description={"393/tim ex moms"}
              />
            </ButtonRadioContainer>
          </FormSection>

          <FormSection
            title={"När ska vi städa?"}
            disabled={!!errors.location || !location.data}
          >
            <DatePicker
              selectedDate={fields.date}
              includeDates={_.uniqWith(
                availableCleaningTimes.data?.availableCleaningTimes.map(
                  ({ startTime }) => new Date(startTime)
                ) || [],
                (a, b) => dayjs(a).isSame(b, "day")
              )}
              onChangeHandler={onDatePickerClickHandler}
            />

            <WideSelectForm
              Selector={({ isExpanded }) => (
                <SelectItem>
                  {getTimeText(fields.timeRange)}
                  <ShowMoreIcon showMore={!isExpanded} />
                </SelectItem>
              )}
            >
              {getAvailableTimes()
                .filter((i) => i.startTime !== fields.timeRange?.startTime)
                .map((time, idx) => (
                  <SelectItem
                    key={idx}
                    onClick={() => onTimeClickHandler(time)}
                  >
                    {getTimeText(time)}
                  </SelectItem>
                ))}
            </WideSelectForm>
          </FormSection>

          <FormSection
            title={"Städmaterial"}
            disabled={
              !!errors.location ||
              !location.data ||
              !fields.date ||
              !fields.timeRange?.startTime
            }
          >
            <ButtonRadioContainer>
              <WideButtonRadio
                onClick={() => onToolsClickHandler(false)}
                isChecked={Boolean(!fields.additions.tools)}
                title={"Använd mitt egna städmaterial"}
                description={
                  "Du har dammsugare, mopp & hink, microfiberduk, disktrasa, svamp och rengöringsmedel för kök, badrum och golv."
                }
              />
              <WideButtonRadio
                onClick={() => onToolsClickHandler(true)}
                isChecked={Boolean(fields.additions.tools)}
                title={"Atmosphere tar med städmaterial"}
                description={
                  "Vi tar med oss vårt naturliga rengöringsmedel & material. Det enda du behöver ha på plats är dammsugare, mopp & hink!"
                }
                label={"Bra miljöval! +110kr"}
              />
            </ButtonRadioContainer>
          </FormSection>
          <FormSection
            title={"Din kontaktinformation"}
            disabled={
              !!errors.location ||
              !location.data ||
              !fields.date ||
              !fields.timeRange?.startTime
            }
          >
            <ContactForm
              register={
                register as unknown as UseFormRegister<Pick<Form, "contact">>
              }
              formState={formState}
              isBusiness={true}
            />
          </FormSection>
          <FormSection
            title={"Övrigt"}
            disabled={
              !!errors.location ||
              !location.data ||
              !fields.date ||
              !fields.timeRange?.startTime ||
              (!!errors.contact && !errors.contact.ssn) ||
              !fields.contact.companyName ||
              !fields.contact.firstname ||
              !fields.contact.surname ||
              !fields.contact.email ||
              !fields.contact.tel
            }
          >
            <TextAreaContainer>
              <br></br>
              <h6>
                Meddelande<span>(Ej obligatoriskt)</span>
              </h6>
              <textarea
                name="message"
                {...register}
                placeholder="Är det något mer vi behöver veta?"
              ></textarea>
            </TextAreaContainer>
            <CheckboxContainer>
              <input
                type="checkbox"
                // name="terms"
                id="checkboxTerms"
                // onChange={() => trigger("terms")}
                {...register("terms", {
                  required: true,
                })}
              />
              <label htmlFor="checkboxTerms">
                Jag godkänner{" "}
                <a
                  href={ROUTES.TERMS_OF_USE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Atmospheres villkor
                </a>
              </label>
              <br></br>
              <br></br>
              {!fields.terms && <InfoMessage information={InformationTerms} />}
            </CheckboxContainer>
          </FormSection>
        </FormSectionContainer>
      </Container>
    </BookingLayout>
  );
};

const Container = styled.div`
  margin: 0px 60px 100px;
  padding-top: 125px;

  @media (max-width: 900px) {
    margin: 78px 18px 185px;
    padding-top: 18px;
  }
`;

const Confirmation = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.secondaryLight2};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  text-align: center;

  > span {
    opacity: 0.5;
  }
`;

const CheckmarkImage = styled.div`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background: ${(props) => props.theme.colors.primary};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;

  > svg {
    width: 50px;
    height: 50px;
  }
`;

const Questions = styled.div`
  margin-top: 0px;
`;
const Question = styled.div`
  padding: 12px 0 0 0;
  border-radius: 35px;
  transition: 0.3s ease-out;
  color: ${(props) => props.theme.colors.primary};

  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .MuiCollapse-wrapperInner {
    background: ${(props) => props.theme.colors.secondary};
    border-radius: 0 0 35px 35px;
    color: white;
    margin: 0;
    padding: 35px;
    line-height: 1.618rem;

    > ul {
      padding-inline-start: 20px;
      margin-top: 0;
    }

    > a {
      color: ${(props) => props.theme.colors.primaryLight1};
    }
  }

  h6 {
    margin: 0 0 22px 0;
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary};

    h6 {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

// const IdNumberInput = styled.input`
//   border-radius: 5px;
//   border: 2px solid ${(props) => props.theme.colors.secondary};
//   background: white;
//   padding: 10px;
//   margin: 5px;
//   box-sizing: border-box;
//   outline: none;
//   width: 100%;
// `;

const TextAreaContainer = styled.div`
  width: 100%;

  > h6 {
    margin: 10px 5px 5px;
    display: flex;
    align-items: center;

    > span {
      opacity: 0.3;
      margin: 0 0 0 5px;
    }
  }

  > textarea {
    border-radius: 5px;
    border: 2px solid ${(props) => props.theme.colors.secondary};
    background: white;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
    outline: none;
    width: -webkit-fill-available;
    resize: none;
    min-height: 100px;
  }
`;

const CheckboxContainer = styled.div`
  diplay: block;
  width: 100%;
  margin: 20px 0 5px 5px;

  > h6 {
    margin: 0px 0px 15px;
    display: flex;
    align-items: center;

    > span {
      opacity: 0.3;
      margin: 0 0 0 5px;
    }
  }

  > label {
    margin-right: 15px;
  }

  > input {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: white;
    border: 2px solid ${(props) => props.theme.colors.secondary};
  }
`;
const WideSelectForm = styled(SelectForm)`
  width: 100%;
`;
const SelectItem = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  :hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;
const ButtonRadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`;
const SmalButtonRadio = styled(ButtonRadio)`
  flex: calc(50% - 10px);

  @media (max-width: 900px) {
    flex: calc(100% - 10px);
  }
`;
const WideButtonRadio = styled(ButtonRadio)`
  flex: 100%;
`;

// const FormSubTitle = styled.h6`
//   margin: 25px 5px 10px;
// `;

export default BookingBusiness;
