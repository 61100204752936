import React from "react";
import { useLocation } from "react-router-dom";
// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";
// import styled from "@emotion/styled";

import { useScrollToTop } from "../lib/react/hooks";
import MainLayout from "../components/layout/MainLayout";

// import svanen from "../assets/images/svanen.jpg";

import {
  CenterCircleSection,
  GlobalGoalsSection,
  SmallCirclesLeftSection,
  SmallCirclesRightSection,
  BookingHorizontalSection,
} from "../components/sections";

import productsImage from "../assets/images/products.png";
import staffImage from "../assets/images/person.jpg";
import carImage from "../assets/images/elbil.png";
import topImage from "../assets/images/room-flower-one.jpg";
import plantImage from "../assets/images/plant.png";

const TITLE = "Vårt miljöansvar";
const DESCRIPTION =
  "Att värna om miljön & den biologiska mångfalden ligger i vårat DNA. Vi förbrukar resurser på ett hållbart sätt och använder innovativa lösningar & tekniker för att minimera vår påverkan på miljön.";

const TITLELEFTSMALL = "Miljöanpassat rengöringsmedel";
const DESCRIPTIONLEFTSMALL =
  "Vi använder skonsamma och miljöanpassade rengöringsmedel när vi städar.";
// const DESCRIPTIONLEFTSMALL = "Vi skapar vårt egna rengöringsmedel på naturliga ingredienser så som citron, ättika, bikarbonat, såpa. Det blir ett skinande rent hem fritt från kemikalier och allergier.";

const TITLERIGHTSMALL = "Miljöcertifierad personal";
const DESCRIPTIONRIGHTSMALL =
  "Vi utbildar all vår personal inom resursförbrukning, återvinning, använding av rengöringsmedel och hantering av miljöfarligt avfall - det är vad vi kallar miljöcertifierad personal!";

const TITLELEFTSMALL2 = "Fossilfria bilar";
const DESCRIPTIONLEFTSMALL2 =
  "Våra bilar är helt fossilfria och drivs bara på el! Väljer du oss så vet du att atmosfären hålls ren genom hela resan - till och från städningen!";

const TitleRightSmall3 = "Ett nytt träd för varje städning";
const DescriptionRightSmall3 =
  "Att plantera träd anses vara ett av det mest kostnadseffektiva sättet att bekämpa växthuseffekten. Ca 17 %, ungefär 6 miljarder ton av jordens CO2 -utsläpp, kommer från ohållbar markanvändning och skövling av skog. Ett medelstort träd absorberar hundratals kilo CO2 under sin livstid. Att plantera träd är därför ett effektivt sätt att binda koldioxid och förbättra atmosfären.";

interface ILiabilityProps {}

export const Liability = (props: ILiabilityProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  return (
    <MainLayout>
      <CenterCircleSection
        image={topImage}
        title={TITLE}
        description={DESCRIPTION}
      />
      {/* <SvanenLogo></SvanenLogo> */}
      <SmallCirclesLeftSection
        image={productsImage}
        title={TITLELEFTSMALL}
        description={DESCRIPTIONLEFTSMALL}
      />
      <SmallCirclesRightSection
        image={staffImage}
        title={TITLERIGHTSMALL}
        description={DESCRIPTIONRIGHTSMALL}
      />
      <SmallCirclesLeftSection
        image={carImage}
        title={TITLELEFTSMALL2}
        description={DESCRIPTIONLEFTSMALL2}
      />
      <SmallCirclesRightSection
        image={plantImage}
        title={TitleRightSmall3}
        description={DescriptionRightSmall3}
      />
      <GlobalGoalsSection />
      <BookingHorizontalSection />
    </MainLayout>
  );
};

// const SvanenLogo = styled.div`
//   position: absolute;
//   width: 130px;
//   height: 130px;
//   background-color: white;
//   background-image: url(${svanen});
//   background-size: cover;
//   left: 38%;
//   z-index: 2;
//   transform: translateY(100%);
//   border-radius: 50%;

//   @media (max-width: 600px) {
//     width: 100px;
//     height: 100px;
//     left: 55%;
//     transform: translateY(-5%);
//   }
// `;

export default Liability;
