import styled from "@emotion/styled";
import { size } from 'polished';

interface ICircleProps {
  size?: number;
  background?: string
}
export default styled.div<ICircleProps>(props => ({
  ...props.size && size(props.size),
  ...props.background && {background: `url(${props.background})`},
  borderRadius: "50%",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat"
}));