import styled from "@emotion/styled";
import { fullscreenCircleSizeWidth } from "./FullscreenSection";

export const CircleContainer = styled.div`
  position: absolute;
  max-width: 1920px;
  width: 100%;
  height: ${fullscreenCircleSizeWidth};
  overflow: hidden;

  @media (max-width: 1920px) {
    overflow: hidden;
  }

  @media (max-width: 600px) {
    position: relative;
    height: auto;
    overflow: unset;
  }
`;
