import React from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as CalendarSVG } from "../../assets/svg/icon-calendar.svg";

import styled from "@emotion/styled";

interface IProps {
  onChangeHandler(
    date: Date | [Date, Date] | null,
    event: React.SyntheticEvent<any> | undefined
  ): void;
  includeDates?: Date[];
  selectedDate?: Date | null;
  className?: string;
}
export const DatePicker = ({
  onChangeHandler,
  includeDates,
  selectedDate,
  className,
}: IProps) => {
  return (
    <Container>
      <ReactDatePicker
        calendarClassName={className}
        onChange={(val, event) => {
          onChangeHandler(val, event);
        }}
        dateFormat="d MMMM, yyyy"
        todayButton="Idag"
        // maxDate={new Date(Date.now() + 12096e5)}
        includeDates={includeDates}
        // minDate={new Date()}
        selected={selectedDate}
        placeholderText={"Vilket datum?"}
        customInput={<CustomInput />}
      />
    </Container>
  );
};

export interface ICustomInputProps {
  onChange?: (event: React.ChangeEvent) => void;
  onClick?: (event: React.MouseEvent) => void;
  placeholder?: string;
  value?: string | number;
  isSecure?: any;
  id?: string;
}

const CustomInput = React.forwardRef<HTMLDivElement, ICustomInputProps>(
  (props, ref) => {
    const onClick = (event: React.MouseEvent) => {
      const y = window.scrollY;
      const noScroll = () => window.scrollTo(0, y);

      window.addEventListener("scroll", noScroll);
      if (props.onClick) props.onClick(event);
      setTimeout(() => {
        window.removeEventListener("scroll", noScroll);
      }, 50);
    };

    return (
      <Button ref={ref} onClick={onClick}>
        {props.value ? props.value : props.placeholder}
        <CalendarSVG />
      </Button>
    );
  }
);

const Button = styled.div`
  position: relative;
  background: white;
  margin: 5px;
  border: 2px solid ${(props) => props.theme.colors.secondary};
  border-radius: 5px;
  padding: 10px;
  min-height: 16px;

  &:hover {
    color: ${(props) => props.theme.colors.primary};

    > svg path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  > svg {
    position: absolute;
    right: 9px;
    bottom: 9px;
    width: 21px;
    height: 21px;
  }
`;

const Container = styled.div`
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
    cursor: pointer;
  }

  .react-datepicker-popper {
    z-index: 99999;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  background: white;
  border: none;
  font-family: poppins;
  margin: 0 5px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
  outline: none;

  animation: datepicker 0.5s ease-out;

  @keyframes datepicker {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .react-datepicker__header {
    background: ${(props) => props.theme.colors.secondary};
    padding-top: 15px;
  }

  .react-datepicker__navigation--next {
    margin-top: 5px;
    border-left-color: white;
    outline: none;
  }

  .react-datepicker__navigation--previous {
    margin-top: 5px;
    border-right-color: white;
    outline: none;
  }
  .react-datepicker__navigation-icon::before {
    top: 16px;
  }
  .react-datepicker__today-button {
    background: #ffffff;
    border-top: 1px solid #eaeaea;
    padding: 8px 0;
    color: ${(props) => props.theme.colors.primary};
    border-radius: 0 0 5px 5px;
  }

  .react-datepicker__month-container {
    width: 500px;
  }
  .react-datepicker__current-month {
    color: white;
  }
  .react-datepicker__day-name {
    width: calc(14.28% - 5px);
    height: 3.5rem;
    line-height: 3.5rem;
    color: white;
  }
  .react-datepicker__day {
    width: calc(14.28% - 5px);
    height: 3.5rem;
    line-height: 3.5rem;
    outline: none;
    transition: 0.3s ease-out;
  }
  .react-datepicker__day--today {
    border: 2px solid rgba(59, 57, 56, 0.1);
    border-radius: 5px;
  }
  .react-datepicker__day--selected {
    background: ${(props) => props.theme.colors.primary};
    border: none;
  }
  .react-datepicker__day--keyboard-selected {
    background: ${(props) => props.theme.colors.primary};
    border: none;
  }
  .react-datepicker__triangle {
    display: none;
  }

  @media (max-width: 900px) {
    .react-datepicker__month-container {
      width: calc(100vw - 46px);
    }

    .react-datepicker__day-name {
      height: 2.5rem;
      line-height: 2.5rem;
    }

    .react-datepicker__day {
      height: 2.5rem;
      line-height: 2.5rem;
    }
  }
`;

export default StyledDatePicker;
