import React from "react";
import { useLocation } from "react-router-dom";

// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";

import { useScrollToTop } from "../lib/react/hooks";
import MainLayout from "../components/layout/MainLayout";

import roomImage from "../assets/images/room-livingroom-two.jpg";
import natureImage from "../assets/images/flower-leaves.png";

import {
  BookingSection,
  ThreeCirclesSection,
  TwoCirclesRightSection,
  TwoCirclesLeftSection,
  ScoreSection,
  FaqSection,
  BookingHorizontalSection,
  TreeSection,
} from "../components/sections";

interface ILandingProps {}

export const Landing = (props: ILandingProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);

  return (
    <MainLayout>
      <BookingSection />
      <ThreeCirclesSection />
      <TwoCirclesRightSection image={roomImage} />
      <TreeSection />
      <TwoCirclesLeftSection image={natureImage} />
      <ScoreSection />
      <FaqSection.LandingPage />
      <BookingHorizontalSection />
    </MainLayout>
  );
};

export default Landing;
