import React, { FC, PropsWithChildren } from "react";
// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";
import styled from "@emotion/styled";

import Header from "../Header";
import Footer from "../Footer";

interface IMainLayoutProps extends PropsWithChildren {}

const MainLayout: FC<IMainLayoutProps> = ({ children }) => {
  return (
    <Container>
      <Header />
      <main>{children}</main>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

export default MainLayout;
