import React from "react";
import { css, Global } from "@emotion/react";
import { normalize } from "polished";
import { theme } from "./theme";

export const globalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
  ${normalize()}

  #root {
    height: 100%;
  }
  body {
    font-family: 'Poppins', sans-serif;
    background: ${theme.colors.background};
    color: ${theme.colors.secondary};
    overflow: hidden; // dont touch have to be like this to prevent background scrolling in ios modals

    input:disabled {
      opacity: 0.3;
      display: none;
    }

    input {
      padding: 10px;
      margin: 5px;
      border-radius: 5px;
      flex: calc(65% - 10px);
      box-sizing: border-box;
      border: 2px solid ${theme.colors.secondary};
      outline: none;
      color: ${theme.colors.secondary};
      width: -webkit-fill-available;

      &:hover {
        color: ${theme.colors.primary};
      }

      &:focus {
        border-color: ${theme.colors.primary};
      }
    }

    /* ===== ===== */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
      margin: 0 0 24px 0;
      color: ${theme.colors.secondary};
    }

    h1 {
      font-size: 5rem;
      font-weight: 700;
    }
    h2 {
      font-size: 4rem;
    }
    h3 {
      font-size: 3rem;
    }
    h4 {
      font-size: 2rem;
    }
    h5 {
      font-size: 1.5rem;
    }
    h6 {
      font-size: 1rem;
    }

    a {
      text-decoration: none;
      color: ${theme.colors.primary};
      transition: .3s ease-out;

      &:hover {
        color: ${theme.colors.primaryLight2};
      }

      @media (max-width: 600px) {
        &:hover: {
          color: unset;
        }
        &:clicked: {
          color: ${theme.colors.primaryLight2};
        }
      }
    }

    p, span {
      line-height: 1.618;
      margin: 0 0 14px 0;
      color: ${theme.colors.secondary};
    }

    li {
      line-height: 1.618;
    }
  }
  html {
    overflow-y: scroll; // dont touch have to be like this to prevent background scrolling in ios modals

    @media (max-width: 1200px) {
      h1 {
        font-size: 3.5rem;
      }
      h2 {
        font-size: 3rem;
      }
      h3 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1.8rem;
      }
      h5 {
        font-size: 1.6rem;
      }
      h6 {
        font-size: 1rem;
      }
    }

    @media (max-width: 900px) {
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 2rem;
      }
      h3 {
        font-size: 1.8rem;
      }
      h4 {
        font-size: 1.6rem;
      }
      h5 {
        font-size: 1.4rem;
      }
      h6 {
        font-size: 1rem;
      }
    }

    @media (max-width: 600px) {
      h1 {
        font-size: 2.4rem;
      }
      h2 {
        font-size: 1.8rem;
      }
      h3 {
        font-size: 1.6rem;
      }
      h4 {
        font-size: 1.4rem;
      }
      h5 {
        font-size: 1.2rem;
      }
      h6 {
        font-size: 1rem;
      }
    }

    @media (max-width: 320px) {
    }
  }
`;

export const GlobalStyles = () => (
  <Global styles={globalStyles} />
);
