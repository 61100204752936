import React, { FC } from "react";
import { Transition } from "react-transition-group";

interface IPropsFade {
  in: boolean;
  timeout?: number;
  children: React.ReactElement;
}

interface IPropsGrow {
  in: boolean;
  timeout?: number;
  children: React.ReactElement;
}

export const Fade: FC<IPropsFade> = ({ children, in: isIn, timeout = 0 }) => {
  const duration = 1000 

  const defaultStyle = {
    transition: `all ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    unmounted: { opacity: 0, transform: "translateY(20px)" },
    entering: { opacity: 0, transform: "translateY(20px)" },
    entered: { opacity: 1, transform: "translateY(0px)" },
    exiting: { opacity: 0, transform: "translateY(20px)" },
    exited: { opacity: 0, transform: "translateY(20px)" },
  };

  return (
    <Transition in={isIn} timeout={timeout}>
      {(state) =>
        React.cloneElement(children, {
          style: {
            ...defaultStyle,
            ...transitionStyles[state],
          },
        })
      }
    </Transition>
  );
};

export const Grow: FC<IPropsGrow> = ({ children, in: isIn, timeout = 0 }) => {
  const duration = 2200 

  const defaultStyle = {
    transition: `all ${duration}ms ease-in-out`,
  };

  const transitionStyles = {
    unmounted: { transform: "translateY(180px) scale(0)" },
    entering: { transform: "translateY(180px) scale(0)" },
    entered: { transform: "translateY(0px) scale(1)" },
    exiting: { transform: "translateY(180px) scale(0)" },
    exited: { transform: "translateY(180px) scale(0)" },
  };

  return (
    <Transition in={isIn} timeout={timeout}>
      {(state) =>
        React.cloneElement(children, {
          style: {
            ...defaultStyle,
            ...transitionStyles[state],
          },
        })
      }
    </Transition>
  );
};