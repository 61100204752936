import React, { FC } from "react";
import { css } from "@emotion/react";
import { size } from "polished";
import { Link as BaseLink } from "react-router-dom";
import styled from "@emotion/styled";

import { ROUTES } from "../routes";
// import Button from "./Button";
import BaseCircle from "./Circle";

import { ReactComponent as AtmosSvg } from "../assets/svg/atmos-symbol-invert.svg";
import { ReactComponent as MailSvg } from "../assets/svg/contact-mail.svg";
import { ReactComponent as PhoneSvg } from "../assets/svg/contact-phone.svg";
import { ReactComponent as FacebookSvg } from "../assets/svg/social-facebook.svg";
// import { ReactComponent as TwitterSvg } from "../assets/svg/social-twitter.svg";
import { ReactComponent as InstagramSvg } from "../assets/svg/social-instagram.svg";
import { ReactComponent as LinkedinSvg } from "../assets/svg/social-linkedin.svg";
import Neutral from "../assets/images/label-co2-website-white-en.png";

interface IFooter {}

export const Footer: FC<IFooter> = props => {
  return (
    <Section>
      <Links>
        <Link to={ROUTES.SERVICES}>Våra tjänster</Link>
        <Link to={ROUTES.FAQ}>Vanliga frågor</Link>
        <Link to={ROUTES.WORK_WITH_US}>Jobba med oss </Link>
        <Link to={ROUTES.TERMS_OF_USE}>Allmänna villkor</Link>
        <Link to={ROUTES.LIABILITY}>Miljöansvar</Link>
        <Link to={ROUTES.ABOUT}>Om oss</Link>
      </Links>

      <ContactContainer>
        <a href={"mailto:kontakt@atmosphere.nu?Subject=Kontakt%20Atmosphere"} target="_blank" rel="noopener noreferrer">
          <Contact>
            <ContactCircle>
              <MailSvg />
            </ContactCircle>
            <span>kontakt@atmosphere.nu</span>
          </Contact>
        </a>

        <a href={"tel:+4673-6771005"} rel="noopener noreferrer">
          <Contact>
            <ContactCircle>
              <PhoneSvg />
            </ContactCircle>
            <span>073-677 10 05</span>
          </Contact>
        </a>
      </ContactContainer>

      <LogoContainer>
        <AtmosLogo />
      </LogoContainer>

      <SocialLinks>
        <a href={"https://www.linkedin.com/company/42470646"} target="_blank" rel="noopener noreferrer"><LinkedinSvg /></a>
        <a href={"https://www.instagram.com/atmosphere_sweden_ab/"} target="_blank" rel="noopener noreferrer"><InstagramSvg /></a>
        {/* <a href={"https://twitter.com/AtmosphereSwe"} target="_blank" rel="noopener noreferrer"><TwitterSvg /></a> */}
        <a href={"https://www.facebook.com/Atmosphere-Sweden-AB-104456981200290"} target="_blank" rel="noopener noreferrer"><FacebookSvg /></a>
      </SocialLinks>

      <BottomWrapper>
        <p>Atmosphere Sweden AB, Org nummer: 559250-1935</p>
        <p>Fagerstagatan 18A, 163 53  Spånga</p>

        <a href={"https://tree-nation.com/profile/impact/atmosphere"} target="_blank" rel="noopener noreferrer"><Co2Neutral></Co2Neutral></a>
      </BottomWrapper>
    </Section>
  );
};

const Links = styled.div`
  display: flex;
  flex-direction: row;
  padding: 80px 60px 70px 60px;
  width: calc(100% - 250px);
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    padding: 80px 0 45px;
    width: 100%;
  }
`;
const Link = styled(BaseLink)`
  color: white;
  text-decoration: none;
  padding: 20px;
`;

const Co2Neutral = styled.div`
  width: 150px;
  height: 55px;
  background-size: contain;
  background-image: url(${Neutral});
  position: absolute;
  bottom: 80px;

  @media (max-width: 600px) {
    text-align: center;
    margin: 25px auto 0;
    position: unset;
    bottom: 0px;
  }
`;

const BottomWrapper = styled.div`
  padding: 0 0 80px 80px;
  position: relative;

  > p {
    color: white;
    margin: 0;
    opacity: 0.4;
    
    &:nth-of-type(2) {
      padding-bottom: 90px;
    }
  }

  > a {
    width: 150px;
    height: 55px;
  }

  @media (max-width: 600px) {
    padding: 0;

    > p {
      padding: 0 18px 10px;
      text-align: center;

      &:nth-of-type(2) {
        padding-bottom: 10px;
      }
    }
  }
`;


const SocialLinks = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  right: 80px;
  bottom: 80px;
  z-index: 1;
  margin-top: 15px;

  > a > svg {
    fill: white;
    margin-left: 20px;
    transition: .3s ease-out;
    cursor: pointer;
    width: 28px;
    height: 28px;

    &:hover {
      fill: ${props => props.theme.colors.primaryLight1};
      transform: scale(1.2);
    }
  }

  @media (max-width: 900px) {
    bottom: 120px;
  }

  @media (max-width: 600px) {
    position: unset;
    align-items: center;
    margin: 15px 0 35px;
    justify-content: center;
    margin-top: 0px;

    > svg {
      margin: 0 10px;
  }
`;

const sectionHeight = 470;
const Section = styled.section`
  position: relative;
  color: white;
  width: 100%;
  // height: ${sectionHeight}px;
  height: auto;
  background: ${props => props.theme.colors.secondary};
  border-radius: 0 120px 0 0; //backup
  border-radius: 0 ${sectionHeight / 2}px 0 0;

  > p {
    color: white;
    margin: 0;
    padding: 0 0 0 80px;
    opacity: 0.4;
  }

  @media (max-width: 600px) {
    height: auto;
    border-radius: 0 min(50vh, 30vw) 0 0;
    padding-bottom: 120px;

    > p {
      padding: 0 18px 10px;
      text-align: center;
    }
  }
`;

const Circle = styled(BaseCircle)`
  ${size(50)}
  background: ${props => props.theme.colors.primary};
`;

const centeredFlexBoxCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContactContainer = styled.div`
  ${centeredFlexBoxCss}
  flex-direction: row;
  justify-content: left;
  margin: 0 0 30px 60px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin: 0 18px;
  }
`;

const Contact = styled.div`
  ${centeredFlexBoxCss}
  margin: 20px;

  > div {
    position: relative;
    transition: .3s ease-out;

    &:before {
      content: '';
      background-color: ${props => props.theme.colors.primary};
      width: 100%;
      border-radius: 90px;
      z-index: 1;
      transition: .3s ease-out;
      opacity: 0.2;

      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  &:hover {
    > div {
      &:before {
        animation: shadow-pulse 1.6s infinite;
      }
    }
    > span {
      margin-left: .5rem;
      color: ${props => props.theme.colors.primaryLight1};
    }
  }

  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px ${props => props.theme.colors.primaryLight1};
      opacity: 0.8;
    }
    100% {
      box-shadow: 0 0 0 35px ${props => props.theme.colors.primaryLight1};
      opacity: 0;
    }
  }

  > span {
    color: white;
    margin: auto;
    margin-left: -0.5rem;
    z-index: 1;
    transition: .3s ease-out;
  }

  &:nth-of-type(2) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  right: -30px;
  top: 0;
  margin: -20px;

  @media (max-width: 600px) {
    position: unset;
    right: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const AtmosLogo = styled(AtmosSvg)(
  size(300),
  `
  @media (max-width: 600px) {
    padding: 4% 20%;
  }
`
);

const ContactCircle = styled(Circle)`
  ${size(60)}
  ${centeredFlexBoxCss}
`;

export default Footer;
