import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";

export const FAQ_CONTENT_LANDINGPAGE = [
  {
    title: "Hemstädning",
    questions: [
      {
        question: "Vardagsrum",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
              <li> {"Piffa till soffan."} </li>
            </ul>
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Kök",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
              <li> {"Rengöra köksbänk, vitvaror, diskho och spis."} </li>
              <li>
                {" "}
                {"Slänga sopor (om du förberett tillgång till soprum)."}{" "}
              </li>
            </ul>
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Toalett & badrum",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
              <li> {"Rengöra dusch, badkar, toalettstol."} </li>
            </ul>
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Sovrum",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
              <li>
                {" "}
                {"Byta sängkläder (om du lagt fram rena lakan på sängen)."}{" "}
              </li>
            </ul>
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Detta ingår inte",
        answer: (
          <>
            <ul>
              <li> {"Rengöring av Ugn."} </li>
              <li> {"Rengöring av Kylskåp."} </li>
              <li> {"Rengöring på insidan av skåp och lådor."} </li>
              <li> {"Rengöring av extrema fläckar."} </li>
              <li> {"Rengöring av tak och väggar."} </li>
              <li> {"Putsa fönster."} </li>
            </ul>
            <br />
            {
              "(OBS! Om du har extra mycket att städa eller behöver en total rengöring av hemmet så rekommenderar vi dig att boka en Flytt- / Storstädning)"
            }
            <br />
            <br />
            <Link to={ROUTES.SERVICES}>Se alla våra tjänster</Link>
          </>
        ),
      },
      {
        question: "Extra tilläggstjänster",
        answer: (
          <>
            {"När du bokar en städning hos oss så kan du enkelt lägga till:"}
            <br />
            <ul>
              <li> {"Rengöring av Kylskåp."} </li>
              <li> {"Rengöring av Ugn."} </li>
              <li> {"Diska."} </li>
              <li> {"Källsortering."} </li>
              <li> {"Stryka kläder."} </li>
              <li> {"Putsa fönster."} </li>
            </ul>
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
    ],
  },
  {
    title: "Företagsstädning",
    questions: [
      {
        question: "Kontorsytor",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Torka av alla skrivbord."} </li>
              <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
              <li> {"Slänga sopor & byta påsar."} </li>
            </ul>
            <Link to={ROUTES.BOOKING.BUSINESS_CLEANING}>
              Boka företagsstäd nu
            </Link>
          </>
        ),
      },
      {
        question: "Kök",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
              <li> {"Rengöra köksbänk, micro, diskho och spis."} </li>
              <li> {"Torka av skåp och kylskåp utvändigt."} </li>
              <li> {"Plocka in i diskmaskin & diska."} </li>
              <li> {"Slänga sopor & byta påsar."} </li>
            </ul>
            <Link to={ROUTES.BOOKING.BUSINESS_CLEANING}>
              Boka företagsstäd nu
            </Link>
          </>
        ),
      },
      {
        question: "Toaletter",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor (ej fönster)."} </li>
              <li> {"Rengöra handfat & toalettstol."} </li>
              <li> {"Slänga sopor & byta påsar."} </li>
            </ul>
            <Link to={ROUTES.BOOKING.BUSINESS_CLEANING}>
              Boka företagsstäd nu
            </Link>
          </>
        ),
      },
      {
        question: "Detta ingår inte",
        answer: (
          <>
            <ul>
              <li> {"Fönsterputs."} </li>
              <li> {"Rengöring av väggar."} </li>
              <li> {"Rengöring av lister & karmar."} </li>
              <li> {"Rengöring av ugn."} </li>
            </ul>
            {
              "Självklart kan vi göra extra överenskommelser för att utföra detta!"
            }
            <br />
            <br />
            <Link to={ROUTES.BOOKING.BUSINESS_CLEANING}>
              Boka företagsstäd nu
            </Link>
          </>
        ),
      }
    ]
  },
  {
    title: "Flytt- & Storstädning",
    questions: [
      {
        question: "Vardagsrum",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor."} </li>
              <li> {"Fönsterputs, in- och utsida samt mellan glasen."} </li>
              <li> {"Rengöring av snickerier."} </li>
              <li> {"Rengöring på och bakom element."} </li>
              <li> {"Avtorkning av innerdörrar."} </li>
              <li> {"Avtorkning av skåp och garderober."} </li>
              <li> {"Avtorkning av eluttag och strömbrytare."} </li>
              <li> {"Rengöring av väggar och tak från damm."} </li>
            </ul>
            <a href={"tel:+4673-6771005"} rel="noopener noreferrer">
              Boka nu: 073-677 10 05
            </a>
          </>
        ),
      },
      {
        question: "Kök",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor."} </li>
              <li> {"Rengöring av kokplattor."} </li>
              <li> {"Rengöring av spisfläkt, kåpa och filter."} </li>
              <li> {"Rengöring av ugn, värmeskåp, plåtar & galler."} </li>
              <li> {"Fönsterputs in- och utsida samt mellan glasen."} </li>
              <li> {"Avtorkning av skåp, in- och utvändigt."} </li>
              <li> {"Rengöring av kakel och kryddhylla."} </li>
              <li> {"Rengöring av kyl/frys invändigt, utvändigt om möjligt."} </li>
              <li> {"Rengöring av snickerier."} </li>
              <li> {"Rengöring på och bakom element."} </li>
              <li> {"Avtorkning av innerdörrar."} </li>
              <li> {"Avtorkning av eluttag och strömbrytare."} </li>
              <li> {"Rengöring av väggar och tak från damm."} </li>
            </ul>
            <a href={"tel:+4673-6771005"} rel="noopener noreferrer">
              Boka nu: 073-677 10 05
            </a>
          </>
        ),
      },
      {
        question: "Toalett & badrum",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor."} </li>
              <li> {"Rengöra dusch, badkar, toalettstol & tvättställ."} </li>
              <li> {"Rengöra badrumsskåp."} </li>
              <li> {"Avtorkning av rör."} </li>
              <li> {"Rengöring av badkar och badkarsfront och under badkar."} </li>
              <li> {"Rensning av golvbrunn."} </li>
              <li> {"Rengöring av kakelväggar."} </li>
            </ul>
            <a href={"tel:+4673-6771005"} rel="noopener noreferrer">
              Boka nu: 073-677 10 05
            </a>
          </>
        ),
      },
      {
        question: "Sovrum",
        answer: (
          <>
            <ul>
              <li> {"Dammsuga & moppa golv."} </li>
              <li> {"Dammtorka ytor."} </li>
              <li> {"Rengöra speglar & glasytor."} </li>
              <li> {"Fönsterputs, in- och utsida samt mellan glasen."} </li>
              <li> {"Rengöring av snickerier."} </li>
              <li> {"Rengöring på och bakom element."} </li>
              <li> {"Avtorkning av innerdörrar."} </li>
              <li> {"Avtorkning av skåp och garderober."} </li>
              <li> {"Avtorkning av eluttag och strömbrytare."} </li>
              <li> {"Rengöring av väggar och tak från damm."} </li>
              <li>
                {" "}
                {"Byta sängkläder (om du lagt fram rena lakan på sängen)."}{" "}
              </li>
            </ul>
            <a href={"tel:+4673-6771005"} rel="noopener noreferrer">
              Boka nu: 073-677 10 05
            </a>
          </>
        ),
      },
      {
        question: "Övrig information",
        answer: (
          <>
            {"Allt material så som städutrustning och rengöringsmedel ingår!"}
            <br></br>
            <br></br>
            {"Tänk på att lösa föremål såsom kläder, leksaker m.m. skall vara bortplockade så att lokalerna kan börja städas fr.om. överenskommen starttid."}
            <br />
            <br />
            <a href={"tel:+4673-6771005"} rel="noopener noreferrer">
              Boka nu: 073-677 10 05
            </a>
          </>
        ),
      }
    ]
  },
];

export const FAQ_CONTENT_FAQPAGE = [
  {
    title: "Vanliga frågor",
    questions: [
      {
        question: "Är det säkert att boka städning under COVID-19 pandemin?",
        answer: (
          <>
            {
              "Självklart tar vi detta på största allvar! Vi skickar enbart fullt friska städare till dig då våra personal stannar hemma vid minsta symptom."
            }
            <br />
            <br />
            {
              "Vi tar även med oss desinficerande medel och rengör diverse handtag och ytor i ditt hem!"
            }
            <br />
            <br />
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "I vilka områden utför ni städningar?",
        answer: (
          <>
            {"Vi städar i hela Stockholms län."}
            <br />
            <br />
            {
              "Du kan direkt se om vi kan städa hos dig när du fyller i din adress i bokningsformuläret!"
            }
            <br />
            <br />
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Behöver jag ha städredskap och rengöringsmedel hemma?",
        answer: (
          <>
            {"Ja! Vi behöver ha tillgång till:"}
            <ul>
              <li> {"Dammsugare."} </li>
              <li> {"Mopp & hink."} </li>
              <li> {"Disktrasa & svamp."} </li>
              <li> {"1-3 microfiberdukar."} </li>
            </ul>
            {
              "Om du där emot har bett oss ta med städmaterial, så tar vår städare med sig allt som behövs."
            }
            <br />
            <br />
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Hur lämnar jag över nyckeln till er?",
        answer: (
          <>
            {"Du kan antingen ge nyckeln direkt till vår städare. Vi skriver alltid på en kvittens på att nyckeln har överlämnats. Det går också bra att lämna en nyckel till oss på vårat kontor minst några dagar innan bokningen. Eller ring / maila oss så bestämmer vi då!"}
            <br />
            <br />
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Hur betalar jag för städningen?",
        answer: (
          <>
            {
              "Våra städningar betalas med faktura, som vi skickar efter städningen är klar och du är nöjd!"
            }
            <br />
            <br />
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Använder ni miljövänliga rengöringsmedel?",
        answer: (
          <>
            {"Att värna om miljön ligger högst på vår lista, så självklart använder vi bara miljövänliga rengöringsmedel - Svanenmärkta och oparfymerade."}
            <br />
            <br />
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Kan jag vara hemma under städningen?",
        answer: (
          <>
            {"Ja det går alldeles utmärkt!"}
            <br />
            <br />
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Kan mitt husdjur vara hemma under städningen?",
        answer: (
          <>
            {"Absolut, men berätta gärna det vid bokningen så vi vet det på förhand!"}
            <br />
            <br />
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Kan jag få samma städare varje gång?",
        answer: (
          <>
            {"Om du bokat återkommande städning så försöker vi se till att du får samma städare varje gång. Men vid enstaka städningar kan vi tyvärr inte garantera det. Kontakta oss om du har särskilda önskemål så gör vi vårt bästa för att tillfredsställa det!"}
            <br />
            <br />
            <Link to={ROUTES.BOOKING.HOME_CLEANING}>Boka hemstäd nu</Link>
          </>
        ),
      },
      {
        question: "Vad händer om något går sönder?",
        answer:
          "Vi är alltid försiktiga av oss när vi städar, men är olyckan där så har vi som tur är försäkring och ersätter skador upp till 10 miljoner kronor.",
      },
      {
        question: "Har ni kollektivavtal?",
        answer:
          "Såklart är vi anslutna till kollektivavtal! Vår personal arbetar på antingen heltid eller deltids kontrakt och arbetar under goda villkor.",
      },
      {
        question: "Kan jag använda RUT-avdraget på er städning?",
        answer: (
          <>
            {"Ja det kan du!"}
            <br />
            <br />
            {
              "Hushållstjänster ger dig möjlighet att göra en skattereduktion på 50% av hela beloppet, vilket betyder att du bara betalar halva priset. Du uppger bara att du vill använda RUT-avdraget vid bokning, så gör vi avdraget. Du behöver vara minst 18 år för att göra RUT- och ROT-avdrag på upp till 75 000 kronor per år, där potten för RUT-avdrag endast få uppgå till maximalt 50 000 kronor."
            }
            <br />
            <br />
            <a
              href={
                "https://www.skatteverket.se/privat/fastigheterochbostad/rotochrutarbete/villkorforattfarutavdrag.4.3810a01c150939e893f2b9d.html"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {"Läs mer på Skatteverket.se"}
            </a>
          </>
        ),
      },
    ],
  },
];
