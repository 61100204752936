import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { graphqlClient } from "./graphql/graphqlClient";
import {
  Landing as Home,
  Booking,
  BookingBusiness,
  Garden,
  About,
  Faq,
  Liability,
  Terms,
  Work,
  Services,
  Integrity,
  GetStarted,
  Feedback,
  Portal,
} from "./containers";
// import { Helmet } from "./components/Helmet";
import { ROUTES } from "./routes";
import { ThemeProvider, GlobalStyles } from "./styles";
import Observer from "./components/Observer";

const App = () => (
  <ApolloProvider client={graphqlClient}>
    <ThemeProvider>
      <Observer.Provider>
        <BrowserRouter>
          {/* <Helmet /> */}
          <GlobalStyles />
          <AppRoutes />
        </BrowserRouter>
      </Observer.Provider>
    </ThemeProvider>
  </ApolloProvider>
);

const AppRoutes = () => (
  <Routes>
    <Route path={ROUTES.ROOT} element={<Home />} />
    <Route path={ROUTES.BOOKING.HOME_CLEANING} element={<Booking />} />
    {/* <Route path={ROUTES.BOOKING.MOVE_CLEANING} element={<Booking />} /> */}
    <Route
      path={ROUTES.BOOKING.BUSINESS_CLEANING}
      element={<BookingBusiness />}
    />
    <Route path={ROUTES.TERMS_OF_USE} element={<Terms />} />
    <Route path={ROUTES.GET_STARTED} element={<GetStarted />} />
    <Route path={ROUTES.LIABILITY} element={<Liability />} />
    <Route path={ROUTES.GARDEN.ROOT} element={<Garden />} />
    <Route path={ROUTES.WORK_WITH_US} element={<Work />} />
    <Route path={ROUTES.ABOUT} element={<About />} />
    <Route path={ROUTES.FAQ} element={<Faq />} />
    <Route path={ROUTES.SERVICES} element={<Services />} />
    <Route path={ROUTES.INTEGRITY} element={<Integrity />} />
    <Route path={ROUTES.FEEDBACK} element={<Feedback />} />
    <Route path={ROUTES.PORTAL} element={<Portal />} />
    <Route path="*" element={<Home />} />
  </Routes>
);

export default App;
