import React, { FC } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import Button from "../Button";
import { ROUTES } from "../../routes";
import { lighten, darken } from "polished";

import { ReactComponent as HomeSvg } from "../../assets/svg/icon-home.svg";
// import { ReactComponent as MoveSvg } from "../../assets/svg/icon-truck.svg";
import { ReactComponent as BusinessSvg } from "../../assets/svg/icon-business.svg";

interface IBookingHorizontalSectionProps {}

export const BookingHorizontalSection: FC<IBookingHorizontalSectionProps> = (
  props
) => {
  return (
    <Section>
      <h3>Vad behöver du hjälp med?</h3>
      <QuickBook>
        <Link to={ROUTES.BOOKING.HOME_CLEANING}>
          <WideButton>
            <HomeSvg />
            Boka Hemstäd
          </WideButton>
        </Link>
        <Link to={ROUTES.BOOKING.BUSINESS_CLEANING}>
          <WideButton>
            <BusinessSvg />
            Boka Företagsstäd
          </WideButton>
        </Link>
        <Link to={ROUTES.SERVICES}>
          <WideButton>Se alla våra tjänster</WideButton>
        </Link>
      </QuickBook>
      {/* <Link to={ROUTES.SERVICES}>Se alla våra tjänster</Link> */}
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  margin: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 600px) {
    margin: 0 16px 45px;
  }
`;
const QuickBook = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;

  > a:nth-of-type(3) {
    > button {
      background: white;
      color: ${(props) => props.theme.colors.primary};

      &:hover {
        background-color: ${(props) =>
          lighten(0.05, props.theme.colors.primary)};
        border-color: ${(props) => lighten(0.05, props.theme.colors.primary)};
        color: white;
      }

      &:active {
        background-color: ${(props) => darken(0.1, props.theme.colors.primary)};
        border-color: ${(props) => darken(0.1, props.theme.colors.primary)};
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    > a {
      width: 100%;
    }
  }
`;
const WideButton = styled(Button)`
  margin: 10px;
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  > svg {
    width: 22px;
    height: 22px;
    margin-right: 6px;
    margin-bottom: 2px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 0 0 10px;
  }
`;
