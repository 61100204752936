import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import MainLayout from "../components/layout/MainLayout";
import Circle from "../components/Circle";
import { useScrollToTop } from "../lib/react/hooks";

interface IGetStartedProps {}

export const GetStarted = (props: IGetStartedProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  return (
    <MainLayout>
      <div css={css``} />
      <Circle1></Circle1>
      <Column>
        <h2>Välkommen som kund hos oss!</h2>

        <p>
          Vi är otroligt tacksamma att du valde oss. Här kommer några
          instruktioner du behöver följa för att komma igång.
        </p>

        <br></br>
        <br></br>

        <h4>1. Ladda ner appen</h4>

        <p>
          I appen kan du se dina kommande städtillfällen, ändra tider, begära
          extrajobb, hantera fakturor eller begär en paus.
        </p>

        <ol>
          <li>Ladda ner appen “Mina Städtjänster”</li>
          <ul>
            <li>
              iOS:{" "}
              <a
                href={
                  "https://l.facebook.com/l.php?u=https%3A%2F%2Fapps.apple.com%2Fse%2Fapp%2Fmina-st%25C3%25A4dtj%25C3%25A4nster%2Fid1531077588%3Ffbclid%3DIwAR3XHk44Fuoxd5Gp9YLG7QCf-Vq-jB3G3nE-ZzDFBFEpGKYSh0IjZ7vjp0w&h=AT0yZr0q84l2h5fO8V_jkF5vkp4Xu2IKvxNh3y8VqEJrdWMTkDD747LsFltRU5TSwxjk9s0YeN6WQswN5XNJs_PVDbJu1P69pNcVL6i7kYXtA2T9eaD0ZD-1Afjj4vSpPbOYWMQ&__tn__=-UK-R&c[0]=AT31yKQhbExwcfMMrPJX8r3orClA0BzsDjefnxOk8g9BugyngrHLd9JpKttgdujQGkaKiTqD6scVSE9vtGswMC0H7zum9TOlOBYs9fl3-vtuGxMhlNTF-o4FN223mdRZ0F_5slcsRjcpVYZbSWvGDUT-2y0EsuaH1PRiBGJ7SAZ9qA"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Hämta appen
              </a>
            </li>
            <li>
              Android:{" "}
              <a
                href={
                  "https://l.facebook.com/l.php?u=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tengella.home.mobile%26hl%3Den%26gl%3DSE%26fbclid%3DIwAR28lWyVHXUdQQptj9Yyag782IwPVhSQLmTOgSMD31G34PcRrIu0SwT07Ds&h=AT1N6ABSDyYkUycspasN1yXUQNhVppntKVSIZwsDJgkii9jmOaO1J1AoLRftxbsXFbSIU66GbYknb-3pS8YLy2V8kk0OonXit_WmkEN-zbNVhWZSSHq5Gav2z6qpRJT9i3fLE-w&__tn__=-UK-R&c[0]=AT31yKQhbExwcfMMrPJX8r3orClA0BzsDjefnxOk8g9BugyngrHLd9JpKttgdujQGkaKiTqD6scVSE9vtGswMC0H7zum9TOlOBYs9fl3-vtuGxMhlNTF-o4FN223mdRZ0F_5slcsRjcpVYZbSWvGDUT-2y0EsuaH1PRiBGJ7SAZ9qA"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Hämta appen
              </a>
            </li>
          </ul>
          <li>Logga in med ditt BankID</li>
          <li>
            Se att din information är korrekt och lägg gärna till eventuella
            önskemål du har under Mina sidor {">"} Arbetsadress
          </li>
        </ol>

        <br></br>
        <br></br>

        <h4>2. Förbered hemma</h4>

        <p>
          Se till att ha följande material och redskap hemma lättillgängligt för
          vår städpersonal:
        </p>
        <ul>
          <li>Dammsugare</li>
          <li>Mopp & hink</li>
          <li>Disktrasa & svamp</li>
          <li>1-3 microfiberdukar</li>
        </ul>

        <br></br>

        <p>
          Har du eget rengöringsmedel hemma så kan vi använda oss av det om du
          vill. Annars kan kan du även be oss att ta med vårt miljöanpassade
          rengöringsmedel mot en tilläggskostnad.
        </p>

        <br></br>
        <br></br>

        <h4>3. Att tänka på</h4>

        <span>Inför städningen:</span>
        <ul>
          <li>
            Plocka gärna undan lösa föremål från golv och bord så att vi kan
            fokusera mer av tiden åt att göra rent
          </li>
        </ul>

        <br></br>

        <span>Under städningen:</span>
        <ul>
          <li>Lorem ipsum dolor sit amet...</li>
        </ul>

        <br></br>

        <span>Efter städningen:</span>
        <ul>
          <li>Nyckelhanteringen enligt överenskommelse</li>
          <li>
            Se gärna över städningen och återkom gärna med dina synpunkter så
            att vi kan bli ännu bättre
          </li>
          <ul>
            <li>
              Du kan enkelt skapa ärenden i appen “Mina Städtjänster” för att
              rapportera eventuella avvikelser.
            </li>
            <li>
              Lämna gärna feedback på städningen här:{" "}
              <a
                href={"https://forms.gle/Q68qHSWF432r9ZQa9"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Lämna feedback
              </a>
            </li>
          </ul>
        </ul>

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <p>Har du några andra frågor eller behöver du hjälp?</p>
        <p>
          Ring oss:{" "}
          <a href={"tel:+4673-6771005"} rel="noopener noreferrer">
            073-677 10 05
          </a>
        </p>
      </Column>
    </MainLayout>
  );
};

const Column = styled.div`
  padding: 0 20% 5% 20%;
  margin-top: 200px;

  > h2 {
    margin-bottom: 45px;
  }

  > h3 {
    margin-bottom: 40px;
  }

  > h5 {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  > ul,
  ol {
    margin-top: 8px;
  }

  > ul,
  ol > li {
    margin-bottom: 5px;
  }

  @media (max-width: 600px) {
    padding: 0 18px 10%;
    margin-top: 18%;

    > h2 {
      margin-bottom: 55px;
    }
  }
`;

const Circle1 = styled(Circle)`
  position: absolute;
  opacity: 0.5;
  top: -25%;
  left: -10%;
  width: 50%;
  padding-bottom: 50%;
  background: ${(props) => props.theme.colors.primaryLight2};
  z-index: -1;

  @media (max-width: 600px) {
    top: -10%;
    left: -24%;
    width: 100%;
    padding-bottom: 100%;
  }
`;

export default GetStarted;
