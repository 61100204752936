export const ROUTES = {
  ROOT: "/",
  BOOKING: {
    // ROOT: "/boka",
    HOME_CLEANING: "/boka/hemstad",
    MOVE_CLEANING: "/boka/flyttstad",
    BUSINESS_CLEANING: "/boka/foretagsstad"
  },
  GARDEN: {
    ROOT: "/tradgard"
  },
  WORK_WITH_US: "/arbeta-med-oss",
  TERMS_OF_USE: "/villkor",
  GET_STARTED: "/kom-igang",
  LIABILITY: "/miljoansvar",
  ABOUT: "/om-oss",
  FAQ: "/faq",
  SERVICES: "/services",
  INTEGRITY: "/integritetspolicy",
  FEEDBACK: "/feedback",
  PORTAL: "/portal"
};
