import React, { FC, useEffect, useState, PropsWithChildren } from "react";
// import { jsx, css, keyframes } from "@emotion/react";
// import { size } from "polished";

import { ReactComponent as LoadingSvg } from "../assets/svg/loading.svg";
import styled from "@emotion/styled";

let timeout: NodeJS.Timeout;
let loadingStartTime: Date;

interface ILoadingThrobber extends PropsWithChildren {
  className?: string;
  isLoading: boolean;
  minDuration?: number;
}
export const LoadingThrobber = styled<FC<ILoadingThrobber>>(
  ({ isLoading, minDuration = 1000, ...props }) => {
    const [isThrobberVisible, setIsThrobberVisible] = useState(isLoading);

    useEffect(() => {
      if (isLoading) {
        loadingStartTime = new Date();
        setIsThrobberVisible(true);
      } else {
        const now = new Date();
        if (+now - +loadingStartTime >= minDuration) {
          setIsThrobberVisible(false);
        } else {
          timeout = setTimeout(() => {
            setIsThrobberVisible(false);
          }, minDuration - (+now - +loadingStartTime));
        }
      }
    }, [isLoading, minDuration]);

    useEffect(() => {
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }, []);

    return (
      <div className={props.className}>
        {isThrobberVisible ? <LoadingSvg /> : props.children}
      </div>
    );
  }
)`
  circle {
    stroke: ${(props) => props.theme.colors.orange2};
  }
`;

// const spin = keyframes`
//   to {
//     transform: rotate(360deg);
//   }
// `;

// const Loading = styled(Logo)(size(64), props => ({
//   display: 'block',
//   margin: 'auto',
//   fill: props.theme.colors.grey,
//   path: {
//     transformOrigin: 'center',
//     animation: `${spin} 1s linear infinite`,
//   },
// }));

export default LoadingThrobber;
