import { useLocation } from "react-router-dom";
// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";

import styled from "@emotion/styled";

import { css } from "@emotion/react";

import { useScrollToTop } from "../lib/react/hooks";
import MainLayout from "../components/layout/MainLayout";

import { AllServicesSection } from "../components/sections";

interface IServicesProps {}
export const Services = (props: IServicesProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);

  return (
    <MainLayout>
      <div css={css``} />
      <Column>
        <AllServicesSection />
      </Column>
    </MainLayout>
  );
};

const Column = styled.div`
  margin-top: 100px;

  @media (max-width: 600px) {
    margin-top: 18%;
  }
`;

export default Services;
