import { FC, PropsWithChildren } from "react";
import { ThemeProvider as MaterialThemeProvider } from "@mui/material/styles";

import { Theme, theme } from "./theme";

interface IProps extends PropsWithChildren {}

export const ThemeProvider: FC<IProps> = ({ children }) => (
  <MaterialThemeProvider<Theme> theme={theme}>{children}</MaterialThemeProvider>
);

export default ThemeProvider;
