import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { Collapse } from "@mui/material";
import ShowMoreIcon from "./icons/ShowMoreIcon";

export type TQuestion = {
  question: string;
  answer: React.ReactNode;
};

interface IQuestionsProps {
  questions: Array<TQuestion>;
  className?: string;
}

export const Questions: FC<IQuestionsProps> = (props) => {
  const [expandedIdx, setExpandedIdx] = useState<number | null>(null);
  const onExpandClick = (idx: number) =>
    setExpandedIdx(expandedIdx !== idx ? idx : null);

  const isExpanded = (idx: number) => idx === expandedIdx;

  return (
    <QuestionsWrapper>
      <div className={props.className}>
        {props.questions.map(({ question, answer }, idx) => (
          <Question key={idx}>
            <div
              onClick={() => onExpandClick(idx)}
              aria-expanded={isExpanded(idx)}
              aria-label="show more"
            >
              <h6>{question}</h6>
              <ShowMoreIcon showMore={!isExpanded(idx)} />
            </div>
            <Collapse in={isExpanded(idx)} timeout="auto" unmountOnExit>
              {answer}
            </Collapse>
          </Question>
        ))}
      </div>
    </QuestionsWrapper>
  );
};

const QuestionsWrapper = styled.div`
  padding: 0;
  width: 100%;

  @media (max-width: 600px) {
    padding: 16px;
    width: auto;
  }
`;

const Question = styled.div`
  padding: 22px 0 0 0;
  border-radius: 20px;
  transition: 0.3s ease-out;
  user-select: none;

  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
    cursor: pointer;
  }
  .MuiCollapse-wrapperInner {
    background: ${(props) => props.theme.colors.secondary};
    border-radius: 0 0 20px 20px;
    color: white;
    margin: 0;
    padding: 40px;
    line-height: 1.618rem;

    > ul {
      padding-inline-start: 20px;
    }

    > a {
      color: ${(props) => props.theme.colors.primaryLight1};
    }

    @media (max-width: 600px) {
      padding: 30px;
    }
  }

  h6 {
    margin: 0 0 22px 0;
    font-size: 1.2rem;
  }

  &:hover {
    background: ${(props) => props.theme.colors.primaryLight2};
  }

  @media (max-width: 600px) {
    > div:first-of-type {
      margin: 0 20px;
    }

    h6 {
      margin: 0 0 22px 0;
      font-size: 1rem;
    }
  }
`;
