import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  Resolvers as IResolvers,
  createHttpLink
} from "@apollo/client";
// import { HttpLink } from "apollo-link-http";

import { resolvers } from "./resolvers";
import { typeDefs } from "./types";

const cache = new InMemoryCache();
export const graphqlClient: ApolloClient<NormalizedCacheObject> = new ApolloClient(
  {
    cache,
    link: createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_API,
      // headers: {
      //   authorization: localStorage.getItem("token"),
      //   "client-name": "Atmosphere [web]",
      //   "client-version": "1.0.0"
      // }
    }),
    resolvers: resolvers as IResolvers[],
    typeDefs,
  }
);

// cache.writeData({
//   data: {
//     forms: {
//       __typename: "Forms",
//       cleaningForm: { __typename: "LocalCleaningForm", address: "qwe" },
//     },
//     isLoggedIn: false,
//     // test: { __typename: "Test", ac: "hej", b: 123 }
//   },
// });
