import React, { FC, useState, PropsWithChildren } from "react";

// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";

import styled from "@emotion/styled";
import Button from "./Button";
import { ROUTES } from "../routes";
// import { lighten } from "polished";
import { Link as BaseLink } from "react-router-dom";

import { Close as CloseSvg, Menu as MenuSvg } from "@mui/icons-material";

import { ReactComponent as BaseLogo } from "../assets/svg/atmos-logo-main.svg";
// import { Divider } from "@mui/material";

import { ReactComponent as HomeSvg } from "../assets/svg/icon-home.svg";
import { ReactComponent as BusinessSvg } from "../assets/svg/icon-business.svg";

interface IHeaderProps {}

const Header = (props: IHeaderProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleMenu = () => setIsExpanded(!isExpanded);

  return (
    <HeaderContainer>
      <Menu toggleMenu={toggleMenu} isExpanded={!isExpanded}>
        <LogoContainer>
          <Link to={ROUTES.ROOT}>
              <Logo />
          </Link>
        </LogoContainer>
        <Link to={ROUTES.BOOKING.HOME_CLEANING}>
          <WideButton>
            <HomeSvg />
            Boka Hemstäd
          </WideButton>
        </Link>
        <Link to={ROUTES.BOOKING.BUSINESS_CLEANING}>
          <WideButton>
            <BusinessSvg />
            Boka Företagsstäd
          </WideButton>
        </Link>
        <Link to={ROUTES.SERVICES}>
          <WideButton>
            Alla tjänster
          </WideButton>
        </Link>
        <Border></Border>
        <Link to={ROUTES.PORTAL}>Logga in</Link>
        <Link to={ROUTES.LIABILITY}>Miljöansvar</Link>
        <Link to={ROUTES.ABOUT}>Om oss</Link>
      </Menu>
      
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  display: flex;
  align-items: start;
  margin: 0 12px;
  z-index: 1250;

  @media (max-width: 900px) {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    bottom: 14px;
    right: 40px;
    top: unset;
    left: 0;
    width: 100%;
    padding-right: 16px;
  }
`;

const WideButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  > svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    margin-bottom: 2px;
  }
`;

const Border = styled.div`
  margin: 0 30px 0 0;
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  height: 20px;

  @media (max-width: 900px) {
    border-right: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    width: 55%;
    height: 0px;
    margin: 0 0 40px 0;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  background: white;
  border-radius: 0 0 45px 45px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  padding: 8px 30px 14px 30px;
  top: 0;
  left: 0;
  z-index: 2;

  @media (max-width: 900px) {
    position: absolute;
    border-radius: 45px 5px 5px 5px;
    padding: 12px 28px 9px 18px;
    width: 100%;
    width: -webkit-fill-available;
    max-width: 254px;
    min-width: 175px;
    bottom: 0;
    right: 0;
    top: unset;
    left: unset;
  }
`;

const Logo = styled(BaseLogo)`
  width: 160px;
  height: auto;

  @media (max-width: 900px) {
    width: 120px;
  }
`;

const Link = styled(BaseLink)`
  color: white;
  text-decoration: none;
`;

interface IMenuProps extends PropsWithChildren {
  isExpanded: boolean;
  toggleMenu(): void;
}
const Menu: FC<IMenuProps> = ({ children, isExpanded, toggleMenu }) => {
  const Icon = isExpanded ? CloseSvg : MenuSvg;
  return (
    <MenuContainer isExpanded={!isExpanded}>
      <div>
        {children}
        <Icon onClick={toggleMenu} />
      </div>
    </MenuContainer>
  );
};

interface IMenuContainerProps {
  isExpanded: boolean;
}
const MenuContainer = styled.div<IMenuContainerProps>`
  z-index: -1;

  > div {
    padding: ${props => props.isExpanded ? "2px 20px;" : "2px 20px 2px 245px" };
    background: #4E4D4Bf7;
    border-radius: 0 0 35px 45px;
    transition: all .3s ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 58px;
    width: ${props => props.isExpanded ? "240px" : "auto" };

    > a {
      margin-right: 30px;
      display: ${props => props.isExpanded ? "none" : "block" };

      &:nth-of-type(1) {
        margin-right: 0px;

        > button { 
          border-radius: 90px 0px 0px 90px;
          border-right: 1px solid #517b4f;

          &:before {
            border-radius: 90px 0px 0px 90px;
          }
        }
      }

      &:nth-of-type(2) {
        margin-right: 15px;

        > button { 
          border-radius: 0px 90px 90px 0px;
          border-left: 1px solid #517b4f;

          &:before {
            border-radius: 0px 90px 90px 0px;
          }
        }
      }

      &:nth-of-type(3) {

        > button {
          background-color: transparent;
          border-color: white;
        }
      }
    }

    > svg {
      cursor: pointer;
      transition: .3s ease-out;
      opacity: 0.5;
      transform: scale(1.1);

      &:hover {
        transform: scale(1.2);
      }

      &: active {
        transform: scale(0.9);
      }
    }

    > svg path {
      fill: white;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  @media (max-width: 900px) {
    position: absolute;
    // transform: translateX(${props => props.isExpanded ? "175px" : 0 });
    transition: .3s ease-out;
    border-radius: ${props => props.isExpanded ? "10px" : "10px" };
    width: ${props => props.isExpanded ? "230px" : "295px"};
    height: ${props => props.isExpanded ? "auto" : "auto"};
    bottom: ${props => props.isExpanded ? "0px" : "0px" };
    left: ${props => props.isExpanded ? "unset" : "unset" };
    padding-right: 14px;

    > div {
      transition: .3s ease-out;
      display: flex;
      background: ${props => props.isExpanded ? "#4E4D4B00" : "#4E4D4Bf7"};
      padding: ${props => props.isExpanded ? "0px" : "45px 25px 75px 25px" };
      justify-content: center;
      transform: translateX(${props => props.isExpanded ? 0 : 0});
      border-radius: 5px;
      flex-direction: column-reverse;
      justify-content: center;
      height: ${props => props.isExpanded ? "74.39px" : "auto"};
      width: auto;

      > a {
        opacity: ${props => props.isExpanded ? "0" : "1"};
        position: ${props => props.isExpanded ? "absolute" : "relative"};
        margin: ${props => props.isExpanded ? "0 0 0px 0" : "0 0 40px 0"};
        z-index: 0;
        display: block;
        width: ${props => props.isExpanded ? "80%" : "100%"};
        text-align: center;

        > button {
          padding: 16px 0;
          opacity: ${props => props.isExpanded ? "0" : "1"};
        }

        &:nth-of-type(1) {
          margin: ${props => props.isExpanded ? "0 0 0px 0" : "0 0 20px 0"};
          margin-right: 0px;

          > button {
            border-radius: 90px;
            border: none;

            &:before {
              border-radius: 90px;
            }
          }
        }

        &:nth-of-type(2) {
          > button {
            border-radius: 90px;
            border: none;

            &:before {
              border-radius: 90px;
            }
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          margin: ${props => props.isExpanded ? "0 0 0px 0" : "0 0 20px 0"};
        }
      }

      > svg path {
        fill: black;
      }
    }

    > div > svg {
      position: ${props => props.isExpanded ? "absolute" : "absolute"};
      right: 26px;
      bottom: 22px;
      width: 1.2em;
      height: 1.2em;
      z-index: 5;
    }
  }

  @media (max-width: 330px) {
    width: ${props => props.isExpanded ? "230px" : "282px"};
  }
`;
export default Header;
