import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import styled from "@emotion/styled";

import { ROUTES } from "../routes";
import { useScrollToTop } from "../lib/react/hooks";
import Footer from "../components/Footer";
import ChevronIcon from "../components/icons/Chevron";

const TENGELLA_PORTAL_URL = "https://portal.tengella.se/Home";
// const TENGELLA_PORTAL_REDIRECT_URL = `${TENGELLA_PORTAL_URL}/SetCookieAndRedirect?returnUrl=${window.location.origin}/portal?redirected`;
const IFRAME_TITLE =
  "I kundportalen kan du se samt meddela oss olika uppgifter.";
interface IPortalProps {}

export const Portal = (props: IPortalProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const { userAgent } = navigator;
    const chromeAgent = userAgent.indexOf("Chrome") > -1;
    const safariAgent = userAgent.indexOf("Safari") > -1 && !chromeAgent;

    if (safariAgent && params.get("redirected") === null) {
      window.location.href = TENGELLA_PORTAL_URL; // TENGELLA_PORTAL_REDIRECT_URL;
    }

    return () => {};
  }, []);

  return (
    <>
      <Link to={ROUTES.ROOT}>
        <BackButton>
          <ChevronIcon />
          Tillbaka
        </BackButton>
      </Link>
      <Iframe src={TENGELLA_PORTAL_URL} title={IFRAME_TITLE} frameBorder={0} />
      {/* <Iframe
        src={TENGELLA_PORTAL_REDIRECT_URL}
        title={IFRAME_TITLE}
        frameBorder={0}
      /> */}
      <Footer />
    </>
  );
};

const Iframe = styled.iframe`
  width: 100%;
  height: 120vh;
`;
const BackButton = styled.div`
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  background: white;
  padding: 12px 28px 18px 16px;
  border-radius: 0 0 35px 35px;
  z-index: 999;
`;

export default Portal;
