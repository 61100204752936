import React, {
  FC,
  useState,
  useRef,
  useLayoutEffect,
  PropsWithChildren,
} from "react";

import { lighten } from "polished";
import styled from "@emotion/styled";

import { Collapse, ClickAwayListener } from "@mui/material";

interface ISelectForm extends PropsWithChildren {
  // onClickHandler: (event: React.MouseEvent) => void;
  Selector: React.FC<ISelector>;
  className?: string;
}
interface ISelector {
  isExpanded?: boolean;
}

export const SelectForm: FC<ISelectForm> = ({
  className,
  // onClickHandler,
  Selector,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current !== null) {
      const { clientHeight: height } = ref.current;
      setContainerHeight(height);
    }
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
      <Container
        isMounted={isMounted}
        className={className}
        height={containerHeight}
      >
        <FormSelect ref={ref} onClick={() => setIsExpanded(!isExpanded)}>
          <SelectorWrapper>
            <Selector isExpanded={isExpanded}></Selector>
          </SelectorWrapper>
          <Children
            in={isExpanded}
            timeout="auto"
            unmountOnExit
            onEnter={() => setIsMounted(true)}
            onExited={() => setIsMounted(false)}
          >
            {children}
            {/* {React.Children.map(children, child => (
            <Child onClick={onClickHandler}>{child}</Child>
          ))} */}
          </Children>
        </FormSelect>
      </Container>
    </ClickAwayListener>
  );
};
interface IContainer {
  height: number;
  isMounted: boolean;
}
const Container = styled.div<IContainer>`
  height: ${(props) => props.height}px;
  margin: 5px;
  z-index: ${(props) => (props.isMounted ? 2 : 1)};
`;

const FormSelect = styled.div`
  border-radius: 5px;
  border: 2px solid ${(props) => props.theme.colors.secondary};
  background: white;
  cursor: pointer;
`;
const SelectorWrapper = styled.div``;
const Children = styled(Collapse)`
  cursor: pointer;

  // .MuiCollapse-wrapperInner > {
  //   ::before {
  //     display: block;
  //     margin: 5px 0;
  //     content: "";
  //     border-top: 1px solid ${(props) =>
    lighten(0.7, props.theme.colors.grey3)};
  //   }
  // }
`;
// interface IChild {
//   onClick: (event: React.MouseEvent) => void;
// }
// const Child = styled.div<IChild>``;

export default SelectForm;
