import { useState, useEffect, useContext, useRef, RefObject } from "react";
import Observer from "./Observer";

interface IProps {
  render: (isVisible: boolean, ref: RefObject<Element>) => React.ReactElement;
  updateOnce?: boolean;
  onChange?: (changed: boolean) => void;
}

function Visible({
  render,
  updateOnce = true,
  onChange = () => {},
}: IProps) {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef<Element>(null);
  const observer = useContext(Observer.Context);

  useEffect(() => {
    let el: Element;
    if (ref.current) {
      el = ref.current;
      observer.observe(el);
    }
    return () => observer.unobserve(el);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    if (!ref.current) return;

    const entry = observer.getEntry(ref.current);
    if (entry && entry.intersectionRatio > 0) {
      if (!isVisible) {
        onChange(true);
        setIsVisible(true);
      }
      if (updateOnce) observer.unobserve(ref.current);
    } else if (entry) {
      if (isVisible) {
        onChange(false);
        setIsVisible(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer.entries]);

  return render(isVisible, ref);
}

export default Visible;
