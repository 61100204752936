import React from "react";
import { UseFormReturn } from "react-hook-form";
import styled from "@emotion/styled";
import { ILocationInput } from "../../graphql/generated.types";

interface IProps
  extends Pick<
    UseFormReturn<{ location: ILocationInput }>,
    "register" | "formState"
  > {
  className?: string;
}
export const LocationInputs = ({
  register,
  formState: { errors },
  className,
}: IProps) => {
  return (
    <div className={className}>
      <input
        type="text"
        placeholder="Address"
        autoComplete="street-address"
        {...register("location.address", {
          required: 'obligatoriskt fält "Address"',
          // maxLength: { value: 80, message: "error message" }
        })}
      />
      <input
        type="text"
        placeholder="Postnummer"
        autoComplete="postal-code"
        {...register("location.postalCode", {
          required: 'obligatoriskt fält "Postnummer"',
          // validate: async (value) => await == true || mesg
          pattern: {
            value: /^\d{3}[-\s]?\d{2}$/,
            message: "Felaktigt Postnummer",
          },
        })}
      />
    </div>
  );
};

export const StyledLocationInputs = styled(LocationInputs)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: ${(props) => props.theme.colors.text};

  input {
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    flex: calc(65% - 10px);
    width: calc(65% - 10px);
    box-sizing: border-box;
    border: 2px solid ${(props) => props.theme.colors.secondary};
    outline: none;
    :focus {
      border-color: ${(props) => props.theme.colors.primary};
    }

    :nth-of-type(2) {
      flex: calc(35% - 10px);
      width: calc(35% - 10px);
    }

    @media (max-width: 600px) {
      flex: calc(100% - 10px);
      width: calc(100% - 10px);

      :nth-of-type(2) {
        flex: calc(100% - 10px);
        width: calc(100% - 10px);
      }
    }
  }

  input[type="email"],
  input[type="tel"] {
    flex: 100%;
  }
`;

export default StyledLocationInputs;
