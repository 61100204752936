import React, { FC } from "react";
import styled from "@emotion/styled";

import { ReactComponent as Goal5Svg } from "../../assets/svg/goal-5.svg";
import { ReactComponent as Goal10Svg } from "../../assets/svg/goal-10.svg";
import { ReactComponent as Goal12Svg } from "../../assets/svg/goal-12.svg";
import { ReactComponent as Goal13Svg } from "../../assets/svg/goal-13.svg";
import { ReactComponent as Goal14Svg } from "../../assets/svg/goal-14.svg";
import { ReactComponent as Goal15Svg } from "../../assets/svg/goal-15.svg";

const goals = [
  {
    description:
      "Vi strävar efter att anställa med en jämn könsfördelning. Samt utövar noll-tolerans mot alla trakasserier.",
    GoalSvg: Goal5Svg,
  },
  {
    description:
      "Hos oss är alla välkomna - oavsett vilken nationalitet, religion eller sexuell läggning du än må ha!",
    GoalSvg: Goal10Svg,
  },
  {
    description:
      "Vi är experter på återvinning och förbrukning av resurser! Vi erbjuder även alltid att återvinna åt dig!",
    GoalSvg: Goal12Svg,
  },
  {
    description:
      "Vi siktar mot en minimal miljöpåverkan genom alla våra tjänster. Samt planterar nya träd som renar atmosfären!",
    GoalSvg: Goal13Svg,
  },
  {
    description:
      "Vi ser till att inga farliga medel eller annat avfall hamnar i våra sjöar & hav.",
    GoalSvg: Goal14Svg,
  },
  {
    description:
      "Vi håller skräpet borta från naturen samt skapar nya förutsättningar för djur i våra planterade träd!",
    GoalSvg: Goal15Svg,
  },
];

interface IGlobalGoalsSectionProps {}

export const GlobalGoalsSection: FC<IGlobalGoalsSectionProps> = (props) => {
  return (
    <Section>
      <h3>Vi jobbar aktivt med de globala målen</h3>
      <p>
        - För att maximalt bidra till en bra miljö för våra anställda, för våra
        kunder och för världen!
      </p>
      <GoalsWrapper>
        {goals.map(({ description, GoalSvg }, idx) => (
          <Goal key={idx}>
            <GoalSvg />
            <p>{description}</p>
          </Goal>
        ))}
      </GoalsWrapper>
      <p>Lär dig mer om FN's globala mål:</p>
      <a
        href={"un.org/sustainabledevelopment"}
        target="_blank"
        rel="noopener noreferrer"
      >
        un.org/sustainabledevelopment
      </a>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  margin: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  > a {
    color: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: 600px) {
    margin: 0 18px 100px;
  }
`;

const GoalsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 45px 0 25px;

  @media (max-width: 600px) {
    margin: 45px -8px 0;
  }
`;

const Goal = styled.div`
  width: calc(33% - 40px);
  text-align: center;
  margin-bottom: 45px;
  padding: 0 20px;

  > svg {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 15px;
  }

  @media (max-width: 600px) {
    width: calc(50% - 10px);
    padding: 0 5px;

    > p {
      font-size: 0.9rem;
    }
  }
`;
