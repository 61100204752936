/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type IAnimalsInput = {
  cat?: InputMaybe<Scalars['Boolean']>;
  dog?: InputMaybe<Scalars['Boolean']>;
  other?: InputMaybe<Scalars['Boolean']>;
};

export type IAreaInput = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type IAvailableCleaningTime = {
  __typename?: 'AvailableCleaningTime';
  endTime: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  startTime: Scalars['DateTime'];
};

export type IBusinessCleaningFormInput = {
  additions: ICleaningAdditionsInput;
  area: IAreaInput;
  contact: IContactInput;
  date: Scalars['DateTime'];
  duration: Scalars['Float'];
  frequency: ICleaningFrequency;
  location: ILocationInput;
  message?: InputMaybe<Scalars['String']>;
  terms: Scalars['Boolean'];
  timeRange?: InputMaybe<ITimeRange>;
};

export enum ICacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type ICleaningAdditionsInput = {
  dishes?: InputMaybe<Scalars['Boolean']>;
  garbage?: InputMaybe<Scalars['Boolean']>;
  ironing?: InputMaybe<Scalars['Boolean']>;
  oven?: InputMaybe<Scalars['Boolean']>;
  refrigerator?: InputMaybe<Scalars['Boolean']>;
  tools?: InputMaybe<Scalars['Boolean']>;
  windows?: InputMaybe<Scalars['Boolean']>;
};

export enum ICleaningFrequency {
  Biweekly = 'biweekly',
  Monthly = 'monthly',
  Once = 'once',
  Weekly = 'weekly'
}

export type IContactInput = {
  companyName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  tel?: InputMaybe<Scalars['String']>;
};

export type IHomeCleaningFormInput = {
  additions: ICleaningAdditionsInput;
  animals?: InputMaybe<IAnimalsInput>;
  area: IAreaInput;
  contact: IContactInput;
  date: Scalars['DateTime'];
  duration: Scalars['Float'];
  frequency: ICleaningFrequency;
  location: ILocationInput;
  message?: InputMaybe<Scalars['String']>;
  terms: Scalars['Boolean'];
  timeRange?: InputMaybe<ITimeRange>;
  useRUT?: InputMaybe<Scalars['Boolean']>;
};

export type ILocation = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  /** id: ID */
  available?: Maybe<Scalars['Boolean']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type ILocationInput = {
  address?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

export type IMutation = {
  __typename?: 'Mutation';
  bookBusinessCleaning: Scalars['Boolean'];
  bookHomeCleaning: Scalars['Boolean'];
};


export type IMutationBookBusinessCleaningArgs = {
  form: IBusinessCleaningFormInput;
};


export type IMutationBookHomeCleaningArgs = {
  form: IHomeCleaningFormInput;
};

export type IQuery = {
  __typename?: 'Query';
  availableCleaningTimes: Array<IAvailableCleaningTime>;
  businessCleaningQuote: IQuote;
  homeCleaningQuote: IQuote;
  isLoggedIn: Scalars['Boolean'];
  location: ILocation;
};


export type IQueryAvailableCleaningTimesArgs = {
  duration: Scalars['Float'];
  frequency: ICleaningFrequency;
  location: ILocationInput;
};


export type IQueryBusinessCleaningQuoteArgs = {
  additions?: InputMaybe<ICleaningAdditionsInput>;
  area: IAreaInput;
  duration?: InputMaybe<Scalars['Float']>;
  frequency?: InputMaybe<ICleaningFrequency>;
};


export type IQueryHomeCleaningQuoteArgs = {
  additions?: InputMaybe<ICleaningAdditionsInput>;
  area: IAreaInput;
  duration?: InputMaybe<Scalars['Float']>;
  frequency?: InputMaybe<ICleaningFrequency>;
};


export type IQueryLocationArgs = {
  address?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

export type IQuote = {
  __typename?: 'Quote';
  /** services: [Service] */
  lines: Array<IQuoteLine>;
  taxDeductionAmount: Scalars['Float'];
  totalAfterTaxDedutionAmount: Scalars['Float'];
  totalAmount: Scalars['Float'];
  totalVatAmount: Scalars['Float'];
};

export type IQuoteLine = {
  __typename?: 'QuoteLine';
  /**
   * quantity: Float
   * unitPrice: Float
   * unit: String
   * vatPercentage: Float
   * totalAmount: Float
   */
  duration: Scalars['Float'];
  flatprice: Scalars['Float'];
  text: Scalars['String'];
};

export type ITimeRange = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type IBookHomeCleaningMutationVariables = Exact<{
  form: IHomeCleaningFormInput;
}>;


export type IBookHomeCleaningMutation = { __typename?: 'Mutation', bookHomeCleaning: boolean };

export type IBookBusinessCleaningMutationVariables = Exact<{
  form: IBusinessCleaningFormInput;
}>;


export type IBookBusinessCleaningMutation = { __typename?: 'Mutation', bookBusinessCleaning: boolean };

export type IAvailableCleaningTimesQueryVariables = Exact<{
  duration: Scalars['Float'];
  location: ILocationInput;
  frequency: ICleaningFrequency;
}>;


export type IAvailableCleaningTimesQuery = { __typename?: 'Query', availableCleaningTimes: Array<{ __typename?: 'AvailableCleaningTime', startTime: any, endTime: any }> };

export type ILocationQueryVariables = Exact<{
  postalCode?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
}>;


export type ILocationQuery = { __typename?: 'Query', location: { __typename?: 'Location', postalCode?: string | null, address?: string | null, available?: boolean | null } };

export type IHomeCleaningQuoteQueryVariables = Exact<{
  area: IAreaInput;
  duration?: InputMaybe<Scalars['Float']>;
  frequency?: InputMaybe<ICleaningFrequency>;
  additions?: InputMaybe<ICleaningAdditionsInput>;
}>;


export type IHomeCleaningQuoteQuery = { __typename?: 'Query', homeCleaningQuote: { __typename?: 'Quote', totalAmount: number, totalVatAmount: number, taxDeductionAmount: number, totalAfterTaxDedutionAmount: number, lines: Array<{ __typename?: 'QuoteLine', text: string, duration: number, flatprice: number }> } };

export type IBusinessCleaningQuoteQueryVariables = Exact<{
  area: IAreaInput;
  duration?: InputMaybe<Scalars['Float']>;
  frequency?: InputMaybe<ICleaningFrequency>;
  additions?: InputMaybe<ICleaningAdditionsInput>;
}>;


export type IBusinessCleaningQuoteQuery = { __typename?: 'Query', businessCleaningQuote: { __typename?: 'Quote', totalAmount: number, totalVatAmount: number, taxDeductionAmount: number, totalAfterTaxDedutionAmount: number, lines: Array<{ __typename?: 'QuoteLine', text: string, duration: number, flatprice: number }> } };

export type IQuoteFieldsFragment = { __typename?: 'Quote', totalAmount: number, totalVatAmount: number, taxDeductionAmount: number, totalAfterTaxDedutionAmount: number, lines: Array<{ __typename?: 'QuoteLine', text: string, duration: number, flatprice: number }> };

export type IIsUserLoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type IIsUserLoggedInQuery = { __typename?: 'Query', isLoggedIn: boolean };

export const QuoteFieldsFragmentDoc = gql`
    fragment quoteFields on Quote {
  totalAmount
  totalVatAmount
  taxDeductionAmount
  totalAfterTaxDedutionAmount
  lines {
    text
    duration
    flatprice
  }
}
    `;
export const BookHomeCleaningDocument = gql`
    mutation BookHomeCleaning($form: HomeCleaningFormInput!) {
  bookHomeCleaning(form: $form)
}
    `;
export type IBookHomeCleaningMutationFn = Apollo.MutationFunction<IBookHomeCleaningMutation, IBookHomeCleaningMutationVariables>;

/**
 * __useBookHomeCleaningMutation__
 *
 * To run a mutation, you first call `useBookHomeCleaningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookHomeCleaningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookHomeCleaningMutation, { data, loading, error }] = useBookHomeCleaningMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useBookHomeCleaningMutation(baseOptions?: Apollo.MutationHookOptions<IBookHomeCleaningMutation, IBookHomeCleaningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IBookHomeCleaningMutation, IBookHomeCleaningMutationVariables>(BookHomeCleaningDocument, options);
      }
export type BookHomeCleaningMutationHookResult = ReturnType<typeof useBookHomeCleaningMutation>;
export type BookHomeCleaningMutationResult = Apollo.MutationResult<IBookHomeCleaningMutation>;
export type BookHomeCleaningMutationOptions = Apollo.BaseMutationOptions<IBookHomeCleaningMutation, IBookHomeCleaningMutationVariables>;
export const BookBusinessCleaningDocument = gql`
    mutation BookBusinessCleaning($form: BusinessCleaningFormInput!) {
  bookBusinessCleaning(form: $form)
}
    `;
export type IBookBusinessCleaningMutationFn = Apollo.MutationFunction<IBookBusinessCleaningMutation, IBookBusinessCleaningMutationVariables>;

/**
 * __useBookBusinessCleaningMutation__
 *
 * To run a mutation, you first call `useBookBusinessCleaningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookBusinessCleaningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookBusinessCleaningMutation, { data, loading, error }] = useBookBusinessCleaningMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useBookBusinessCleaningMutation(baseOptions?: Apollo.MutationHookOptions<IBookBusinessCleaningMutation, IBookBusinessCleaningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IBookBusinessCleaningMutation, IBookBusinessCleaningMutationVariables>(BookBusinessCleaningDocument, options);
      }
export type BookBusinessCleaningMutationHookResult = ReturnType<typeof useBookBusinessCleaningMutation>;
export type BookBusinessCleaningMutationResult = Apollo.MutationResult<IBookBusinessCleaningMutation>;
export type BookBusinessCleaningMutationOptions = Apollo.BaseMutationOptions<IBookBusinessCleaningMutation, IBookBusinessCleaningMutationVariables>;
export const AvailableCleaningTimesDocument = gql`
    query AvailableCleaningTimes($duration: Float!, $location: LocationInput!, $frequency: CleaningFrequency!) {
  availableCleaningTimes(
    duration: $duration
    location: $location
    frequency: $frequency
  ) {
    startTime
    endTime
  }
}
    `;

/**
 * __useAvailableCleaningTimesQuery__
 *
 * To run a query within a React component, call `useAvailableCleaningTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCleaningTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCleaningTimesQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      location: // value for 'location'
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useAvailableCleaningTimesQuery(baseOptions: Apollo.QueryHookOptions<IAvailableCleaningTimesQuery, IAvailableCleaningTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAvailableCleaningTimesQuery, IAvailableCleaningTimesQueryVariables>(AvailableCleaningTimesDocument, options);
      }
export function useAvailableCleaningTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAvailableCleaningTimesQuery, IAvailableCleaningTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAvailableCleaningTimesQuery, IAvailableCleaningTimesQueryVariables>(AvailableCleaningTimesDocument, options);
        }
export type AvailableCleaningTimesQueryHookResult = ReturnType<typeof useAvailableCleaningTimesQuery>;
export type AvailableCleaningTimesLazyQueryHookResult = ReturnType<typeof useAvailableCleaningTimesLazyQuery>;
export type AvailableCleaningTimesQueryResult = Apollo.QueryResult<IAvailableCleaningTimesQuery, IAvailableCleaningTimesQueryVariables>;
export const LocationDocument = gql`
    query Location($postalCode: String, $address: String) {
  location(postalCode: $postalCode, address: $address) {
    postalCode
    address
    available
  }
}
    `;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      postalCode: // value for 'postalCode'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useLocationQuery(baseOptions?: Apollo.QueryHookOptions<ILocationQuery, ILocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILocationQuery, ILocationQueryVariables>(LocationDocument, options);
      }
export function useLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILocationQuery, ILocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILocationQuery, ILocationQueryVariables>(LocationDocument, options);
        }
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationQueryResult = Apollo.QueryResult<ILocationQuery, ILocationQueryVariables>;
export const HomeCleaningQuoteDocument = gql`
    query HomeCleaningQuote($area: AreaInput!, $duration: Float, $frequency: CleaningFrequency, $additions: CleaningAdditionsInput) {
  homeCleaningQuote(
    area: $area
    duration: $duration
    frequency: $frequency
    additions: $additions
  ) {
    ...quoteFields
  }
}
    ${QuoteFieldsFragmentDoc}`;

/**
 * __useHomeCleaningQuoteQuery__
 *
 * To run a query within a React component, call `useHomeCleaningQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeCleaningQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeCleaningQuoteQuery({
 *   variables: {
 *      area: // value for 'area'
 *      duration: // value for 'duration'
 *      frequency: // value for 'frequency'
 *      additions: // value for 'additions'
 *   },
 * });
 */
export function useHomeCleaningQuoteQuery(baseOptions: Apollo.QueryHookOptions<IHomeCleaningQuoteQuery, IHomeCleaningQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IHomeCleaningQuoteQuery, IHomeCleaningQuoteQueryVariables>(HomeCleaningQuoteDocument, options);
      }
export function useHomeCleaningQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IHomeCleaningQuoteQuery, IHomeCleaningQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IHomeCleaningQuoteQuery, IHomeCleaningQuoteQueryVariables>(HomeCleaningQuoteDocument, options);
        }
export type HomeCleaningQuoteQueryHookResult = ReturnType<typeof useHomeCleaningQuoteQuery>;
export type HomeCleaningQuoteLazyQueryHookResult = ReturnType<typeof useHomeCleaningQuoteLazyQuery>;
export type HomeCleaningQuoteQueryResult = Apollo.QueryResult<IHomeCleaningQuoteQuery, IHomeCleaningQuoteQueryVariables>;
export const BusinessCleaningQuoteDocument = gql`
    query BusinessCleaningQuote($area: AreaInput!, $duration: Float, $frequency: CleaningFrequency, $additions: CleaningAdditionsInput) {
  businessCleaningQuote(
    area: $area
    duration: $duration
    frequency: $frequency
    additions: $additions
  ) {
    ...quoteFields
  }
}
    ${QuoteFieldsFragmentDoc}`;

/**
 * __useBusinessCleaningQuoteQuery__
 *
 * To run a query within a React component, call `useBusinessCleaningQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessCleaningQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessCleaningQuoteQuery({
 *   variables: {
 *      area: // value for 'area'
 *      duration: // value for 'duration'
 *      frequency: // value for 'frequency'
 *      additions: // value for 'additions'
 *   },
 * });
 */
export function useBusinessCleaningQuoteQuery(baseOptions: Apollo.QueryHookOptions<IBusinessCleaningQuoteQuery, IBusinessCleaningQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IBusinessCleaningQuoteQuery, IBusinessCleaningQuoteQueryVariables>(BusinessCleaningQuoteDocument, options);
      }
export function useBusinessCleaningQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IBusinessCleaningQuoteQuery, IBusinessCleaningQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IBusinessCleaningQuoteQuery, IBusinessCleaningQuoteQueryVariables>(BusinessCleaningQuoteDocument, options);
        }
export type BusinessCleaningQuoteQueryHookResult = ReturnType<typeof useBusinessCleaningQuoteQuery>;
export type BusinessCleaningQuoteLazyQueryHookResult = ReturnType<typeof useBusinessCleaningQuoteLazyQuery>;
export type BusinessCleaningQuoteQueryResult = Apollo.QueryResult<IBusinessCleaningQuoteQuery, IBusinessCleaningQuoteQueryVariables>;
export const IsUserLoggedInDocument = gql`
    query IsUserLoggedIn {
  isLoggedIn @client
}
    `;

/**
 * __useIsUserLoggedInQuery__
 *
 * To run a query within a React component, call `useIsUserLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserLoggedInQuery(baseOptions?: Apollo.QueryHookOptions<IIsUserLoggedInQuery, IIsUserLoggedInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IIsUserLoggedInQuery, IIsUserLoggedInQueryVariables>(IsUserLoggedInDocument, options);
      }
export function useIsUserLoggedInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IIsUserLoggedInQuery, IIsUserLoggedInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IIsUserLoggedInQuery, IIsUserLoggedInQueryVariables>(IsUserLoggedInDocument, options);
        }
export type IsUserLoggedInQueryHookResult = ReturnType<typeof useIsUserLoggedInQuery>;
export type IsUserLoggedInLazyQueryHookResult = ReturnType<typeof useIsUserLoggedInLazyQuery>;
export type IsUserLoggedInQueryResult = Apollo.QueryResult<IIsUserLoggedInQuery, IIsUserLoggedInQueryVariables>;


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type IResolversTypes = {
  AnimalsInput: IAnimalsInput;
  AreaInput: IAreaInput;
  AvailableCleaningTime: ResolverTypeWrapper<IAvailableCleaningTime>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BusinessCleaningFormInput: IBusinessCleaningFormInput;
  CacheControlScope: ICacheControlScope;
  CleaningAdditionsInput: ICleaningAdditionsInput;
  CleaningFrequency: ICleaningFrequency;
  ContactInput: IContactInput;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  HomeCleaningFormInput: IHomeCleaningFormInput;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Location: ResolverTypeWrapper<ILocation>;
  LocationInput: ILocationInput;
  Mutation: ResolverTypeWrapper<{}>;
  Query: ResolverTypeWrapper<{}>;
  Quote: ResolverTypeWrapper<IQuote>;
  QuoteLine: ResolverTypeWrapper<IQuoteLine>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Time: ResolverTypeWrapper<Scalars['Time']>;
  TimeRange: ITimeRange;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type IResolversParentTypes = {
  AnimalsInput: IAnimalsInput;
  AreaInput: IAreaInput;
  AvailableCleaningTime: IAvailableCleaningTime;
  Boolean: Scalars['Boolean'];
  BusinessCleaningFormInput: IBusinessCleaningFormInput;
  CleaningAdditionsInput: ICleaningAdditionsInput;
  ContactInput: IContactInput;
  Date: Scalars['Date'];
  DateTime: Scalars['DateTime'];
  Float: Scalars['Float'];
  HomeCleaningFormInput: IHomeCleaningFormInput;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Location: ILocation;
  LocationInput: ILocationInput;
  Mutation: {};
  Query: {};
  Quote: IQuote;
  QuoteLine: IQuoteLine;
  String: Scalars['String'];
  Time: Scalars['Time'];
  TimeRange: ITimeRange;
  Upload: Scalars['Upload'];
};

export type ICacheControlDirectiveArgs = {
  maxAge?: Maybe<Scalars['Int']>;
  scope?: Maybe<ICacheControlScope>;
};

export type ICacheControlDirectiveResolver<Result, Parent, ContextType = any, Args = ICacheControlDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IAvailableCleaningTimeResolvers<ContextType = any, ParentType extends IResolversParentTypes['AvailableCleaningTime'] = IResolversParentTypes['AvailableCleaningTime']> = {
  endTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  startTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IDateScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['Date'], any> {
  name: 'Date';
}

export interface IDateTimeScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type ILocationResolvers<ContextType = any, ParentType extends IResolversParentTypes['Location'] = IResolversParentTypes['Location']> = {
  address?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  available?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IMutationResolvers<ContextType = any, ParentType extends IResolversParentTypes['Mutation'] = IResolversParentTypes['Mutation']> = {
  bookBusinessCleaning?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType, RequireFields<IMutationBookBusinessCleaningArgs, 'form'>>;
  bookHomeCleaning?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType, RequireFields<IMutationBookHomeCleaningArgs, 'form'>>;
};

export type IQueryResolvers<ContextType = any, ParentType extends IResolversParentTypes['Query'] = IResolversParentTypes['Query']> = {
  availableCleaningTimes?: Resolver<Array<IResolversTypes['AvailableCleaningTime']>, ParentType, ContextType, RequireFields<IQueryAvailableCleaningTimesArgs, 'duration' | 'frequency' | 'location'>>;
  businessCleaningQuote?: Resolver<IResolversTypes['Quote'], ParentType, ContextType, RequireFields<IQueryBusinessCleaningQuoteArgs, 'area'>>;
  homeCleaningQuote?: Resolver<IResolversTypes['Quote'], ParentType, ContextType, RequireFields<IQueryHomeCleaningQuoteArgs, 'area'>>;
  isLoggedIn?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  location?: Resolver<IResolversTypes['Location'], ParentType, ContextType, Partial<IQueryLocationArgs>>;
};

export type IQuoteResolvers<ContextType = any, ParentType extends IResolversParentTypes['Quote'] = IResolversParentTypes['Quote']> = {
  lines?: Resolver<Array<IResolversTypes['QuoteLine']>, ParentType, ContextType>;
  taxDeductionAmount?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  totalAfterTaxDedutionAmount?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  totalAmount?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  totalVatAmount?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IQuoteLineResolvers<ContextType = any, ParentType extends IResolversParentTypes['QuoteLine'] = IResolversParentTypes['QuoteLine']> = {
  duration?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  flatprice?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  text?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ITimeScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['Time'], any> {
  name: 'Time';
}

export interface IUploadScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type IResolvers<ContextType = any> = {
  AvailableCleaningTime?: IAvailableCleaningTimeResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Location?: ILocationResolvers<ContextType>;
  Mutation?: IMutationResolvers<ContextType>;
  Query?: IQueryResolvers<ContextType>;
  Quote?: IQuoteResolvers<ContextType>;
  QuoteLine?: IQuoteLineResolvers<ContextType>;
  Time?: GraphQLScalarType;
  Upload?: GraphQLScalarType;
};

export type IDirectiveResolvers<ContextType = any> = {
  cacheControl?: ICacheControlDirectiveResolver<any, any, ContextType>;
};
