import React, { FC } from "react";
import styled from "@emotion/styled";

import { ReactComponent as CheckmarkSvg } from "../assets/svg/icon-check.svg";
import { ReactComponent as CrossSvg } from "../assets/svg/icon-cross.svg";
// import { ReactComponent as LoadingsSvg } from "../assets/svg/loading.svg";

interface IVerifyLabel {
  className?: string;
  isValid: boolean;
  success?: string;
  failure?: string;
}
export const VerifyLabel = styled<FC<IVerifyLabel>>(
  ({ isValid, success, failure, ...props }) => (
    <div className={props.className}>
      {isValid ? (
        <div> 
          <CheckmarkSvg />
          <span>{success}</span>
        </div>
      ) : (
        <div>
          <CrossSvg />
          <span>{failure}</span>
        </div>
      )}
    </div>
  )
)`
  > div {
    display: flex;
    align-items: center;
    margin-top: 5px;

    animation: fadein .5s ease-out;

    @keyframes fadein {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
  }
  > div > span {
    padding: 5px 20px 5px 40px;
    border-radius: 30px;
    background: ${props =>
      props.isValid ? '#e8f2ea' : '#f3e8e8'};
    margin: 0;
    margin-left: -30px;
  }
  > div > svg {
    padding: 13px;
    width: 20px;
    height: 20px;
    overflow: visible;
    border-radius: 50%;
    background: ${props =>
      props.isValid ? props.theme.colors.primary: props.theme.colors.error};
      z-index: 1;

    animation: scalein .7s ease-out;

    @keyframes scalein {
      0% {transform: scale(0.5);}
      100% {transform: scale(1);}
    }
  }
  > div > svg > svg path {
    fill: white;
  }
`;

export default VerifyLabel;
