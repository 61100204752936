import React, { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { ExpandMore } from "@mui/icons-material";

interface IShowMoreIcon extends PropsWithChildren {
  showMore?: boolean;
}
export const ShowMoreIcon = styled<FC<IShowMoreIcon>>(
  ({ showMore, children, ...props }) =>
    React.createElement(ExpandMore, props, children)
)`
  transform: ${props => (props.showMore ? "rotate(0deg) scale(1.8);" : "rotate(180deg) scale(1.8);")};
  transition: transform 0.4s ease-out !important;
  width: 18px !important;
  height: 18px !important;
`;

export default ShowMoreIcon;
