// import { IResolvers, ICleaningFrequency } from "../generated.types";
import { IResolvers } from "../generated.types";
export const resolvers: IResolvers = {
  Query: {
    isLoggedIn(root, args, context) {
      return true;
    }
    // forms(root, args, context) {
    //   return {
    //     __typename: "Forms",
    //     homeCleaningForm: {
    //       __typename: "HomeCleaningForm",
    //       area: {
    //         __typename: "Area",
    //         min: 30,
    //         max: 40
    //       },
    //       duration: 3,
    //       date: "2020-04-13",
    //       time: "14:00",
    //       frequency: ICleaningFrequency.Weekly,
    //       useRUT: true,
    //       contact: {
    //         __typename: "Contact",
    //         firstname: "z",
    //         surname: "bw",
    //         email: "zakarias@newstag.com",
    //         tel: "0765616668",
    //         ssn: "190001010523"
    //       },
    //       location: {
    //         __typename: "Location",
    //         address: "stringbergsgatan 44",
    //         postalCode: "11531"
    //       },
    //       additions: {
    //         __typename: "HomeCleaningAdditions",
    //         refrigerator: true,
    //         ironing: true,
    //         dishes: true,
    //         garbage: true,
    //         windows: true,
    //         oven: true,
    //         tools: true
    //       },
    //       other: {
    //         __typename: "HomeCleaningOther",
    //         message: ""
    //       },
    //       terms: true
    //     }
    //   };
    // },
    // test(root, args, context) {
    //   return {
    //     __typename: "Test",
    //     a: "hej",
    //     b: { __typename: "TestTwo", c: 123 }
    //   };
    // }
  }
  // LocalHomeCleaningForm: {
  //   __resolveType(parent, args, context) {
  //     return 'BaseForm' //{
  // address: "qwe",
  // postalCode: 123,
  // kvm: 50,
  // duration: 123,
  // date: 99,
  // hour: 3,
  // refrigerator: true,
  // ironing: true,
  // dishes: true,
  // garbage: true,
  // windows: true,
  // oven: true,
  // tools: true,
  // frequency: 'weekly' as ICleaningFrequency
  //}

  //   }
  // }
};
