import { useEffect, useState } from "react";

export const useScrollToTop = (deps: React.DependencyList) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => window.scrollTo(0, 0), deps);
};

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
