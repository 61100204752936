import React from "react";
import { FieldError, UseFormReturn } from "react-hook-form";
import { IContactInput } from "../../graphql/generated.types";
import VerifyLabel from "../../components/VerifyLabel";
import styled from "@emotion/styled";

interface IProps
  extends Pick<
    UseFormReturn<{ contact: Omit<IContactInput, "ssn"> }>,
    "register" | "formState"
  > {
  className?: string;
  isBusiness?: boolean;
}

export function ContactForm({
  register,
  formState: { errors },
  isBusiness,
}: IProps) {
  return (
    <Container>
      <input
        type="text"
        placeholder="Namn"
        id="given-name"
        autoComplete="given-name"
        {...register("contact.firstname", {
          required: "Förnamn är obligatoriskt",
          maxLength: { value: 80, message: "Ogiltligt förnamn" },
        })}
      />

      <input
        type="text"
        placeholder="Efternamn"
        id="family-name"
        autoComplete="family-name"
        {...register("contact.surname", {
          required: "Efternamn är obligatoriskt",
          maxLength: { value: 20, message: "Ogiltligt efternamn" },
        })}
      />
      {isBusiness && (
        <input
          type="text"
          placeholder="Företagsnamn"
          id="company-name"
          autoComplete="organization"
          {...register("contact.companyName", {
            required: "Företagsnamn är obligatoriskt",
            maxLength: { value: 30, message: "Ogiltligt företagsnamn" },
          })}
        />
      )}
      <input
        type="email"
        placeholder="E-mail"
        autoComplete="email"
        {...register("contact.email", {
          required: "E-mail är obligatoriskt",
          pattern: {
            value: /^\S+@\S+$/i,
            message: "Ogiltlig e-mail",
          },
        })}
      />
      <input
        type="tel"
        placeholder="Telefonnummer"
        autoComplete="tel"
        {...register("contact.tel", {
          required: "Telefonnummer är obligatoriskt",
          pattern: {
            value: /^(?:\+|00)?(?:[\s-]?\d{2,4}){3,4}$/,
            message: "Ogiltligt telefonnummer",
          },
        })}
      />

      {errors.contact &&
        (Object.values(errors.contact) as FieldError[]).map(
          (error, idx) =>
            error.message && (
              <VerifyLabel
                key={idx + (error.ref?.name || error.message || "")}
                isValid={false}
                success={""}
                failure={error.message}
              />
            )
        )}
    </Container>
  );
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: ${(props) => props.theme.colors.text};

  input {
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    flex: calc(50% - 10px);
    width: calc(50% - 10px);
    box-sizing: border-box;
    border: 2px solid ${(props) => props.theme.colors.secondary};
    outline: none;
    max-hegiht: 40px;

    :focus {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  input[type="text"]:nth-of-type(3),
  input[type="email"],
  input[type="tel"] {
    flex: 100%;
    width: 100%;
  }
`;

export default ContactForm;
