import { css } from "@emotion/react";
import styled from "@emotion/styled";

const maxHeight = 1080;
export const FullscreenSection = styled.section`
  display: flex;
  height: 100vh;
  // max-height: ${(window.innerHeight / window.innerWidth) * 100}vw);
  max-height: min(${maxHeight}px, ${(10 / 16) * 100}vw);
  margin: 0 100px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    margin: 0 50px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    margin: 0 18px;
    max-height: none;
    height: auto;
    margin-bottom: 100px;
  }
`;

const scale = 1.06;
// 650px is arbitrarily
export const fullscreenCircleSizeWidth = `
min(
	calc(${scale * (2 / Math.sqrt(3))} * min(${(10 / 16) * 100}vw, ${maxHeight}px)),
	calc(${scale * (2 / Math.sqrt(3))} * max(100vh, 650px)) 
) 
`;
// calculated based on 25% X translate
export const fullscreenCircleSizeStyle = css`
  width: ${fullscreenCircleSizeWidth};
  padding-bottom: ${fullscreenCircleSizeWidth};
`;
