import { keyframes } from "@emotion/react";

// import { theme } from "./theme";

export const spinKF = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const leftInRightOutFadeKF = keyframes`
  0% {opacity: 0; transform: translateX(-20px);}
  10% {opacity: 0; transform: translateX(-20px);}
  20% {opacity: 1; transform: translateX(0px);}
  80% {opacity: 1; transform: translateX(0px);}
  90% {opacity: 0; transform: translateX(20px);}
  100% {opacity: 0; transform: translateX(20px);}
`;

export const fadeInKF = keyframes`
  0% {opacity: 0;}
  100% {opacity 1;}
`;

export const growInKF = keyframes`
  0% {opacity: 0; transform: scale(0.7);}
  45% {opacity: 1; transform: scale(1.04);}
  100% {transform: scale(1);}
`;
