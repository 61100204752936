import React, { FC, useState } from "react";
import styled from "@emotion/styled";
// import Button from "../../Button";
import { Questions as BaseQuestions, TQuestion } from "../../Questions";
import { FAQ_CONTENT_LANDINGPAGE, FAQ_CONTENT_FAQPAGE } from "./content";

interface IFaqSectionProps {
  title?: string;
  faq: Array<{
    title: string;
    questions: Array<TQuestion>;
  }>;
}

export const Comp: FC<IFaqSectionProps> = (props) => {
  const [selectorIdx, setSelectorIdx] = useState(0);
  const showSelectors = props.faq.length > 1;
  return (
    <Section>
      {props.title && <h3>{props.title}</h3>}
      <Container>
        {showSelectors && (
          <Selectors>
            {props.faq.map(({ title }, idx) => (
              <Selector
                key={idx}
                onClick={() => setSelectorIdx(idx)}
                isSelected={idx === selectorIdx}
              >
                {title}
              </Selector>
            ))}
          </Selectors>
        )}
        <Questions questions={props.faq[selectorIdx].questions} />
      </Container>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 45px;

  flex-direction: column;
  > h3 {
    margin-top: 15px;
    margin-bottom: 45px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  width: 100%;
  min-width: 800px;
  max-width: 1000px;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    padding: 0;
    min-width: auto;
  }
`;
const Questions = styled(BaseQuestions)`
  flex-grow: 1;
  background: ${(props) => props.theme.colors.primaryLight3};
  border-radius: 0 35px 35px 35px;
  padding: 20px;
`;

const Selectors = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  @media (max-width: 600px) {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 0px;
    padding-bottom: 8px;

    &:after {
      content: '';
      padding: 1px;
    }
  }
`;
interface ISelector {
  isSelected?: boolean;
}
const Selector = styled.button<ISelector>`
  outline: none;
  border-radius: 10px;
  border: none;
  padding: 14px 20px;
  text-align: left;
  margin-bottom: 8px;
  min-width: 200px;
  cursor: pointer;
  white-space: nowrap;
  transition: .3s ease-out;
  color: ${(props) => props.theme.colors.greyBackground};

  background: ${(props) =>
    props.isSelected ? props.theme.colors.primaryLight3 : "none"};

  &:hover:enabled {
    background: ${(props) => props.theme.colors.secondaryLight2};
  }
  &:active:enabled {
    background: ${(props) => props.theme.colors.primaryLight2};
  }

  @media (max-width: 600px) {
    min-width: auto;
    padding: 10px 14px;
    margin-right: 16px;
    margin-bottom: 0px;

    &:hover:enabled {
      background: ${(props) => props.theme.colors.primaryLight3};
    }

  }
`;

const FaqSection =  {
  Comp,
  LandingPage: () => (
    <Comp
      title={"Vad ingår i er städning?"}
      faq={FAQ_CONTENT_LANDINGPAGE}
    />
  ),
  FaqPage: () => <Comp faq={FAQ_CONTENT_FAQPAGE} />,
};

export default FaqSection