import React from "react";
import dayjs from "dayjs";

import Button from "./Button";

import styled from "@emotion/styled";
import {
  IBusinessCleaningFormInput,
  IHomeCleaningFormInput,
  ICleaningFrequency,
  IQuote,
} from "../graphql/generated.types";
import { collapsedViewBreakPoint } from "./layout/BookingLayout";

interface IProps {
  className?: string;
  form: IHomeCleaningFormInput | IBusinessCleaningFormInput;
  formIsValid: boolean;
  onSubmit: (event: React.MouseEvent) => void;
  quote: {
    loading: boolean;
    data?: IQuote;
  };
  isBusiness?: boolean;
}

const quoteLineTextLookup: { [key: string]: string } = {
  refrigerator: "Kylskåp",
  ironing: "Stryka kläder",
  dishes: "Diska",
  garbage: "Källsortera",
  oven: "Ugn",
  tools: "Städmaterial",
  windows: "Fönsterputs",
  cleaning: "Städning",
};

const Summary = ({
  className,
  form,
  formIsValid,
  onSubmit,
  quote,
  isBusiness,
}: IProps) => {
  const {
    timeRange,
    date,
    area,
    // duration,
    frequency,
    // additions: { tools, ...additions },
  } = form;

  const clockStart = timeRange
    ? dayjs(timeRange.startTime).format("HH:mm")
    : null;
  const clockEnd = timeRange ? dayjs(timeRange?.endTime).format("HH:mm") : null;
  const totalHours = timeRange
    ? dayjs(timeRange.endTime).diff(dayjs(timeRange.startTime), "hour", true)
    : null;
  const day = date ? dayjs(date).format("YYYY-MM-DD") : null;
  const taRounded = quote.data ? Math.ceil(quote.data?.totalAmount) : null;
  const tatdaRounded = quote.data
    ? Math.ceil(quote.data?.totalAfterTaxDedutionAmount)
    : null;

  const tavaRounded = quote.data
    ? Math.ceil(quote.data.totalAmount - quote.data.totalVatAmount)
    : null;

  return (
    <Container className={className}>
      <Center>
        <Title>Sammanfattning</Title>
        <IncludedItems>
          {isBusiness && <h6>Företagsstädning</h6>}
          {!isBusiness && <h6>Hemstädning</h6>}
          <ul>
            {quote.data?.lines.map((line, idx) => (
              <li key={line.text}>
                {/* <li key={`${idx}-${line.text}`}> */}
                <div>
                  <span id="summary-line-item-text">
                    {quoteLineTextLookup[line.text]}
                  </span>
                  <span id="summary-line-item-value">
                    {line.duration !== 0 && <>+{line.duration * 60} min</>}
                    {line.flatprice !== 0 && <>+{line.flatprice} kr</>}
                  </span>
                </div>
              </li>
            ))}
            {frequency !== ICleaningFrequency.Once && date && timeRange && (
              <li>
                <div>
                  <span id="summary-line-item-text">Återkommande städning</span>
                  {frequency === ICleaningFrequency.Weekly && (
                    <span id="summary-line-item-value">Varje vecka</span>
                  )}
                  {frequency === ICleaningFrequency.Biweekly && (
                    <span id="summary-line-item-value">Varannan vecka</span>
                  )}
                  {frequency === ICleaningFrequency.Monthly && (
                    <span id="summary-line-item-value">Var fjärde vecka</span>
                  )}
                </div>
                {/* <div>
                <span>Nästa tillfälle</span>
                <span>
                  {day}, kl {clockStart}-{clockEnd}
                </span>
              </div> */}
              </li>
            )}
          </ul>
        </IncludedItems>
        <Periodic>
          {timeRange && date && (
            <div>
              <div>
                <span>
                  <span id="summary-date">{day}</span>, kl{" "}
                  <span id="summary-time-start">{clockStart}</span>-
                  <span id="summary-time-end">{clockEnd}</span>,{" "}
                  <span id="summary-area">
                    {area.min}-{area.max}
                  </span>
                  kvm
                </span>
                <span>
                  <span id="summary-total-hours">{totalHours}</span> tim
                </span>
              </div>
            </div>
          )}
        </Periodic>
      </Center>
      <ButtonWrapper>
        {quote.data && (
          <PriceEstimate>
            <div>
              <span>Uppskattat pris:</span>
              {isBusiness && (
                <span>
                  Pris inkl moms:{" "}
                  <strong id="booking-price-total">{taRounded}</strong>
                </span>
              )}
              {!isBusiness && (
                <span>
                  Pris innan RUT:{" "}
                  <strong id="booking-price-total">{taRounded}</strong>
                </span>
              )}
            </div>
            <div>
              {/* {price.loading && <span> Estimerar Pris {price.loading}</span>} */}
              {isBusiness && (
                <p>
                  <strong id="booking-price-after-deduction">
                    {tavaRounded}
                  </strong>{" "}
                  kr
                </p>
              )}
              {!isBusiness && (
                <p>
                  <strong id="booking-price-after-deduction">
                    {tatdaRounded}
                  </strong>{" "}
                  kr
                </p>
              )}
            </div>
          </PriceEstimate>
        )}
        <WideButton
          id={"submit-booking-request"}
          disabled={!formIsValid}
          onClick={onSubmit}
        >
          Skicka förfrågan*
        </WideButton>
        <p>*Vi kontaktar dig så snart vi kan för att slutföra bokningen!</p>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.colors.secondaryLight2};
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Center = styled.div`
  top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  @media (max-width: ${collapsedViewBreakPoint - 1}px) {
    padding: 18px;
  }
`;

const Periodic = styled.div`
  margin: 20px 0;
  width: 100%;
  box-sizing: border-box;

  > div {
    animation: appear 0.3s ease-out;
    background: #e2dede;
    border-radius: 5px;
    padding: 20px;

    > div {
      display: flex;
      justify-content: space-between;

      > span {
        margin: 0;
      }
    }
  }
`;
const Title = styled.h5``;
const IncludedItems = styled.div`
  width: 100%;
  box-sizing: border-box;

  li {
    animation: appear 0.3s ease-out;
  }
  > ul {
    padding-left: 20px;
  }
  li > div {
    display: flex;
    justify-content: space-between;
  }
  li > div > span {
    margin: 0 0 4px 0;
  }
  ::after {
    content: "";
    display: block;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: scale(1) translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0px);
    }
  }
`;

const PriceEstimate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 78px;
  background: ${(props) => props.theme.colors.secondaryLight2};

  > div {
    flex-direction: column;
    width: 100%;
    text-align: right;

    &:nth-of-type(2) {
      text-align: left;
    }

    > span {
      font-size: 0.9rem;
      display: block;
      margin: 0;

      &:nth-of-type(2) {
        opacity: 0.5;
      }
    }

    > p {
      font-weight: 700;
      color: ${(props) => props.theme.colors.primary};
      font-size: 2.6rem;
      margin: 0 0 0 15px;
    }
  }

  @media (max-width: 350px) {
    > div > p {
      font-size: 2.1rem;
    }
  }
`;

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 40px;
  width: 100%;
  text-align: center;

  > p {
    font-size: 0.8rem;
  }

  @media (max-width: ${collapsedViewBreakPoint - 1}px) {
    padding: 0 18px;

    > button {
      margin-right: 62px;
    }

    > p {
      margin-right: 65px;
    }
  }
`;

const WideButton = styled(Button)`
  margin-bottom: 10px;
  width: -webkit-fill-available;
`;

export default React.memo(Summary, (prevProps, nextProps) => {
  return nextProps.quote.loading && !prevProps.quote.loading;
});
