import React, { FC, useState } from "react";
import { size } from "polished";
import styled from "@emotion/styled";
import BaseCircle from "../Circle";
import { FullscreenSection } from "./FullscreenSection";

import Visible from "../../components/Visible";
import { Fade } from "../../components/Transitions";
import easy from "../../assets/images/room-hallway-one.jpg";
import bathroom from "../../assets/images/room-livingroom-three.png";
import climate from "../../assets/images/butterfly-two.jpg";

const CIRCLES = [
  {
    idx: 1,
    timeout: 0,
    isActive: false,
    image: easy,
    title: "Tryggt & Enkelt",
    description:
      "Enkel bokning, och en bekymmerfri städning! Med heltäckande trygghetsförsäkring.",
  },
  {
    idx: 2,
    timeout: 200,
    isActive: true,
    image: bathroom,
    title: "Skinande rent",
    description:
      "Vi är städproffs, och våra kunder rekommenderar oss varmt! Vi erbjuder dig även en nöjd-kund-garanti!",
  },
  {
    idx: 3,
    timeout: 300,
    isActive: false,
    image: climate,
    title: "Klimatsmart",
    description:
      "Miljöcertifierad personal, miljöanpassade rengöringsmedel och ett stort engagemang för natur & miljö!",
  },
];

type TCircle = {
  idx: number;
  isActive: boolean;
  image: string;
  title: string;
  description: string;
};

interface IThreeCirclesSectionProps {}
export const ThreeCirclesSection: FC<IThreeCirclesSectionProps> = (props) => {
  const [circles, setCircles] = useState(CIRCLES);

  const onEnter = (circle: TCircle) => (event: React.MouseEvent) => {
    setCircles(circles.map((c) => ({ ...c, isActive: circle.idx === c.idx })));
  };
  const onLeave = (circle: TCircle) => (event: React.MouseEvent) => {
    setCircles(CIRCLES);
  };
  const onEnterTouch = (circle: TCircle) => (event: React.TouchEvent) => {
    setCircles(circles.map((c) => ({ ...c, isActive: circle.idx === c.idx })));
  };
  // const onLeaveTouch = (circle: TCircle) => (event: React.TouchEvent) => {
  //   setCircles(CIRCLES);
  // };
  return (
    <Section>
      {circles.map((circle) => (
        <Visible
          key={circle.idx}
          render={(isVisible, ref) => (
            <Circle
              ref={ref as React.RefObject<HTMLDivElement>}
              onMouseEnter={onEnter(circle)}
              onMouseLeave={onLeave(circle)}
              onTouchStart={onEnterTouch(circle)}
              {...circle}
            >
              <Fade in={isVisible} timeout={circle.timeout}>
                <div>
                  <h4>{circle.title}</h4>
                  <p>{circle.description}</p>
                </div>
              </Fade>
            </Circle>
          )}
        />
      ))}
    </Section>
  );
};

const Circle = styled(BaseCircle)<TCircle>`
  ${(props) => size(props.isActive ? "min(35vw, 500px)" : "min(20vw, 300px)")};
  background-image: ${(props) => `url(${props.image})`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  color: #fff;
  transition: min-width 0.5s, width 0.5s, min-height 0.5s, height 0.5s ease-in-out;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);

  &:after {
    content: '';
    background: ${(props) =>
      props.isActive ? "rgba(0,0,0,0.45)" : "rgba(0,0,0,0.25)"};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 1000px;
    z-index: 1;
    height: 100%;
    transition: .5s ease-in-out;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    > h4 {
      color: white;
      z-index: 2;
      transition: transform 0.3s ease-out;
      transform: ${(props) =>
        props.isActive ? "translateY(-45px)" : "translateY(0px)"};
      margin: 0;
    }
    > p {
      color: white;
      z-index: 2;
      width: 315px;
      position: absolute;
      transform: translateY(28px);
      transition: opacity 0.3s ease-out;
      opacity: ${(props) => (props.isActive ? 1 : 0)};
      margin: 0;
    }
  }

  @media (max-width: 1920px) {
    ${(props) =>
      size(props.isActive ? "min(30vw, 500px)" : "min(20vw, 300px)")};
  }

  @media (max-width: 600px) {
    transition: min-width 0.5s, width 0.5s, min-height 0.5s, height 0.5s ease-in-out;
    min-width: ${(props) => (props.isActive ? "315px" : "180px")};
    min-height: ${(props) => (props.isActive ? "315px" : "180px")};
    width: ${(props) => (props.isActive ? "315px" : "180px")};
    height: ${(props) => (props.isActive ? "315px" : "180px")};
  

    > div {
      > h4 {
        transform: ${(props) =>
          props.isActive ? "translateY(-45px)" : "translateY(0px)"};
        pointer-events: none;
      }

      > p {
        transform: translateY(34px);
        width: 260px;
        pointer-events: none;
      }
    }
  }
}
`;

const Section = styled(FullscreenSection)`
  background: ${(props) => props.theme.colors.secondary};
  border-radius: 0 min(50vh, 25vw);
  height: 100vh;
  max-height: 900px;
  margin: 0;

  @media (max-width: 1200px) {
    margin: 0;
  }

  @media (max-width: 600px) {
    padding: 25px 0;
    border-radius: 0 120px 0 120px; //backup
    border-radius: 0 min(50vh, 30vw) 0 min(50vh, 30vw);
    margin-bottom: 100px;
  }
`;
