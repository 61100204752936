import { useLocation } from "react-router-dom";
// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";

import { useScrollToTop } from "../lib/react/hooks";
import MainLayout from "../components/layout/MainLayout";

import {
  CenterCircleSection,
  SmallCirclesLeftSection,
  SmallCirclesRightSection,
  // ThreeImagesSection,
  BookingHorizontalSection
} from "../components/sections";

import aboutImage1 from "../assets/images/flower-two.jpg";
import aboutImage2 from "../assets/images/person.jpg";
import aboutImage3 from "../assets/images/flower-leaves.png";
import topImage from "../assets/images/flower-one.jpg";

const TITLE = "Vår vision";
const DESCRIPTION = "Att skapa en ren atmosfär - för dig och för världen!";

const TITLELEFTSMALL = "Vår affärsidé";
const DESCRIPTIONLEFTSMALL = "Vi erbjuder gröna klimatsmarta servicetjänster med hög kvalitét till bästa pris. Vi är ett glatt serviceinriktat gäng som vill förenkla din vardag! ";

const TITLERIGHTSMALL = "Vår personal";
const DESCRIPTIONRIGHTSMALL = "Vi är erfarna inom städ och servicebranchen, och våra  anställda har kollektivavtal och schyssta villkor.";

const TITLELEFTSMALL2 = "Vårt miljöansvar";
const DESCRIPTIONLEFTSMALL2 = "För att ha en minimal påverkan på miljön genom våra städningar så använder vi enbart miljöanpassade rengöringsmedel, miljöcertifierar vår personal och åker fossilfritt.";

interface IAboutProps {}
export const About = (props: IAboutProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  return (
    <MainLayout>
      <CenterCircleSection
        image={topImage}
        title={TITLE} 
        description={DESCRIPTION} 
      />
      <SmallCirclesLeftSection
        image={aboutImage1}
        title={TITLELEFTSMALL}
        description={DESCRIPTIONLEFTSMALL}
      />
      <SmallCirclesRightSection
        image={aboutImage2}
        title={TITLERIGHTSMALL}
        description={DESCRIPTIONRIGHTSMALL}
      />
      <SmallCirclesLeftSection
        image={aboutImage3}
        title={TITLELEFTSMALL2}
        description={DESCRIPTIONLEFTSMALL2}
      />
      {/* <ThreeImagesSection /> */}
      <BookingHorizontalSection />
    </MainLayout>
  );
};

export default About;
