import gql from "graphql-tag";
import { resolvers as formResolvers } from "./forms";


export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

// type ResolverFn = (
//   parent: any,
//   args: any,
//   { cache } : { cache: ApolloCache<any> }
// ) => any;

// interface ResolverMap {
//   [field: string]: ResolverFn;
// }

// interface AppResolvers extends R2 {
// }

export const resolvers = [
  formResolvers
]
// export const resolvers: IResolvers = {
//   ...formResolvers
// }
