import React, { FC } from "react";
import styled from "@emotion/styled";
import Circle from "../Circle";
import { Fade, Grow } from "../../components/Transitions";
import Visible from "../../components/Visible";

import { ReactComponent as Tree1Svg } from "../../assets/svg/trees-1.svg";
import { ReactComponent as Tree2Svg } from "../../assets/svg/trees-2.svg";
import { ReactComponent as Tree3Svg } from "../../assets/svg/trees-3.svg";
import { ReactComponent as Tree4Svg } from "../../assets/svg/trees-4.svg";
import { ReactComponent as Tree5Svg } from "../../assets/svg/trees-5.svg";
import { ReactComponent as BushesSvg } from "../../assets/svg/trees-bushes.svg";
import TreeNationLogo from "../../assets/images/tree-nation.png";

const trees = [
  { TreeSvg: Tree1Svg, timeout: 600 },
  { TreeSvg: Tree2Svg, timeout: 200 },
  { TreeSvg: Tree3Svg, timeout: 0 },
  { TreeSvg: Tree4Svg, timeout: 400 },
  { TreeSvg: Tree5Svg, timeout: 800 },
];

interface ITreeSectionProps {}

export const TreeSection: FC<ITreeSectionProps> = (props) => {
  return (
    <Visible
      render={(isVisible, ref) => (
        <Section>
          {/* <TreeCounter>
            <div>
              <h1>839</h1>
              <span>Planterade träd</span>
            </div>
          </TreeCounter> */}
          <TreeWrapper ref={ref as React.RefObject<HTMLDivElement>}>
            <Bushes>
              <BushesSvg />
            </Bushes>
            {trees.map(({ TreeSvg, timeout }, idx) => (
              <Grow in={isVisible} timeout={timeout} key={idx}>
                <Tree>
                  <TreeSvg />
                </Tree>
              </Grow>
            ))}
          </TreeWrapper>

          <Fade in={isVisible} timeout={500}>
            <div>
              <h3>Vi planterar ett träd för varje städning!</h3>
              <p>Tillsammans renar vi atmosfären - en städning i taget.</p>
              
              <TreePartner> 
                <p>I samarbete med:</p>
                <a href={"https://tree-nation.com/profile/impact/atmosphere"} target="_blank" rel="noopener noreferrer">
                  <TreeNation></TreeNation>
                </a>
              </TreePartner>
            </div>
          </Fade>
          <Circle1></Circle1>
        </Section>
      )}
    />
  );
};

const Section = styled.section`
  position: relative;
  display: flex;
  margin: 50vh 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  > div {
    > p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 600px) {
    margin: 0 18px 150px;
  }
`;

const TreePartner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    opacity: 0.5;
    margin-right: 12px;
    margin-bottom: 0;
    // font-size: 1.2rem;
    font-style: italic;
  }

  > a {
    transition: 0.3s ease-out;

    &:hover {
      transform: scale(1.05);
    }
    &:active {
      transform: scale(0.95);
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    > p {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
`;

const TreeNation = styled.div`
  width: 155px;
  height: 35px;
  background-image: url(${TreeNationLogo});
  background-size: cover;
`;

// const TreeCounter = styled.div`
//   position: absolute;
//   right: 9%;
//   border-radius: 50%;
//   background: ${(props) => props.theme.colors.secondary};
//   width: 240px;
//   height: 240px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 3;
//   flex-direction: column;
//   transform: translateY(-50%);

//   > div {
//     > h1 {
//       font-size: 50px;
//       color: ${(props) => props.theme.colors.primaryLight2};
//       margin-bottom: 5px;
//     }

//     > span {
//       color: white;
//       margin: 0;
//     }
//   }

//   @media (max-width: 900px) {
//     width: 200px;
//     height: 200px;
//   }

//   @media (max-width: 600px) {
//     width: 150px;
//     height: 150px;
//     right: unset;
//     transform: translateY(40%);

//     > div {
//       > h1 {
//         font-size: 35px;
//       }

//       > span {
//         font-size: 0.8rem;
//       }
//     }
//   }
// `;

const TreeWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 45px 0;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 600px) {
    margin: 40px -20px;
  }
`;

const Tree = styled.div`
  width: 16%;
  text-align: center;
  margin-bottom: 20px;

  > svg {
    width: 150px;
  }

  @media (max-width: 600px) {
    width: 18%;
    margin-bottom: -20px;

    > svg {
      width: 85px;
    }
  }
`;

const Bushes = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 110%;
  z-index: 2;
  margin-top: -30px;

  @media (max-width: 600px) {
    transform: scale(1.3) translateY(6px);
    margin-top: 0px;
  }
`;

const Circle1 = styled(Circle)`
  position: absolute;
  opacity: 0.2;
  width: 65%;
  padding-bottom: 65%;
  background: ${(props) => props.theme.colors.primaryLight2};
  z-index: -1;

  animation: atmosphere 4s ease-in-out infinite;

  @media (max-width: 600px) {
    width: 130%;
    padding-bottom: 130%;
  }

  @keyframes atmosphere {
    0% {
      transform: scale(1);
      opacity: 0.1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.2;
    }
    100% {
      transform: scale(1);
      opacity: 0.1;
    }
  }
`;
