import React, { FC } from "react";
import styled from "@emotion/styled";
import {
  fullscreenCircleSizeStyle,
  FullscreenSection,
} from "./FullscreenSection";
// import Button from "../Button";
import Circle from "../Circle";
import { CircleContainer } from "./CircleContainer";
import { Fade } from "../../components/Transitions";
import Visible from "../../components/Visible";

// import room from "../../assets/images/room5.png";

interface ITwoCirclesRightSectionProps {
  image?: string;
}

export const TwoCirclesRightSection: FC<ITwoCirclesRightSectionProps> = (
  props
) => {
  return (
    <Visible
      render={(isVisible, ref) => (
        <Section>
          <CircleContainer>
            <Circle2 />
            <Circle1 background={props.image} />
          </CircleContainer>

          <Column>
            <Fade in={isVisible}>
              <Text ref={ref as React.RefObject<HTMLDivElement>}>
                <h2>En ren atmosfär - för dig och för världen</h2>
                <p>
                  Det som skiljer oss från andra är vårt stora engagemang för
                  natur och miljö. När du anlitar oss skall det kännas bra i
                  kropp & själ, och du kan lita på att vi gör vårt yttersta för
                  att värna om din närmiljö och omgivning.
                </p>
              </Text>
            </Fade>
          </Column>

          <Column />
        </Section>
      )}
    />
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  z-index: 3;
`;

const Section = styled(FullscreenSection)``;

const Circle1 = styled(Circle)`
  position: absolute;
  background-color: #efedec;
  opacity: 1;
  transform: translate(25%, -50%);
  top: 50%;
  right: 0;
  ${fullscreenCircleSizeStyle}
  z-index: 1;

  @media (max-width: 600px) {
    position: unset;
    transform: translate(0) scale(1.2);
    width: 100%;
    padding-bottom: 100%;
  }
`;

const Circle2 = styled(Circle)`
  position: absolute;
  opacity: 0.5;
  transform: translate(60%, -50%);
  top: 50%;
  width: 45%;
  padding-bottom: 45%;
  background: ${(props) => props.theme.colors.primaryLight2};
  z-index: 2;

  @media (max-width: 600px) {
    transform: translate(-50%, 20%);
    width: 70%;
    padding-bottom: 70%;
  }
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: black;

  > h2 {
  }

  > p {
    margin-bottom: 30px;
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    margin-top: 15%;

    > p {
      font-size: 1rem;
    }
  }
`;
