import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import TagManager from "react-gtm-module";

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID || "",
});

const rootElement = document.getElementById("root");

const root = createRoot(rootElement!);
root.render(<App />);
