import React, { FC } from "react";
import styled from "@emotion/styled";
// import { ROUTES } from "../../routes";
// import Button from "../Button";

import { Fade } from "../../components/Transitions";
import Visible from "../../components/Visible";

// import itemImage3 from "../../assets/images/person.png";
import hemstadImage from "../../assets/images/room-bedroom-one.jpg";
import windowImage from "../../assets/images/service-window.png";
import officeImage from "../../assets/images/service-office.png";
import moveImage from "../../assets/images/service-move.png";
import recycleImage from "../../assets/images/recycle.jpg";
import carwashImage from "../../assets/images/carwash.png";
import viewingImage from "../../assets/images/service-viewing.png";
import bigcleanImage from "../../assets/images/service-bigclean.png";

const images = [
  {
    title: "Hemstäd",
    description:
      "Rena golv, fritt från damm och ett tillpiffat hem - En vanlig hemstädning.",
    image: hemstadImage,
  },
  {
    title: "Företagsstäd",
    description: "Vi erbjuder städning av kontor och trapphus, stora som små.",
    image: officeImage,
  },
  {
    title: "Fönsterputs",
    description:
      "Vi är experter på fönsterputs. Vi ger dig skinande fönster som aldrig förr! ",
    image: windowImage,
  },
  {
    title: "Flyttstäd",
    description:
      "Behöver du rent efter flytten? Vi gör en total städning av hemmet!",
    image: moveImage,
  },
  {
    title: "Storstäd",
    description:
      "När du har det extra stökigt. Vi gör en total städning av hemmet!",
    image: bigcleanImage,
  },
  {
    title: "Visningsstäd",
    description:
      "Ska du ha rent till visningen? Vi gör en total städning av hemmet!",
    image: viewingImage,
  },
  {
    title: "Källsortering",
    description: "Låt experterna ta hand om och källsortera ditt avfall.",
    image: recycleImage,
  },
  {
    title: "Biltvätt & städ",
    description:
      "Vi erbjuder både invändig och utvändig städning av din bil - hos dig!",
    image: carwashImage,
  },
  // { title: "Trädgårdskötsel", description: "Bli av med ogräs & få ordning på trädgårdsrabatten.", image: carwashImage }
];

interface IAllServicesSectionProps {}

export const AllServicesSection: FC<IAllServicesSectionProps> = (props) => {
  return (
    <Visible
      render={(isVisible, ref) => (
        <Section>
          <h3>Alla våra tjänster</h3>
          <ItemsWrapper ref={ref as React.RefObject<HTMLDivElement>}>
            {images.map(({ title, description, image }, idx) => (
              <Fade key={idx + title} in={isVisible} timeout={0}>
                <Item background={image} key={idx}>
                  <div></div>
                  <h5>{title}</h5>
                  <p>{description}</p>
                  <a href={"tel:+4673-6771005"} rel="noopener noreferrer">
                    Boka: 073-677 10 05
                  </a>
                </Item>
              </Fade>
            ))}
          </ItemsWrapper>
        </Section>
      )}
    />
  );
};

const Section = styled.section`
  display: flex;
  margin: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 600px) {
    margin: 0 18px;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 45px 0;
  justify-content: center;
`;

type TItemProps = {
  background: string;
};

const Item = styled.div<TItemProps>`
  width: calc(33% - 40px);
  text-align: center;
  margin-bottom: 65px;
  padding: 0 20px;

  > div {
    width: 200px;
    height: 200px;
    background-color: #efedec;
    background: url(${(props) => props.background});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin: 0 auto 22px;
  }

  > h5 {
    margin-bottom: 10px;
  }

  > p {
    margin-bottom: 22px;
  }

  @media (max-width: 900px) {
    width: calc(50% - 40px);
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 0 10px;

    > div {
      width: 180px;
      height: 180px;
    }
  }
`;

// const WideButton = styled(Button)`
//   width: auto;
//   margin: 20px auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 18px 32px;

//   @media (max-width: 900px) {
//     width: 100%;
//   }

//   @media (max-width: 600px) {
//     width: auto;
//   }
// `;
