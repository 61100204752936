import React from "react";
import { size } from "polished";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";

import { IAsideToggle } from "./layout/BookingLayout";
import { ReactComponent as ArrowSvg } from "../assets/svg/booking-arrow.svg";

import { IQuote } from "../graphql/generated.types";

interface IProps extends IAsideToggle {
  quote: {
    loading: boolean;
    data?: IQuote;
  };
  useRUT: boolean;
  isBusiness?: boolean;
}

const SummaryToggle = React.forwardRef<HTMLButtonElement, IProps>(
  ({ isAsideOpen, onClick, quote, useRUT, isBusiness }, ref) => {
    const tatdaRounded = quote.data
      ? Math.ceil(quote.data?.totalAfterTaxDedutionAmount)
      : null;

    const tavaRounded = quote.data
      ? Math.ceil(quote.data.totalAmount - quote.data.totalVatAmount)
      : null;

    return (
      <Container isAsideOpen={isAsideOpen}>
        <div>
          {quote.data && (
            <>
              <p>Uppskattat pris:</p>
              {isBusiness && <h3 id="booking-price-after-deduction">{tavaRounded}</h3>}
              {!isBusiness && <h3 id="booking-price-after-deduction">{tatdaRounded}</h3>}
              {/* <h6>{quote.data?.totalAmount}</h6> */}
            </>
          )}
        </div>
        <div>
          <ToggleButton
            ref={ref}
            onClick={onClick}
            color="inherit"
            aria-label="open drawer"
            edge="start"
          >
            <ArrowSvg />
          </ToggleButton>
          <p>{isAsideOpen ? "Stäng" : "Se bokning"}</p>
        </div>
      </Container>
    );
  }
);

const Container = styled.div<{ isAsideOpen?: boolean }>`
  > div:nth-of-type(1) {
    pointer-events: none;
    height: 75px;
    background: #f7f3f2f7;
    transition: 0.3s ease-out;
    padding: 13px 80px 13px 35px;
    box-sizing: border-box;
    border-radius: 50px 0 0 50px;
    right: 0;
    bottom: 0px;
    position: absolute;

    p {
      margin: 0;
      white-space: nowrap;
      font-size: 0.8rem;
    }

    h3 {
      margin: 0;
      color: ${(props) => props.theme.colors.primary};
      font-weight: 700;
    }
    h6 {
      font-size: 1rem;
    }
  }

  > div:nth-of-type(2) {
    position: relative;
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      position: absolute;
      bottom: 0;
      margin: 5px 0;
      font-size: 0.8rem;
      white-space: nowrap;
      width: 100%;
      text-align: center;
    }
  }

  button > svg {
    transform: rotate(-90deg);
    transition: 0.3s ease-out;
    fill: ${(props) => props.theme.colors.primary};
    width: 35px;
    height: 35px;
  }

  position: fixed;
  bottom: ${(props) => (props.isAsideOpen ? "54px" : "54px")};
  right: 0px;
  z-index: ${(props) => (props.isAsideOpen ? 1350 : 1200)};
  display: flex;
  align-items: center;

  ${(props) =>
    props.isAsideOpen
      ? `
    > div:nth-of-type(1) {
      background: 0;
      opacity: 0;
    }
    button > svg {
      transform: rotate(90deg);
      fill: #a0a0a0;
    }
  `
      : ``}
`;
const ToggleButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  margin: 10px;
  z-index: 1;
  &.MuiButtonBase-root {
    ${size(70)};
    margin: 0;
  }
`;

export default SummaryToggle;
