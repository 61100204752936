import React, { FC } from "react";
// import { size } from "polished";
import { fadeInKF, growInKF } from "../../styles";
import styled from "@emotion/styled";

import { FullscreenSection } from "./FullscreenSection";
import Circle from "../Circle";

interface ICenterCircleSectionProps {
  title: string;
  description: string;
  image?: string;
}

export const CenterCircleSection: FC<ICenterCircleSectionProps> = props => {
  return (
    <Section>
      <Column>
        <Circle1 background={props.image}>
          <Circle2 />
          <Text>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
          </Text>
        </Circle1>
      </Column>
    </Section>
  );
};

const Column = styled.div`
  display: flex;
  justify-content: center;
  z-index: 3;
`;

const Section = styled(FullscreenSection)`
  align-items: baseline;
  margin-bottom: min(8vw, calc(0.8 * 1600px));

  @media (max-width: 600px) {
    align-items: center;
  }
`;

const Circle1 = styled(Circle)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  top: -10%;
  width: min(60vw, 960px);
  height: min(60vw, 960px);
  z-index: -2;

  animation: ${growInKF} 1.4s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    background: rgba(20, 26, 21, 0.4);
    width: 100%;
    height: 100%;
    border-radius: 999px;
    z-index: -1;
  }

  @media (max-width: 600px) {
    position: relative;
    min-width: 110vw;
    min-height: 110vw;
    width: min(110vw, 960px);
    height: min(110vw, 960px);
  }
`;
const Circle2 = styled(Circle)`
  position: absolute;
  opacity: 0.5;
  transform: translate(65%, -40%);
  top: 0%;
  width: 70%;
  padding-bottom: 70%;
  background: ${props => props.theme.colors.primaryLight2};
  z-index: 0;
  transition: 1s ease-out;

  &:hover {
    transform: translate(65%, -40%) scale(1.02);
  }

  animation: breathe 1.8s ease-in-out;

  @keyframes breathe {
    0% {transform: translate(40%, -40%) scale(0.6);}
    60% {transform: translate(65%, -40%) scale(1.02);}
    100% {transform: translate(65%, -40%) scale(1);}
  }

  @media (max-width: 600px) {
    width: 50%;
    padding-bottom: 50%;
    transform: translate(70%, -25%);

    @keyframes breathe {
      0% {transform: translate(50%, -25%) scale(0.6);}
      60% {transform: translate(70%, -25%) scale(1.02);}
      100% {transform: translate(70%, -25%) scale(1);}
    }
  }
`;

const Text = styled.div`
  text-align: center;
  padding: 0 8%;
  z-index: 1;

  > h3 {
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.8);
    animation: ${fadeInKF} 1.2s ease-in-out;
    color: white;
    margin-bottom: 24px;
  }
  p {
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 1);
    animation: ${fadeInKF} 1.7s ease-in-out;
    color: white;
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    p {
      font-size: 1rem;
    }
  }
`;
