import React, { FC } from "react";
import { size } from "polished";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { FullscreenSection } from "./FullscreenSection";
import Button from "../Button";
import Circle from "../Circle";
import { CircleContainer } from "./CircleContainer";
import { ROUTES } from "../../routes";
import { Fade } from "../../components/Transitions";
import Visible from "../../components/Visible";

import { ReactComponent as BaseLeaves } from "../../assets/svg/leaves.svg";

interface ITwoCirclesLeftSectionProps {
  image?: string;
}

export const TwoCirclesLeftSection: FC<ITwoCirclesLeftSectionProps> = (
  props
) => {
  return (
    <Visible
      render={(isVisible, ref) => (
        <Section>
          <Column />

          <CircleContainer>
            <Circle1 background={props.image} />
            <Circle2>
              <Leaves />
            </Circle2>
          </CircleContainer>

          <Column>
            <Fade in={isVisible}>
              <Text ref={ref as React.RefObject<HTMLDivElement>}>
                <h2>Vi tänker mycket på miljön!</h2>
                <p>
                  För att ha en minimal påverkan på miljön genom våra städningar
                  så använder vi enbart miljöanpassade rengöringsmedel,
                  miljöcertifierar vår personal och åker fossilfritt.
                </p>
                <Link to={ROUTES.LIABILITY}>
                  <WideButton>Läs mer om vårt miljöansvar</WideButton>
                </Link>
              </Text>
            </Fade>
          </Column>
        </Section>
      )}
    />
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  z-index: 3;

  @media (max-width: 600px) {
    display: block;
  }
`;

const Section = styled(FullscreenSection)`
  margin-top: min(15vw, calc(0.15 * 1600px));
`;

const Circle1 = styled(Circle)`
  position: absolute;
  background-color: #efedec;
  opacity: 1;
  transform: translate(-25%, -50%);
  top: 50%;
  width: 65%;
  padding-bottom: 65%;
  z-index: 1;

  @media (max-width: 1920px) {
    width: 60%;
    padding-bottom: 60%;
  }

  @media (max-width: 600px) {
    position: unset;
    transform: translate(0) scale(1.2);
    width: 100%;
    padding-bottom: 100%;
  }
`;
const Circle2 = styled(Circle)`
  position: absolute;
  opacity: 0.5;
  transform: translate(60%, -50%);
  top: 50%;
  width: 45%;
  padding-bottom: 45%;
  background: ${(props) => props.theme.colors.primaryLight2};
  z-index: 2;

  @media (max-width: 600px) {
    transform: translate(100%, 20%);
    width: 70%;
    padding-bottom: 70%;
  }
`;

const theta = (Math.PI / 16) * 5;
const Leaves = styled(BaseLeaves)(
  size(180),
  `
  position: absolute;
  left: calc(50% + 50% * ${Math.cos(theta)});
  bottom: calc(50% + 50% * ${Math.sin(theta)});
  transform: translate(-14px,14px);
  path {
    fill: #bed1c0;
  }
`
);

const Text = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: black;

  > h2 {
  }

  > p {
    margin-bottom: 30px;
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    margin-top: 15%;

    > p {
      font-size: 1rem;
    }
  }
`;
const WideButton = styled(Button)`
  width: fit-content;
  margin: 3px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;
