import React, {
  FC,
  useState,
  useRef,
  useEffect,
  PropsWithChildren,
} from "react";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import ChevronIcon from "../../components/icons/Chevron";

import {
  disableBodyScroll,
  enableBodyScroll,
  // clearAllBodyScrollLocks
} from "body-scroll-lock";
import styled from "@emotion/styled";

export const sideBarWidth = 580;
export const collapsedViewBreakPoint = 1100;

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export interface IAsideToggle {
  isAsideOpen?: boolean;
  onClick?(): void;
}
interface IProps extends PropsWithChildren {
  aside: React.ReactElement;
  asideToggle: {
    Comp: FC<IAsideToggle | any>; // todo should not have any
    props: object;
  };
}

const BookingLayout: FC<IProps> = ({ children, aside, asideToggle }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const ref = useRef<HTMLElement>(null);

  const enableScroll = () => ref.current && enableBodyScroll(ref.current);
  const disableScroll = () => ref.current && disableBodyScroll(ref.current);

  useEffect(() => {
    return () => {
      enableScroll();
    };
  }, []);

  const toggleHandle = () => {
    if (mobileOpen) enableScroll();
    if (!mobileOpen) disableScroll();
    setMobileOpen(!mobileOpen);
  };
  const onCloseHandle = () => {
    setMobileOpen(false);
    enableScroll();
  };
  const onOpenHandle = () => {
    setMobileOpen(true);
    disableScroll();
  };

  // const isMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const isCollapsedView = !useMediaQuery(
    `(min-width:${collapsedViewBreakPoint}px)`
  );
  return (
    <>
      {/* <Header /> */}
      <Link to={ROUTES.ROOT}>
        <BackButton>
          <ChevronIcon />
          Tillbaka
        </BackButton>
      </Link>
      <Container>
        {isCollapsedView && (
          <asideToggle.Comp
            {...asideToggle.props}
            onClick={toggleHandle}
            isAsideOpen={mobileOpen}
          />
        )}

        <Main>{children}</Main>

        {isCollapsedView ? (
          <SwipeableDrawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={onCloseHandle}
            onOpen={onOpenHandle}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Aside ref={ref}>{aside}</Aside>
          </SwipeableDrawer>
        ) : (
          <Drawer variant="permanent" anchor="right" open>
            <Aside>{aside}</Aside>
          </Drawer>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 1600px;
  ${!iOS
    ? `
  display: flex;
  justify-content:center;
  `
    : ""}
  .MuiDrawer-paperAnchorDockedRight {
    border: none;
  }
`;
const Main = styled.div((props) => ({
  maxWidth: "900px",
  // [props.theme.breakpoints.up("md")]: {
  [`@media (min-width:${collapsedViewBreakPoint}px)`]: {
    width: `calc(100vw - ${sideBarWidth}px)`,
    marginRight: sideBarWidth,
  },
}));
const Aside = styled.aside`
  width: min(${sideBarWidth}px, 100vw);
  height: 100%;
  overflow: scroll;
`;

const BackButton = styled.div`
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
  background: white;
  padding: 12px 28px 18px 16px;
  border-radius: 0 0 35px 35px;
  z-index: 999;

  @media (max-width: 900px) {
  }
`;

export default BookingLayout;
