import styled from "@emotion/styled";

import { lighten, darken } from "polished";

export default styled.button`
  display: block;
  margin: 0 auto;
  padding: 24px 48px;
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: 90px;
  font-family: inherit;
  font-weight: 700;
  color: white;
  text-transform: none;
  background-color: ${props => props.theme.colors.primary};
  cursor: pointer;
  outline: none;
  position: relative;
  transition: .3s ease-out;

  &:before {
    content: '';
    background-color: ${props => props.theme.colors.primary};
    width: 100%;
    border-radius: 90px;
    z-index: -1;
    transition: .3s ease-out;
    opacity: 0;

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &:disabled {
    cursor: auto;
    color: #dedede;
    background-color: #a0a0a0;
    border-color: #a0a0a0;
    pointer-events: none;
  }

  &:hover:enabled {
    background-color: ${props => lighten(0.05, props.theme.colors.primary)};
    border-color: ${props => lighten(0.05, props.theme.colors.primary)};
    z-index: 1;

    &:before {
      animation: shadow-pulse 1.6s infinite;
      opacity: 0.2;
    }
  }

  &:active:enabled {
    background-color: ${props => darken(0.1, props.theme.colors.primary)};
    border-color: ${props => darken(0.1, props.theme.colors.primary)};
    transform: scale(0.95);
  }

  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px ${props => props.theme.colors.primaryLight1};
      opacity: 0.8;
    }
    100% {
      box-shadow: 0 0 0 35px ${props => props.theme.colors.primaryLight1};
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 18px 32px;
  }

  @media (max-width: 600px) {

  }
`;
