import React, { FC, useState } from "react";
import { size } from "polished";
import { leftInRightOutFadeKF } from "../../styles";
import styled from "@emotion/styled";
import BaseCircle from "../Circle";
import { FullscreenSection } from "./FullscreenSection";

import { ReactComponent as QuoteSvg } from "../../assets/svg/quote.svg";

const QUOTES = [
  {
    quote: "Mycket nöjd med kontakt, städning och bemötande, supergullig tjej!",
    user: "Caroline",
  },
  {
    quote: "Bra jobb, rent och fint, snabbt svar och bra pris",
    user: "Jan",
  },
  {
    quote:
      "Mycket bra och pålitliga! Lätt att ha kontakt med, kom i tid och städade bra.",
    user: "Johanna",
  },
  {
    quote: "Utmärkt utfört arbete och kunde komma snabbt! Rekommenderas!",
    user: "Elisabeth",
  }
];

interface IScoreSectionProps {}
export const ScoreSection: FC<IScoreSectionProps> = (props) => {
  const [quoteIdx, setQuoteIdx] = useState(0);
  const nextQuote = () => setQuoteIdx((quoteIdx + 1) % 4);
  return (
    <Section>
      <Circle>
        <h1>4,8/5</h1>
        <a
          href={"https://offerta.se/foretag/king-flytt-stad/#ratings"}
          target="_blank"
          rel="noopener noreferrer"
        >
          offerta.se
        </a>
      </Circle>
      <TextBox>
        <h3>Nöjda kunder!</h3>
        <QuoteIcon />
        <Quote onAnimationIteration={nextQuote}>
          <p>{QUOTES[quoteIdx].quote}</p>
          <b>{QUOTES[quoteIdx].user}</b>
        </Quote>
      </TextBox>
    </Section>
  );
};

const Section = styled(FullscreenSection)`
  margin-top: min(15vw, calc(0.1 * 1600px));
  position: relative;

  @media (max-width: 600px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Quote = styled.div`
  animation: ${leftInRightOutFadeKF} 4s infinite 0s;
  transition: 0.3s ease-out;

  > p {
    color: white;
    line-height: calc(18px * 1.618);
    font-size: 1.3rem;
    margin-top: 15px;
    font-style: italic;
    padding-right: 20px;
  }

  > b {
    margin: 0;
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    min-height: 170px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
  }

  @media (max-width: 350px) {
    > p {
      font-size: 1rem;
    }

    > b {
      font-size: 1rem;
    }
  }
`;

const QuoteIcon = styled(QuoteSvg)(
  size(65),
  `
  position: absolute;
  top: 30px;
  left: calc(min(15vw,120px));

  animation: quote 4s ease-out infinite 0s;

  @keyframes quote {
    0% {opacity: 0; transform: scale(.90);}
    10% {opacity: 0; transform: scale(.90);}
    20% {opacity: 1; transform: scale(1);}
    80% {opacity: 1; transform: scale(1);}
    90% {opacity: 0; transform: scale(.90);}
    100% {opacity: 0; transform: scale(.90);}
  }

  @media (max-width: 600px) {
    left: calc(min(8vw,120px));
    top: 42%;
    width: 45px;
    height: 45px;
  }

  @media (max-width: 350px) {
    top: 39%;
  }
`
);

const Circle = styled(BaseCircle)`
  width: min(45vw, 580px);
  height: min(45vw, 580px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  background: ${(props) => props.theme.colors.primaryLight2};
  z-index: 1;

  > h1 {
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 500;
    font-size: 7rem;
    margin-top: 15px;
  }
  > a {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 1.4rem;
    transition: 0.3s ease-out;

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media (max-width: 600px) {
    min-width: 110vw;
    min-height: 110vw;
    width: min(110vw, 580px);
    height: min(110vw, 580px);

    > h1 {
      font-size: 4rem;
      margin-top: 30px;
    }

    > a {
      font-size: 1.2rem;
    }
  }
`;

const TextBox = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.secondary};
  border-radius: 0 0 120px; //backup
  border-radius: 0 0 min(150px, ${55 / 4}vw);
  height: 300px;
  max-height: calc(30vw - 50px);
  width: 300px;
  margin-left: calc(-1 * min(30vw, 200px) / 2);
  padding-left: calc(min(15vw, 140px));
  padding-right: 30px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: left;
  color: white;
  flex-direction: column;

  > h3 {
    position: absolute;
    margin: 0;
    top: -75px;
    left: 15px;
    width: 100%;
    text-align: center;
    z-index 1;
  }

  @media (max-width: 600px) {
    border-radius: 0 0 min(50vh, 30vw);
    width: auto;
    height: auto;
    max-height: none;
    padding: 56% 50px 50px;
    margin: -45% 18px 0;

    > h3 {
      top: -35%;
      left: 0;
    }
  

    > p {
      margin-top: 50px;
    }
  }

  @media (max-width: 350px) {
    padding: 55% 30px 35px;
  }
`;
