import { useLocation } from "react-router-dom";
// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";

import styled from "@emotion/styled";
import { useScrollToTop } from "../lib/react/hooks";
import MainLayout from "../components/layout/MainLayout";
import { FaqSection, BookingHorizontalSection } from "../components/sections";

interface IFaqProps {}

export const Faq = (props: IFaqProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  return (
    <MainLayout>
      <Column>
        <h2>Vanliga frågor</h2>
        <FaqSection.FaqPage />
        <br />
        <FaqSection.LandingPage />
      </Column>
      <BookingHorizontalSection />
    </MainLayout>
  );
};

const Column = styled.div`
  margin-top: 200px;
  padding: 0 20% 5% 20%;

  > h2 {
    margin-bottom: 45px;
    text-align: center;
  }

  @media (max-width: 600px) {
    margin-top: 18%;
    padding: 0;

    > h2 {
      text-align: center;
    }
  }
`;

export default Faq;
