import React, { FC } from "react";
import styled from "@emotion/styled";
import { FullscreenSection } from "./FullscreenSection";
import Circle from "../Circle";
import { CircleContainer } from "./CircleContainer";
import { Fade } from "../../components/Transitions";
import Visible from "../../components/Visible";


interface ISmallCirclesRightSectionProps {
  title: string;
  description: string;
  image?: string
}

export const SmallCirclesRightSection: FC<ISmallCirclesRightSectionProps> = props => {
  return (
    <Visible
      render={(isVisible, ref) => (
        <Section>

          <CircleContainer>
            <Circle1 background={props.image}/>
            <Circle2 />
          </CircleContainer>

          <Column ref={ref as React.RefObject<HTMLDivElement>}>
            <Fade in={isVisible} timeout={0}>
              <Text>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
              </Text>
            </Fade>
          </Column>

          <Column />
        </Section>
      )}
    />
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  z-index: 3;
`;

const Section = styled(FullscreenSection)`
  margin-bottom: min(5vw, calc(0.05 * 1600px));
`;

const Circle1 = styled(Circle)`
  position: absolute;
  background-color: #efedec;
  opacity: 1;
  transform: translate(-20%, -50%);
  top: 50%;
  right: 0;
  width: 38%;
  padding-bottom: 38%;
  z-index: 1;

  @media (max-width: 600px) {
    position: unset;
    width: 75%;
    padding-bottom: 75%;
    transform: translate(17%, 0);
  }
`;

const Circle2 = styled(Circle)`
  position: absolute;
  opacity: 0.5;
  transform: translate(135%, -50%);
  top: 50%;
  width: 60%;
  padding-bottom: 60%;
  background: ${props => props.theme.colors.primaryLight2};
  z-index: 2;

  @media (max-width: 600px) {
    transform: translate(75%, -50%);
    width: 100%;
    padding-bottom: 100%;
  }
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: black;

  > p {
    margin-bottom: 30px;
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    margin-top: 7%;

    > h2 {
      margin-bottom: 12px;
    }

    > p {
      font-size: 1rem;
    }
  }
`;
