import React, { FC } from "react";
// import { size } from "polished";
import styled from "@emotion/styled";


interface IInfoMessageProps {
  information: string;
}

export const InfoMessage: FC<IInfoMessageProps> = props => {
  return (

    <InfoMessageContainer>
      <p>{props.information}</p>
    </InfoMessageContainer>
  );
};

const InfoMessageContainer = styled.div`
  background: #faf6e8;
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 25px;

  > p {
    color: #a28e4d;
    margin: 0;
  }

`;
