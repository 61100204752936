import { useLocation } from "react-router-dom";

import styled from "@emotion/styled";
import { useScrollToTop } from "../lib/react/hooks";
import MainLayout from "../components/layout/MainLayout";

interface IFeedbackProps {}

export const Feedback = (props: IFeedbackProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  return (
    <MainLayout>
      <Column>
        <iframe title='Feedback' src="https://docs.google.com/forms/d/e/1FAIpQLSdTYc19fFPtldLI12bk6v3dFhvFvvfvPNcx5U_rMKBtCS9MPw/viewform?embedded=true" width={640} height={1516} frameBorder={0} marginHeight={0} marginWidth={0}>Loading…</iframe>
      </Column>
    </MainLayout>
  );
};

const Column = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 50px;

  > h2 {
    margin-bottom: 45px;
  }

  @media (max-width: 600px) {
    margin-top: 18%;
    padding: 0;

    > h2 {
      text-align: center;
    }
  }
`;

export default Feedback;
