import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
});

export const theme = {
  ...muiTheme,
  colors: {
    accent: "#e535ab",
    grey: "#5A5A5A",
    greyBackground: "#3B3938",
    text: "#2F2F2E",
    textSecondary: "#747790",
    error: "#be4a4a",

    background: "#ffffff",
    primary: "#5D7E63",
    primaryLight1: "#C6D5C9",
    primaryLight2: "#C6D5C9",
    primaryLight3: "#EAF2EC",
    secondary: "#2F2F2E",
    secondaryLight1: "#5D5957",
    secondaryLight2: "#F5F3F3",

    grey1: "#838383",
    grey2: "#5A5A5A",
    grey3: "#2C2C2C",

    orange1: "#d67659",
    orange2: "#e0a069",
  },
};

export type Theme = typeof theme;
