import { FC } from "react";
// import { css, jsx, SerializedStyles } from "@emotion/react";
import { Link } from "react-router-dom";
import { lighten } from "polished";
import styled from "@emotion/styled";

import { fadeInKF } from "../../styles";
import {
  fullscreenCircleSizeStyle,
  fullscreenCircleSizeWidth,
  FullscreenSection,
} from "./FullscreenSection";
import Button from "../Button";
import Circle from "../Circle";
import { ROUTES } from "../../routes";
import { Fade } from "../../components/Transitions";
import Visible from "../../components/Visible";
import { darken } from "polished";

import room1 from "../../assets/images/room-bedroom-two.jpg";

import { ReactComponent as HomeSvg } from "../../assets/svg/icon-home.svg";
// import { ReactComponent as MoveSvg } from "../../assets/svg/icon-truck.svg";
import { ReactComponent as BusinessSvg } from "../../assets/svg/icon-business.svg";

interface IBookingSectionProps {}

export const BookingSection: FC<IBookingSectionProps> = (props) => {
  return (
    <Visible
      render={(isVisible, ref) => (
        <Section ref={ref as React.RefObject<HTMLElement>}>
          <Column>
            <Fade in={isVisible}>
              <Heading>
                <h1>Boka en grön städning!</h1>
                <p>
                  Vi är ett städföretag som tänker mycket på miljön. Vår
                  affärsidé är att utföra klimatsmarta städtjänster av hög
                  kvalitet till ett bra pris. Vår vision är att skapa en ren
                  atmosfär för dig och för världen!
                </p>
              </Heading>
            </Fade>
          </Column>

          <Column>
            <QuickBook>
              <Fade in={isVisible} timeout={100}>
                <h5>Vad behöver du hjälp med?</h5>
              </Fade>
              <Fade in={isVisible} timeout={200}>
                <ButtonsWrapper>
                  <Link to={ROUTES.BOOKING.HOME_CLEANING}>
                    <WideButton>
                      <HomeSvg />
                      Boka Hemstäd
                    </WideButton>
                  </Link>
                  <Link to={ROUTES.BOOKING.BUSINESS_CLEANING}>
                    <WideButton>
                      <BusinessSvg />
                      Boka Företagsstäd
                    </WideButton>
                  </Link>
                  <Link to={ROUTES.SERVICES}>
                    <WideButton>Se alla våra tjänster</WideButton>
                  </Link>
                </ButtonsWrapper>
              </Fade>
              {/* <Link to={ROUTES.SERVICES}>Se alla våra tjänster</Link> */}
              <Fade in={isVisible} timeout={300}>
                <BookingPrice>
                  <span>Från</span>
                  <span>243kr</span>
                  <span>/tim</span>
                  <div>Efter RUT-avdrag</div>
                </BookingPrice>
              </Fade>
            </QuickBook>
          </Column>

          <CircleContainerTop>
            <Circle1 />
            <Circle2 />
          </CircleContainerTop>
        </Section>
      )}
    />
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 35px 0 0;
  flex: 55%;
  z-index: 2;
  position: relative;

  &:nth-of-type(2) {
    flex: 40%;
    padding: 0 0 0 35px;
  }

  @media (max-width: 700px) {
    flex: 100%;
    flex-direction: column;
    padding: 0;

    &:nth-of-type(2) {
      flex: 100%;
      padding: 0;
    }
  }
`;

// const styles: { [k: string]: SerializedStyles } = {};
// styles.section1 = css`
//   margin-top: -10%;
// `;
const Section = styled(FullscreenSection)``;

const CircleContainerTop = styled.div`
  position: absolute;
  width: 100%;
  max-width: 1920px;
  height: ${fullscreenCircleSizeWidth};
  overflow: hidden;

  @media (max-width: 700px) {
    top: 0;
    height: 100vh;
  }
`;

const Circle1 = styled(Circle)`
  position: absolute;
  opacity: 1;
  transform: translate(-25%, -50%);
  top: 50%;
  ${fullscreenCircleSizeStyle}
  background-image: url(${room1});
  z-index: 1;

  animation: breatheinbig 1.4s ease-in-out;

  @keyframes breatheinbig {
    0% {
      transform: translate(-25%, -50%) scale(0.9);
    }
    60% {
      transform: translate(-25%, -50%) scale(1.02);
    }
    100% {
      transform: translate(-25%, -50%) scale(1);
    }
  }

  &:after {
    content: "";
    position: absolute;
    background: rgba(20, 26, 21, 0.45);
    width: 100%;
    height: 100%;
    border-radius: 999px;
  }

  @media (max-width: 700px) {
    top: 22%;
    width: 150%;
    padding-bottom: 150%;
  }

  @media (max-width: 350px) {
    top: 30%;
    width: 160%;
    padding-bottom: 160%;
  }

  @media screen and (min-width: 380px) and (max-width: 700px) {
    top: 16%;
  }
`;

const Circle2 = styled(Circle)`
  position: absolute;
  opacity: 0.5;
  transform: translate(60%, -80%);
  top: 30%;
  width: 50%;
  padding-bottom: 50%;
  background: ${(props) => props.theme.colors.primaryLight2};
  z-index: 1;
  transition: 1s ease-out;

  animation: breathein 1.8s ease-in-out;

  @keyframes breathein {
    0% {
      transform: translate(40%, -80%) scale(0.6);
    }
    60% {
      transform: translate(60%, -80%) scale(1.02);
    }
    100% {
      transform: translate(60%, -80%) scale(1);
    }
  }

  &:hover {
    transform: translate(60%, -80%) scale(1.02);
  }

  @media (max-width: 700px) {
    top: 10%;
    transform: translate(87%, -60%);
    width: 70%;
    padding-bottom: 70%;

    @keyframes breathein {
      0% {
        transform: translate(70%, -60%) scale(0.6);
      }
      60% {
        transform: translate(87%, -60%) scale(1.02);
      }
      100% {
        transform: translate(87%, -60%) scale(1);
      }
    }
  }
`;
const Heading = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
  color: white;

  > h1 {
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    color: white;
    animation: ${fadeInKF} 1.2s ease-in-out;
  }

  > p {
    text-shadow: 0px 2px 25px rgba(0, 0, 0, 1);
    color: white;
    font-size: 1.4rem;
    animation: ${fadeInKF} 1.7s ease-in-out;
    padding-right: 7%;
  }

  @media (max-width: 1200px) {
    > p {
      font-size: 1.1rem;
    }
  }

  @media (min-width: 700px) {
    max-width: calc(${fullscreenCircleSizeWidth} * 0.75 - 100px);
  }
  @media (max-width: 700px) {
    padding-top: 22%;

    > p {
      font-size: 1rem;
    }
  }
`;
const QuickBook = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #b5b5b5;
  margin-top: 20%;
  text-align: center;

  > a {
    width: 100%;
  }
  > a:nth-of-type(4) {
    margin-top: 10px;
    text-align: center;
  }

  > div {
    > a:nth-of-type(3) {
      > button {
        background: white;
        color: ${(props) => props.theme.colors.primary};

        &:hover {
          background-color: ${(props) =>
            lighten(0.05, props.theme.colors.primary)};
          border-color: ${(props) => lighten(0.05, props.theme.colors.primary)};
          color: white;
        }

        &:active {
          background-color: ${(props) =>
            darken(0.1, props.theme.colors.primary)};
          border-color: ${(props) => darken(0.1, props.theme.colors.primary)};
        }
      }
    }
  }

  @media (max-width: 700px) {
    margin-top: 33%;

    > h5 {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 350px) {
    margin-top: 90px;
  }

  @media screen and (min-width: 500px) and (max-width: 700px) {
    > h5 {
      color: white;
    }
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
`;

const WideButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 22px;
    height: 22px;
    margin-right: 6px;
    margin-bottom: 2px;
  }
`;
const BookingPrice = styled.div`
  margin: 20px;
  padding: 10px;
  width: 80%;
  border-top: 2px solid #e8e8e8;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  > span {
    padding: 0 0.3rem;
    margin-bottom: 0px;

    &:nth-of-type(2) {
      font-size: 2.5rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors.primary};
      break-after: always;
      margin-bottom: 0px;
    }
  }
  > div {
    flex-basis: 100%;
    text-align: center;
  }
`;
