

import { useLocation } from "react-router-dom";
// import { useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";
import styled from "@emotion/styled";
// import { fadeInKF, growInKF } from "../styles";
import { css } from "@emotion/react";
import Button from "../components/Button";
// import { ROUTES } from "../routes";
import { useScrollToTop } from "../lib/react/hooks";

import MainLayout from "../components/layout/MainLayout";

import topImage from "../assets/images/cat.jpg";

import {
  CenterCircleSection,
  // SmallCirclesLeftSection,
  // SmallCirclesRightSection
} from "../components/sections";

const TITLE = "Vill du jobba med oss?";
const DESCRIPTION =
  "Just nu söker vi förstärkning till vårt städ-team! Var med oss på en resa mot en renare atmosfär - för alla!";

interface IWorkProps {}

export const Work = (props: IWorkProps) => {
  const routerLocation = useLocation();
  useScrollToTop([routerLocation && routerLocation.pathname]);
  return (
    <MainLayout>
      <div css={css``} />

      <CenterCircleSection
        image={topImage}
        title={TITLE} 
        description={DESCRIPTION} 
      />

      <Apply>
        <h3>Ansök till oss idag!</h3>
        <p>Bifoga ditt CV eller berätta lite om dig själv, så hör vi av oss!</p>
        <a
          href={"mailto:kontakt@atmosphere.nu?Subject=Jobbansökan%20Atmosphere"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <WideButton>Skicka din ansökan med mail</WideButton>
        </a>
      </Apply>
    </MainLayout>
  );
};

const WideButton = styled(Button)`
  margin: 45px auto;
`;

const Apply = styled.div`
  text-align: center;
  padding-top: 20px;
  margin: 0 18px 100px;
`;

export default Work;
