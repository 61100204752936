import React, { FC, PropsWithChildren } from "react";

import { size } from "polished";
import styled from "@emotion/styled";

import Circle from "../../components/Circle";

export const FormSectionContainer: FC<PropsWithChildren> = (props) => {
  return <Container>{props.children}</Container>;
};

interface IFormSectionProps extends PropsWithChildren {
  title: string;
  disabled?: boolean;
}
export const FormSection: FC<IFormSectionProps> = (props) => {
  return (
    <Section disabled={props.disabled}>
      <FormCircle />
      <FormTitle>{props.title}</FormTitle>
      {props.children}
    </Section>
  );
};

const Container = styled.div`
  counter-reset: form-section;
`;

const Section = styled.section<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0;
  ${(props) =>
    props.disabled
      ? `
      filter: grayscale(100%); 
      opacity: 0.4; 
      pointer-events: none;
      `
      : ""}
`;

const formMarginBottom = -20;
const FormTitle = styled.h5`
  margin: 0 0 ${formMarginBottom}px;
`;
const FormCircle = styled(Circle)`
  ${size(100)}
  margin: 0 -10px ${formMarginBottom}px -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.primaryLight3};
  ::before {
    font-size: 40px;
    font-weight: 500;
    counter-increment: form-section;
    content: counter(form-section);
  }
`;
